import React, { useEffect, useState } from 'react';

import { ApproveModal } from '../components/base/ApproveModal';
import { BUILDER_VIEW_MOVE_APP_TO_FOLDER_CONFIRMATION_BUTTON } from '../constants';
import { useChatStore } from '../store/chat';
import { useOrganizationStore } from '../store/organization';
import { updateApp } from '../api/BuilderApi';
import { toast } from 'react-toastify';

interface MoveAppToFolderModalProps {
  onSuccess: () => Promise<void>;
}

// eslint-disable-next-line max-lines-per-function
export const MoveAppToFolderModal = ({ onSuccess }: MoveAppToFolderModalProps) => {
  const [folderIdToMoveAppTo, setFolderIdToMoveAppTo] = useState<string | null>(null);
  const { appFolders } = useOrganizationStore();
  const { showMoveAppToFolderModalForApp: app } = useChatStore();

  useEffect(() => {
    if (appFolders.length > 0) {
      setFolderIdToMoveAppTo(appFolders[0].id);
    }
  }, [appFolders]);

  const onClose = () => useChatStore.setState({ showMoveAppToFolderModalForApp: null });

  if (!app) {
    return null;
  }

  return (
    <ApproveModal
      message={
        <>
          <span className="whitespace-pre-line">
            To which folder do you
            <br />
            want to move the {app?.is_template ? 'template' : 'application'}?
          </span>
          <div className="whitespace-nowrap pt-2 flex w-full gap-2">
            <select
              className="w-full border border-system-separator p-2 rounded"
              onChange={(e) => {
                setFolderIdToMoveAppTo(e.target.value);
              }}
            >
              {appFolders.map((folder) => (
                <option key={folder.id} value={folder.id}>
                  {folder.name}
                </option>
              ))}
            </select>
          </div>
        </>
      }
      approveButton={{
        text: 'Move',
        className: 'bg-system-accent text-white',
        dataTestId: BUILDER_VIEW_MOVE_APP_TO_FOLDER_CONFIRMATION_BUTTON,
      }}
      rejectButton={{
        text: 'Cancel',
      }}
      onApprove={() => {
        if (folderIdToMoveAppTo) {
          // eslint-disable-next-line promise/catch-or-return
          updateApp(app.id, { app_folder_id: folderIdToMoveAppTo })
            .then(onSuccess)
            .catch(() => toast.error('Something went wrong when moving the folder, please retry'))
            .finally(onClose);
        } else {
          onClose();
        }
      }}
      onReject={() => {
        onClose();
      }}
    />
  );
};
