import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './index.css';
import App from './App';

import TagManager from 'react-gtm-module';
import { useAuthStore } from './store/auth';

const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID ?? '';
if (gtmId) {
  TagManager.initialize({
    gtmId,
  });

  let previousUserId = '';
  useAuthStore.subscribe((state) => {
    if (state.firebaseUser && state.firebaseUser.uid !== previousUserId) {
      previousUserId = state.firebaseUser.uid;

      TagManager.dataLayer({
        dataLayer: {
          userId: state.firebaseUser.uid,
          userEmail: state.firebaseUser.email,
          userEmailVerified: state.firebaseUser.emailVerified,
          userIsAnonymous: false,
        },
      });
    }
  });
}

const rootElement = document.getElementById('root') as Element;
const root = ReactDOM.createRoot(rootElement);

root.render(
  <>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      draggable={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      pauseOnHover
      theme="colored"
    />

    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>
);
