/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import React from 'react';
import { LazyReactIconComponentType } from './typings';

const LazyRightArrowFillIcon: LazyReactIconComponentType = ({
  color = 'none',
  size = 24,
  ...props
}) => {
  const className = 'lazy-icon ' + (props.className || '');

  return (
    <svg
      {...props}
      className={className}
      width={size}
      height={size}
      fill={color}
      viewBox="0 0 20 20"
    >
      <rect width="20" height="20" rx="10" fill={color} fillOpacity="1" />
      <path
        opacity="1"
        d="M15.5 10H4.5M15.5 10L11.57143 14.125M15.5 10L11.57143 5.875"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LazyRightArrowFillIcon;
