import React from 'react';
import {
  AuthenticationRequirementToMessageContent,
  TextMessageContentFormat,
} from '../../../types';
import TextMessage from './TextMessage';
import { useChatStore } from '../../../store/chat';
import useAuthenticationCheck from './AuthenticationCheckHook';

interface OauthAuthenticationRequirementMessageProps {
  authenticationInfo: AuthenticationRequirementToMessageContent;
}

// eslint-disable-next-line max-lines-per-function
export const OauthAuthenticationRequirementMessage = ({
  authenticationInfo,
}: OauthAuthenticationRequirementMessageProps) => {
  const { provider, oauthSigninLink } = authenticationInfo;
  // TODO: This message should be coming from backed via a translator
  const message = `Before you can continue, you need to login with your ${provider} account`;
  const instanceId = useChatStore.getState().instance?.id;

  const isAuthenticated = instanceId
    ? useAuthenticationCheck(instanceId, authenticationInfo.provider)
    : false;

  return (
    <div>
      <TextMessage text={message} format={TextMessageContentFormat.Plain} />
      <div className="border rounded-lg w-full max-w-[400px] m-3">
        <div className="flex justify-between p-4">
          <p className="font-semibold text-sm">{provider}</p>
        </div>

        {isAuthenticated ? (
          <p className="text-xs pl-4 pb-3 text-black/[.54]">Access Received</p>
        ) : (
          <div className="flex justify-start mt-2">
            <a
              href={oauthSigninLink}
              target="_self"
              rel="noopener noreferrer"
              className="flex items-center justify-center font-semibold text-sm \
rounded-bl-lg rounded-br-lg bg-lazy-action text-white w-full py-2"
            >
              Allow access to {provider}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default OauthAuthenticationRequirementMessage;
