import React from 'react';
import { getVH, isMobileDevice } from '../utils/deviceDimensions';

interface CollapsibleSidebarProps {
  open: boolean;
  children: React.ReactNode;
}
const CollapsibleSidebar = ({ open, children }: CollapsibleSidebarProps) => {
  const isMobileView = isMobileDevice();

  const customStyle = {
    width: open ? (isMobileView ? '100%' : '280px') : '0',
    height: isMobileView ? getVH() : '100%',
  };
  return (
    <div
      className="flex flex-col shrink-0 overflow-x-hidden
      overflow-y-scroll no-scrollbar transition-[width]"
      style={customStyle}
    >
      {children}
    </div>
  );
};

export default CollapsibleSidebar;
