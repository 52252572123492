import React, { useRef, useEffect } from 'react';
import RunAppIcon from 'remixicon-react/PlayCircleLineIcon';
import ArrowDownIcon from 'remixicon-react/ArrowDownSLineIcon';
import classNames from 'classnames';
import Button from './Button';
import * as dataTestidConstants from '../../constants';
import { isMobileDevice } from '../../utils/deviceDimensions';
import { useChatStore } from '../../store/chat';
import { RunAppButtonDropdownContent } from './RunAppButtonDropdownContent';
import { useAppStore } from '../../store/app';

export interface RunAppButtonProps {
  onRunApp: (ramMb: number, autoUpdateTestApp: boolean) => void;
  isAppRestarting: boolean;
  isNewVersionAvailable?: boolean;
  isFirstRun?: boolean;
  leftAlignDropDown?: boolean;
  currentRam?: number;
  isTestingApp?: boolean;
  handleSaveInstanceSettings: () => void;
}

// eslint-disable-next-line max-lines-per-function
export const RunAppButton = ({
  onRunApp,
  isAppRestarting,
  isFirstRun = true,
  isNewVersionAvailable = false,
  leftAlignDropDown = true,
  isTestingApp,
  currentRam,
  handleSaveInstanceSettings,
}: RunAppButtonProps) => {
  const { isMemorySelectorDropdownOpen } = useChatStore();

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSaveInstanceSettingsWithClosingDropdownMenu = () => {
    handleSaveInstanceSettings();
    useChatStore.setState({ isMemorySelectorDropdownOpen: false });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as HTMLElement)) {
      useChatStore.setState({ isMemorySelectorDropdownOpen: false });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <div className="flex bg-system-success rounded-md">
        <Button
          className={classNames('bg-system-success text-white hover:bg-system-success/80 gap-1', {
            'cursor-not-allowed': isAppRestarting,
          })}
          dataTestid={
            isFirstRun
              ? dataTestidConstants.TEST_APP_RUN_APP_BUTTON
              : dataTestidConstants.TEST_APP_RUN_AGAIN_APP_BUTTON
          }
          onClick={() => {
            onRunApp(
              useAppStore.getState().currentAppRam || 0,
              useAppStore.getState().autoUpdateTestApp
            );
          }}
          iconProps={{ icon: RunAppIcon, iconSize: 24 }}
          disabled={isAppRestarting}
          loading={isAppRestarting}
        >
          {isFirstRun ? 'Run app' : isNewVersionAvailable ? 'Update & Run' : 'Run again'}
        </Button>
        <button
          data-testid={dataTestidConstants.TEST_APP_RUN_MEMORY_SELECTOR_DROPDOWN_BUTTON}
          className={classNames(
            'bg-system-success text-white border-l-2 border-[#26A347] rounded-r-md',
            { 'px-1': !isMobileDevice() },
            { 'px-2': isMobileDevice() }
          )}
          onClick={() => {
            useChatStore.setState({ isMemorySelectorDropdownOpen: !isMemorySelectorDropdownOpen });
          }}
        >
          <ArrowDownIcon size="16" />
        </button>
      </div>

      {isMemorySelectorDropdownOpen && (
        <div
          ref={dropdownRef}
          className={classNames(
            'absolute mt-2 w-72 bg-white border',
            ' border-gray-300 rounded-md shadow-lg p-4 z-10',
            { 'right-0': leftAlignDropDown },
            { 'left-[-77.5px]': !leftAlignDropDown && isMobileDevice() },
            { 'left-[-81.5px]': !leftAlignDropDown && !isMobileDevice() }
          )}
        >
          <RunAppButtonDropdownContent
            appMemoryOptionsProps={{ currentRam }}
            leftAlignDropDown={leftAlignDropDown}
            isTestingApp={isTestingApp}
            handleSaveInstanceSettings={handleSaveInstanceSettingsWithClosingDropdownMenu}
          />
        </div>
      )}
    </div>
  );
};
