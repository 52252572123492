/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type OrganizationUserInviteUpdate = {
    /**
     * Updated status of the invite
     */
    status?: OrganizationUserInviteUpdate.status;
};

export namespace OrganizationUserInviteUpdate {

    /**
     * Updated status of the invite
     */
    export enum status {
        RESENT = 'Resent',
        ACCEPTED = 'Accepted',
    }


}

