/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum FileFormat {
    PYTHON = 'python',
    HTML = 'html',
    JAVASCRIPT = 'javascript',
    JAVASCRIPTX = 'javascriptx',
    TYPESCRIPT = 'typescript',
    TYPESCRIPTX = 'typescriptx',
    BASH = 'bash',
    TXT = 'txt',
    CSS = 'css',
    JSON = 'json',
    YAML = 'yaml',
    JPG = 'jpg',
    MARKDOWN = 'markdown',
    UNKNOWN = 'unknown',
}
