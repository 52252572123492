import RocketIcon from 'remixicon-react/Rocket2LineIcon';
import React from 'react';
import { useChatStore } from '../../store/chat';
import classNames from 'classnames';
import { isMobileDevice } from '../../utils/deviceDimensions';

interface PublishVersionButtonProps {
  publishVersionFunction?: (string) => void;
  builderSessionStateId?: string;
}
// eslint-disable-next-line max-lines-per-function
const PublishVersionButton = ({
  publishVersionFunction,
  builderSessionStateId,
}: PublishVersionButtonProps) => {
  const buttonText = isMobileDevice() ? '' : 'Publish';
  const handlePublishVersionFunction = () => {
    if (publishVersionFunction && builderSessionStateId) {
      publishVersionFunction(builderSessionStateId);
    }
  };

  if (!builderSessionStateId) {
    return null;
  }

  return (
    <div className="flex">
      <button
        onClick={handlePublishVersionFunction}
        disabled={useChatStore.getState().userInputLoading}
        className={classNames(
          'text-system-accent md:bg-transparent',
          'hover:bg-system-accent/10 rounded-lg text-lazy-btn-text p-1',
          {
            'cursor-not-allowed': useChatStore.getState().userInputLoading,
          }
        )}
      >
        <RocketIcon
          className="align-text-bottom
        inline-block mr-1"
          size={16}
        />
        <span className="hidden xs:inline-block">{buttonText}</span>
      </button>
    </div>
  );
};

export default PublishVersionButton;
