import classNames from 'classnames';
import React, { Ref, useEffect } from 'react';

import { LazyApp } from '../../../../../api/generated';
import useModalVisibility from '../../../../../hooks/modalVisibility';
import { isMobileDevice } from '../../../../../utils/deviceDimensions';
import { RichPromptAction } from '../../../../../components/chat/RichPromptActionList';

import { useChatAppActionsList } from '../../../../chatAppActionsListHook';

interface ActionsMenuProps {
  app: LazyApp;
  onAppMoved: () => Promise<void>;
  onClose: () => void;
}

// eslint-disable-next-line max-lines-per-function
export const ActionsMenu = ({ app, onAppMoved, onClose }: ActionsMenuProps) => {
  const { itemRef, isVisible } = useModalVisibility(true);
  const { getAvailableSidebarActionsList } = useChatAppActionsList();

  useEffect(() => {
    if (!isVisible) {
      onClose();
    }
  }, [isVisible]);

  const actions = getAvailableSidebarActionsList(app, async () => {
    await onAppMoved();
    onClose();
  });

  const handleActionClick = (action: RichPromptAction) => {
    action.onClick();
    onClose();
  };

  if (!actions?.length) {
    return null;
  }

  return (
    <div
      className={classNames(
        'flex flex-col py-1.5 bg-white rounded',
        'z-[5] absolute top-0 right-0',
        {
          'gap-[2px]': !isMobileDevice(),
          'gap-[4px]': isMobileDevice(),
        }
      )}
      ref={itemRef as Ref<HTMLDivElement>}
    >
      {actions.map((action, index) => (
        <button
          key={index}
          className={classNames(
            'flex py-1.5 rounded-md',
            'text-label-primary hover:bg-system-success-hover'
          )}
          onClick={() => handleActionClick(action)}
          onMouseDown={(e) => e.preventDefault()}
        >
          {action.content}
        </button>
      ))}
    </div>
  );
};
