/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum BuilderSessionStateRequestStatus {
    INFLIGHT = 'Inflight',
    USER_CANCELLED = 'UserCancelled',
    STALE = 'Stale',
    ERROR_OCCURRED = 'ErrorOccurred',
    COMPLETED = 'Completed',
}
