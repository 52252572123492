/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserAuthenticationRequirement = {
    provider: string;
    instance_id: string;
    type: UserAuthenticationRequirement.type;
    scopes?: string;
    fields?: Array<string>;
    needs_to_perform_authentication_again?: boolean;
};

export namespace UserAuthenticationRequirement {

    export enum type {
        OAUTH = 'OAuth',
        API_KEY = 'ApiKey',
        CUSTOM = 'Custom',
    }


}

