/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileInfo } from '../models/FileInfo';
import type { FileUploadDetails } from '../models/FileUploadDetails';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AppFileService {

    /**
     * Uploads app files and return access path
     * Uploads app files that needs to be hosted and returns access path to those to allow the backend to access the files when needed.
     * @param requestBody
     * @returns FileUploadDetails Successful Response
     * @throws ApiError
     */
    public static appFileHostLazyAppFiles(
        requestBody: FileInfo,
    ): CancelablePromise<FileUploadDetails> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/app_files/upload_files',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
