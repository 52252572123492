/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import React from 'react';
import { LazyReactIconComponentType } from './typings';
import { ReactComponent as ColoredGoogle } from '../assets/colored-google.svg';

const LazyDarkSlothIcon: LazyReactIconComponentType = ({ color = 'none', size = 24, ...props }) => {
  const className = 'lazy-icon ' + (props.className || '');

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.5 16.5C14.6344 16.3315 17 13.1421 17 9C17 4.85786 13.6421 1.5 9.5 1.5C5.35786 1.5 2 4.85786 2 9C2 13.1421 4.42343 16.5 9.5 16.5ZM15.512 6.69477C14.3837 6.04654 12.5774 5.52088 11.5574 6.65731C10.2674 8.0944 11.2672 9.49211 11.8765 9.82584C12.1905 9.99785 12.5535 10.1327 12.9172 10.2677C14.1376 10.721 15.3645 11.1767 14.7656 13.0479C14.658 13.1929 14.5432 13.3315 14.4213 13.4635C13.4247 14.5434 11.8395 15.3069 9.48368 15.3875C7.18144 15.3839 5.60624 14.6273 4.60393 13.5306C4.46556 13.3792 4.33606 13.2191 4.21559 13.0507C3.61446 11.1772 4.84197 10.7213 6.06306 10.2678C6.4267 10.1327 6.78978 9.99785 7.10382 9.82584C7.7131 9.49211 8.71288 8.0944 7.42291 6.65731C6.41047 5.52939 4.6236 6.03876 3.49373 6.68024C4.42399 4.3216 6.73214 2.61249 9.5 2.61249C12.2735 2.61249 14.5855 4.32862 15.512 6.69477ZM5.95146 9C6.36473 9 6.69974 8.6461 6.69974 8.20954C6.69974 7.77299 6.36473 7.41909 5.95146 7.41909C5.5382 7.41909 5.20319 7.77299 5.20319 8.20954C5.20319 8.6461 5.5382 9 5.95146 9ZM12.2805 8.20954C12.2805 8.6461 12.6155 9 13.0288 9C13.4421 9 13.7771 8.6461 13.7771 8.20954C13.7771 7.77299 13.4421 7.41909 13.0288 7.41909C12.6155 7.41909 12.2805 7.77299 12.2805 8.20954Z"
        fill="#171717"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.91723 11.4713C8.72739 12.3271 10.0409 12.3271 10.8511 11.4713C11.0182 11.2948 11.2891 11.2948 11.4561 11.4713C11.6232 11.6478 11.6232 11.934 11.4561 12.1105C10.3118 13.3193 8.45651 13.3193 7.31219 12.1105C7.14511 11.934 7.14511 11.6478 7.31219 11.4713C7.47927 11.2948 7.75015 11.2948 7.91723 11.4713Z"
        fill="#171717"
      />
    </svg>
  );
};

export default LazyDarkSlothIcon;
