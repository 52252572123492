import { UserPermissions, useAuthStore } from '../store/auth';

export async function updateUserPermissions(email: string) {
  try {
    const emailHost = email.split('@')[1];
    if (emailHost === 'datamilk.app' || emailHost === 'getlazy.ai') {
      useAuthStore.setState({
        userPermissions: {
          isUserAllowedToPrompt: true,
          isUserAllowedToPublish: true,
          isUserAllowedToRunApp: true,
          isUserAllowedToConfigureCustomDomain: true,
          isUserAllowedToConfigureProMemory: true,
        },
      });
    } else {
      const response = await fetch(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${process.env.REACT_APP_LAZY_CHECK_EMAIL_SUBSCRIPTION}${email}`
      );
      const body = (await response.json()) as unknown as UserPermissions;

      useAuthStore.setState({ userPermissions: body });
    }
  } catch (_) {}
}
