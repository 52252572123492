import React from 'react';
import FlashlightIcon from 'remixicon-react/FlashlightFillIcon';
import Button from '../components/base/Button';
import { useUserAnalyticsStore } from '../store/userAnalytics';
import { LAZY_ACCOUNTS_DOMAIN, LAZY_PLAN_PAGE, TEST_UPGRADE_TO_PRO_BUTTON } from '../constants';
import classNames from 'classnames';

export const upgradeToProButtonHandler = () => {
  const pricingUrl = useUserAnalyticsStore
    .getState()
    .addUtmsToUrl(`${LAZY_ACCOUNTS_DOMAIN}${LAZY_PLAN_PAGE}`);
  window.open(pricingUrl, '_blank');
};

interface UpgradeToProButtonProps {
  customButtonText?: string;
  hideIcon?: boolean;
  customStyles?: string;
}

export const UpgradeToProButton = (props: UpgradeToProButtonProps) => {
  return (
    <Button
      dataTestid={TEST_UPGRADE_TO_PRO_BUTTON}
      className={classNames(
        'bg-purple-500 text-white hover:bg-purple-500/80 gap-1',
        props.customStyles
      )}
      onClick={upgradeToProButtonHandler}
      iconProps={!props.hideIcon ? { icon: FlashlightIcon, iconSize: 16 } : undefined}
    >
      {props.customButtonText || 'Upgrade to Pro'}
    </Button>
  );
};
