import React from 'react';
import classNames from 'classnames';
import { UpgradeToProButton } from './UpgradeToProButton';
import { isMobileDevice } from '../utils/deviceDimensions';

export const UpgradeToProNotice = () => {
  return (
    <div
      className={'w-full h-full flex flex-1 items-center justify-center bg-background-secondary'}
    >
      <div
        className={classNames('flex gap-4 flex-col p-3 items-center', {
          'gap-3 p-2': isMobileDevice(),
        })}
      >
        <div className={classNames('font-semibold items-center text-center max-w-[220px] text-xl')}>
          You need to upgrade to run your app
        </div>

        <UpgradeToProButton customButtonText="Upgrade plan" />
      </div>
    </div>
  );
};
