/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Permissions {
    APP_LIST = 'app.list',
    APP_GET = 'app.get',
    APP_CREATE = 'app.create',
    APP_UPDATE = 'app.update',
    INSTANCE_DEPLOY = 'instance.deploy',
    INSTANCE_COMMAND = 'instance.command',
    ORG_GET = 'org.get',
    ORG_CREATE = 'org.create',
    ORG_UPDATE = 'org.update',
    ROLE_GET = 'role.get',
    ROLE_CREATE = 'role.create',
    ROLE_LIST = 'role.list',
    APP_FOLDER_LIST = 'app_folder.list',
    APP_FOLDER_CREATE = 'app_folder.create',
    APP_FOLDER_UPDATE = 'app_folder.update',
}
