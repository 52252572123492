import { LazyApp } from '@lazy-frontend/api/generated';

import { useAppStore } from '../../../store/app';
import { APPS_TEMPLATES_FILTERS, ARCHIVED_FILTERS, PUBLISHED_STATUS_FILTERS } from './constants';

export const applyArchivedFilter = (currentFilterValue: string) => {
  return (app: LazyApp) => {
    if (currentFilterValue === ARCHIVED_FILTERS.ALL) {
      return true;
    } else if (currentFilterValue === ARCHIVED_FILTERS.ARCHIVED) {
      return app.archive_date !== null;
    } else if (currentFilterValue === ARCHIVED_FILTERS.NON_ARCHIVED) {
      return app.archive_date === null;
    }
    return true;
  };
};

export const applyPublishedStatusFilter = (currentFilterValue: string) => {
  return (app: LazyApp) => {
    const publishedAppVersionsMap = useAppStore.getState().publishedAppVersionsMap;
    if (currentFilterValue === PUBLISHED_STATUS_FILTERS.ALL) {
      return true;
    } else if (currentFilterValue === PUBLISHED_STATUS_FILTERS.PUBLISHED) {
      return publishedAppVersionsMap.get(app.id);
    } else if (currentFilterValue === PUBLISHED_STATUS_FILTERS.UNPUBLISHED) {
      return !publishedAppVersionsMap.get(app.id);
    }
    return true;
  };
};

export const applyAppsAndTemplatesFilter = (currentFilterValue: string) => {
  return (app: LazyApp) => {
    if (currentFilterValue === APPS_TEMPLATES_FILTERS.ALL) {
      return true;
    } else if (currentFilterValue === APPS_TEMPLATES_FILTERS.APPS) {
      return !app.is_template;
    } else if (currentFilterValue === APPS_TEMPLATES_FILTERS.TEMPLATES) {
      return app.is_template;
    }
    return true;
  };
};
