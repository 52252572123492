import { OpenAPI } from './generated';
import { ApiRequestOptions } from './generated/core/ApiRequestOptions';
import { useAuthStore } from '../store/auth';

export const setupHeaders = () => {
  OpenAPI.HEADERS = async (_options: ApiRequestOptions) => {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    };

    await useAuthStore.getState().refreshUserOnce();
    const user = useAuthStore.getState().firebaseUser;
    if (user !== null) {
      const identityToken = await user.getIdToken();
      headers.Authorization = `Bearer ${identityToken}`;
    }

    return headers;
  };
};
