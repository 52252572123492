/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CostEventPostModel } from '../models/CostEventPostModel';
import type { EventPostModel } from '../models/EventPostModel';
import type { LazyContextData } from '../models/LazyContextData';
import type { StatsSchemaResponse } from '../models/StatsSchemaResponse';
import type { StatsSchemaSnapshot } from '../models/StatsSchemaSnapshot';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StatsService {

    /**
     * Gets historical statistics snapshots
     * Gets a historical series of statistic measurements snapshots given
     * the start and end dates of the series.
     * @param timeWindowBegin
     * @param timeWindowEnd
     * @returns StatsSchemaResponse Successful Response
     * @throws ApiError
     */
    public static statsGetMetricsHistory(
        timeWindowBegin?: string,
        timeWindowEnd?: string,
    ): CancelablePromise<StatsSchemaResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/stats',
            query: {
                'time_window_begin': timeWindowBegin,
                'time_window_end': timeWindowEnd,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Calculate, store and return a snapshot of statistical data
     * This entry point should be called periodically so to generate a time series
     * of statistical data.
     * @returns StatsSchemaSnapshot Successful Response
     * @throws ApiError
     */
    public static statsTakeStatsDataSnapshot(): CancelablePromise<StatsSchemaSnapshot> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/stats',
        });
    }

    /**
     * Stores a new event
     * Stores a new event that will be used for several statistics
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static statsStoreEvent(
        requestBody: EventPostModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/stats/event',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Stores a new cost event
     * Stores a new cost event that will be used to track costs
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static statsStoreCostEvent(
        requestBody: CostEventPostModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/stats/cost',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Decodes an encoded context information
     * Decodes an encoded context information and returns the decoded data
     * @param requestBody
     * @returns LazyContextData Successful Response
     * @throws ApiError
     */
    public static statsDecodeContext(
        requestBody: string,
    ): CancelablePromise<LazyContextData> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/stats/decode_context',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
