export const ARCHIVED_FILTERS = {
  NON_ARCHIVED: 'non-archived',
  ARCHIVED: 'archived',
  ALL: 'all',
};

export const APPS_TEMPLATES_FILTERS = {
  ALL: 'all',
  APPS: 'apps',
  TEMPLATES: 'templates',
};

export const PUBLISHED_STATUS_FILTERS = {
  ALL: 'all',
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished',
};
