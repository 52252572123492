/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum CostType {
    LLM_TOKENS = 'LLM_TOKENS',
    OPENAI_IMAGE = 'OPENAI_IMAGE',
    GCP_COMPUTE_ENGINE_APP_UPTIME = 'GCP_COMPUTE_ENGINE_APP_UPTIME',
    EGRESS_NETWORK = 'EGRESS_NETWORK',
}
