import React from 'react';
import SlothConfused from '../assets/sloth-confused.png';
const AppLoadingTest = () => {
  return (
    <div
      className="flex flex-col justify-middle gap-[30px]
    py-12 items-center justify-center mx-auto"
    >
      <img className="rounded-xl h-36" referrerPolicy="no-referrer" src={SlothConfused as string} />
      <span className="text-sm text-label-light-grey font-medium">Your app will be here</span>
    </div>
  );
};

export default AppLoadingTest;
