/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import Loader4LineIcon from 'remixicon-react/Loader4LineIcon';
import NotLoadedImage from '../assets/not-loaded-image.svg';
import DefaultAppIcon from '../assets/default-app-icon.svg';
import { useAppStore } from '../store/app';
import { AppService } from '../api/generated';

export interface ImageLoaderProps {
  src: string;
  maxTrials: number;
  isIcon?: boolean;
}

export function getImageName(url: string): string {
  // extracting file name from the url.
  const urlTokens = url.split('?')[0].split('/');
  return urlTokens[urlTokens.length - 1];
}

export function fetchAppImage(
  imageName: string,
  retries = 0,
  maxRetries: number,
  retryInterval = 500
): Promise<string> {
  return AppService.appGetAppIcon(imageName)
    .then((response) => {
      if (!response) {
        return ''; // Return an empty string if the response is falsy.
      }
      return `data:image/png;base64,${response}`;
    })
    .catch(() => {
      if (retries < maxRetries) {
        return new Promise<string>((resolve) =>
          setTimeout(() => {
            // eslint-disable-next-line max-len
            // eslint-disable-next-line @typescript-eslint/no-floating-promises, promise/catch-or-return
            fetchAppImage(imageName, retries + 1, maxRetries, 2 * retryInterval).then(resolve);
          }, retryInterval)
        );
      } else {
        return ''; // Ensure this also returns a string.
      }
    });
}

export function setAppFavIcon(faveIcon: string) {
  const faviconLink = document.querySelector("link[rel='icon']");
  if (faveIcon && faviconLink) {
    faviconLink.setAttribute('href', faveIcon);
  }
}

function ImageLoader(props: ImageLoaderProps) {
  const [imageSrc, setImageSrc] = useState('');
  const [imageContent, setImageContent] = useState('');
  const [isLoading, setLoading] = useState(true);

  function getFavIconContent() {
    return imageContent;
  }

  useEffect(() => {
    const retries = 0;
    const maxRetries = props.maxTrials;

    const imageName = getImageName(props.src);

    const fetchImage = () => {
      const appStoreImageURLMap = useAppStore.getState().appImageURLMap;
      fetchAppImage(imageName, retries, maxRetries)
        .then((response) => {
          if (response) {
            setImageSrc(response);
            setImageContent(response);
            appStoreImageURLMap.set('lazy-app-icon', response);
          } else {
            setImageSrc(DefaultAppIcon as string);
            setImageContent(DefaultAppIcon as string);
            appStoreImageURLMap.set('lazy-app-icon', DefaultAppIcon as string);
          }
          return setLoading(false);
        })
        .catch(() => {
          setImageSrc(DefaultAppIcon as string);
          setImageContent(DefaultAppIcon as string);
          appStoreImageURLMap.set('lazy-app-icon', DefaultAppIcon as string);
          setLoading(false);
        });
    };

    fetchImage();
  }, [props.src, props.maxTrials]);

  useEffect(() => {
    setAppFavIcon(getFavIconContent());
  }, [imageContent, props.isIcon]);
  return (
    <div className="flex justify-center items-center h-full">
      {isLoading ? (
        <div
          className="bg-cover bg-center h-full w-full flex justify-center items-center"
          style={{ backgroundImage: `url(${NotLoadedImage as string})` }}
        >
          <Loader4LineIcon className="animate-spin" size="1.2rem" />
        </div>
      ) : (
        imageSrc && (
          <img className="rounded-lg" referrerPolicy="no-referrer" src={imageSrc} alt="" />
        )
      )}
    </div>
  );
}

export default ImageLoader;
