import React, { ChangeEvent, memo } from 'react';
import classNames from 'classnames';

interface SwitchProps {
  name: string;
  label: string;
  checked?: boolean;
  onChange: (value: boolean) => void;
  className?: string;
  disabled?: boolean;
  dataTestid?: string;
}

export const Switch = memo(
  ({ checked = false, onChange, label, name, className, disabled, dataTestid }: SwitchProps) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.checked);
    };

    return (
      <div className={classNames('grid grid-flow-col gap-2 justify-start', className)}>
        <label className="w-[31px] h-5 relative inline-block">
          <input
            id={`switch-${name}`}
            type="checkbox"
            className="opacity-0 w-0 h-0"
            checked={checked}
            onChange={handleChange}
            disabled={disabled}
          />
          <span
            className={classNames(
              `absolute cursor-pointer top-0 left-0 bottom-0 right-0 transition 
            duration-[400ms] bg-neutral-200 round rounded-full before:rounded-full
            before:absolute before:content-[''] before:h-3.5 before:w-3.5 before:left-0.5 
            before:bottom-[3px] before:bg-white before:transition before:duration-[400ms]`,
              {
                'bg-system-accent before:translate-x-[13px]': checked,
              }
            )}
          ></span>
        </label>
        <label htmlFor={`switch-${name}`} className="cursor-pointer" data-testid={dataTestid}>
          {label}
        </label>
      </div>
    );
  }
);
