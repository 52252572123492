/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BuilderSessionStateRequestStatus } from '../models/BuilderSessionStateRequestStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BuilderSessionStateService {

    /**
     * Update builder session state
     * Updates builder session state
     * @param builderSessionStateId
     * @param status
     * @returns any Successful Response
     * @throws ApiError
     */
    public static builderSessionStateUpdateBuilderSessionState(
        builderSessionStateId: string,
        status: BuilderSessionStateRequestStatus,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/builder_session_state/{builder_session_state_id}',
            path: {
                'builder_session_state_id': builderSessionStateId,
            },
            query: {
                'status': status,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
