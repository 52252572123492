/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LazyAppFolder } from '../models/LazyAppFolder';
import type { LazyAppFolderCreate } from '../models/LazyAppFolderCreate';
import type { LazyAppFolderUpdate } from '../models/LazyAppFolderUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AppFolderService {

    /**
     * Get app folders
     * Returns lists of all app folders
     * @param orgId
     * @returns LazyAppFolder Successful Response
     * @throws ApiError
     */
    public static appFolderGetAllFolders(
        orgId?: string,
    ): CancelablePromise<Array<LazyAppFolder>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/app_folder',
            query: {
                'org_id': orgId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create new folder
     * Creates a new app folder
     * @param requestBody
     * @returns LazyAppFolder Successful Response
     * @throws ApiError
     */
    public static appFolderCreateFolder(
        requestBody: LazyAppFolderCreate,
    ): CancelablePromise<LazyAppFolder> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/app_folder',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update folder
     * Updates an existing app folder
     * @param folderId
     * @param requestBody
     * @returns LazyAppFolder Successful Response
     * @throws ApiError
     */
    public static appFolderUpdateFolder(
        folderId: string,
        requestBody: LazyAppFolderUpdate,
    ): CancelablePromise<LazyAppFolder> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/app_folder/{folder_id}',
            path: {
                'folder_id': folderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
