import React from 'react';
import ArrowRightDownIcon from 'remixicon-react/ArrowRightDownLineIcon';
import { StarterPrompt as StarterPromptType } from '../../../api/generated';

interface StarterPromptProps {
  starterPrompt: StarterPromptType;
  submitStarterPrompt: (starterPrompt: string) => void;
}

const StarterPrompt = (props: StarterPromptProps) => {
  return (
    <div
      className="flex items-center self-stretch py-2 px-3 text-system-accent hover:bg-blue-100
        rounded-lg border border-system-separator bg-system-accent-hover cursor-pointer"
      onClick={() => props.submitStarterPrompt(props.starterPrompt.prompt)}
    >
      <div className="flex-1 text-sm font-medium leading-5">
        {props.starterPrompt.label || props.starterPrompt.prompt}
      </div>
      <ArrowRightDownIcon size={20} />
    </div>
  );
};

export default StarterPrompt;
