import classNames from 'classnames';
import React, { Ref, useEffect } from 'react';

import { LazyApp, LazyAppCustomDomain } from '../../api/generated';
import useModalVisibility from '../../hooks/modalVisibility';
import { isMobileDevice } from '../../utils/deviceDimensions';
import DeleteBinLineIcon from 'remixicon-react/DeleteBinLineIcon';
import { deleteCustomDomainForApp } from '../../api/BuilderApi';
import { toast } from 'react-toastify';
import ActionButton from '../base/ActionButton';

interface ActionsMenuProps {
  app: LazyApp;
  customDomain: LazyAppCustomDomain;
  onClose: () => void;
  setIsLoading: (boolean) => void;
}

// eslint-disable-next-line max-lines-per-function
export const ActionsMenu = ({ app, customDomain, onClose, setIsLoading }: ActionsMenuProps) => {
  const { itemRef, isVisible } = useModalVisibility(true);

  useEffect(() => {
    if (!isVisible) {
      onClose();
    }
  }, [isVisible]);

  const onDeleteCustomDomain = () => {
    setIsLoading(true);
    return deleteCustomDomainForApp(app.id, customDomain.id)
      .then(() => {
        toast.success('Domain removed successfully.');
        return null;
      })
      .catch(() => {
        toast.error('Domain failed to be removed. Please try again');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div
      className={classNames(
        'flex flex-col py-1.5 bg-white rounded',
        'z-[5] absolute top-0 right-0',
        {
          'gap-[2px]': !isMobileDevice(),
          'gap-[4px]': isMobileDevice(),
        }
      )}
      ref={itemRef as Ref<HTMLDivElement>}
    >
      <ActionButton
        buttonType="danger"
        fillState="light"
        onClick={() =>
          // eslint-disable-next-line no-void
          void onDeleteCustomDomain()
        }
      >
        <DeleteBinLineIcon className="h-5" />
        Remove
      </ActionButton>
    </div>
  );
};
