import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import LoadingPage from './LoadingPage';
import { useOrganizationStore } from '../store/organization';

const AcceptOrganizationInviteInterstitial = () => {
  const params = useParams();
  const navigate = useNavigate();
  const inviteId = params.inviteId as string;
  const orgId = params.orgId as string;
  const [displayError, setDisplayError] = useState('');
  const { error, acceptUserInvite } = useOrganizationStore();

  const acceptInvite = async () => {
    await acceptUserInvite(orgId, inviteId);
  };

  useEffect(() => {
    acceptInvite()
      .then(() => navigate('/'))
      .catch(
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
  }, []);

  useEffect(() => {
    if (error) {
      setDisplayError('Something went wrong with the invite.');
    }
  }, [error]);

  return <LoadingPage error={displayError} />;
};

export default AcceptOrganizationInviteInterstitial;
