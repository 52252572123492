import React, { useEffect, useState, useRef } from 'react';
import { useOrganizationStore, RoleNames } from '../store/organization';
import HotelLineIcon from 'remixicon-react/HotelLineIcon';
import ArrowDownIcon from 'remixicon-react/ArrowDownSLineIcon';
import ActionButton from './base/ActionButton';
import BasicModal from './BasicModal';
import OrganizationUserInvitesSender from './OrganizationUserInvitesSender';
// eslint-disable-next-line max-len
import { ReactComponent as PlaceHolderProfilePicture } from '../assets/placeholder-user-profile-picture.svg';
import classNames from 'classnames';
import { OrganizationUserRoleAssignment, OrganizationUserInvite, Role } from '../api/generated';
import { Loader } from './base/Loader';
import { ApproveModal } from '../components/base/ApproveModal';
import { toast } from 'react-toastify';
import { ReactComponent as CrossIcon } from '../assets/cross-icon.svg';
import {
  TEST_ID_TEAM_POP_UP_CLOSE_BUTTON,
  TEST_ID_TEAM_NAME_CONTAINER,
  TEST_ID_TEAM_NAME_INPUT,
  TEST_ID_TEAM_CHANGE_NAME_BUTTON,
  TEST_ID_TEAM_SAVE_CHANGED_NAME_BUTTON,
  TEST_ID_TEAM_CANCEL_CHANGED_NAME_BUTTON,
  TEST_ID_TEAM_MEMBERS_COUNT_CONTAINER,
  TEST_ID_TEAM_INVITES_CONTAINER,
  TEST_ID_TEAM_INVITE_EMAIL_CONTAINER,
  TEST_ID_TEAM_INVITE_STATUS_CONTAINER,
  TEST_ID_TEAM_INVITE_RESEND_BUTTON,
  TEST_ID_TEAM_INVITE_REMOVE_BUTTON,
  TEST_ID_TEAM_INVITE_REMOVE_CONFIRMATION_BUTTON,
} from '../constants';

interface UserInviteManagerProps {
  userInvite: OrganizationUserInvite;
}

// eslint-disable-next-line max-lines-per-function
const UserInviteManager: React.FC<UserInviteManagerProps> = ({ userInvite }) => {
  const { resendUserInvite, removeUserInvite } = useOrganizationStore();
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);

  const onResendInvite = async () => {
    await resendUserInvite(userInvite.id);
  };

  return (
    <div className="flex gap-1 my-2">
      {isApprovalModalOpen && (
        <ApproveModal
          message={
            <span className="whitespace-pre-line">
              Are you sure you want to remove
              <br />
              invite for {userInvite.user_email}?
            </span>
          }
          approveButton={{
            text: 'Remove Invite',
            className: 'bg-system-danger text-white',
            dataTestId: TEST_ID_TEAM_INVITE_REMOVE_CONFIRMATION_BUTTON,
          }}
          rejectButton={{
            text: 'Cancel',
          }}
          onApprove={() => {
            // eslint-disable-next-line
            removeUserInvite(userInvite.id).finally(() => {
              setIsApprovalModalOpen(false);
            });
          }}
          onReject={() => {
            setIsApprovalModalOpen(false);
          }}
        />
      )}
      <div className="flex gap-2 w-full">
        <PlaceHolderProfilePicture className="h-9 w-9" />
        <div className="flex flex-col">
          <span
            className="text-sm text-label-secondary"
            data-testid={TEST_ID_TEAM_INVITE_EMAIL_CONTAINER}
          >
            {userInvite.user_email}
          </span>
          <span
            className="text-xs text-label-tertiary"
            data-testid={TEST_ID_TEAM_INVITE_STATUS_CONTAINER}
          >
            {userInvite.status}
          </span>
        </div>
      </div>
      <ActionButton
        fillState="subtle"
        buttonType="primary"
        onClick={() => {
          // eslint-disable-next-line no-void
          void onResendInvite();
        }}
        data-testid={TEST_ID_TEAM_INVITE_RESEND_BUTTON}
      >
        Resend
      </ActionButton>
      <ActionButton
        buttonType="danger"
        onClick={() => {
          setIsApprovalModalOpen(true);
        }}
        data-testid={TEST_ID_TEAM_INVITE_REMOVE_BUTTON}
      >
        Remove
      </ActionButton>
    </div>
  );
};

const UsersInviteManager = () => {
  const { organizationBeingConfigured } = useOrganizationStore();
  if (organizationBeingConfigured?.organization_user_invites) {
    const userInvites: OrganizationUserInvite[] =
      organizationBeingConfigured?.organization_user_invites.filter(
        (invite) => invite.status !== OrganizationUserInvite.status.ACCEPTED
      );

    const sortedInvites = userInvites.slice().sort((a, b) => {
      const emailA = a.user_email.toLowerCase();
      const emailB = b.user_email.toLowerCase();

      if (emailA < emailB) {
        return -1;
      }
      if (emailA > emailB) {
        return 1;
      }
      return 0;
    });

    return (
      <div data-testid={TEST_ID_TEAM_INVITES_CONTAINER}>
        {sortedInvites.map((userInvite, index) => (
          <UserInviteManager key={index} userInvite={userInvite} />
        ))}
      </div>
    );
  }
  return null;
};

interface UserRoleManagerProps {
  userRoleAssignment: OrganizationUserRoleAssignment;
  isReadOnly: boolean;
}
// eslint-disable-next-line max-lines-per-function
const UserRoleManager: React.FC<UserRoleManagerProps> = ({ userRoleAssignment, isReadOnly }) => {
  const [dropDownEnabled, setDropDownEnabled] = useState(false);
  const [roleChangeRequestedTo, setRoleChangeRequestedTo] = useState<Role | null>(null);
  const [roleRemovalRequested, setRoleRemovalRequested] = useState(false);
  const { roles, changeUserRole, removeUserRole } = useOrganizationStore();

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as HTMLElement)) {
      setDropDownEnabled(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderRoleRemovalApprovalModal = () => {
    return (
      roleRemovalRequested && (
        <ApproveModal
          message={
            <span className="whitespace-pre-line">
              Are you sure you want to remove {userRoleAssignment.user.name}
              <br />
              {userRoleAssignment.role.name} role?
            </span>
          }
          approveButton={{
            text: 'Remove Role',
            className: 'bg-system-danger text-white',
          }}
          rejectButton={{
            text: 'Cancel',
          }}
          onApprove={() => {
            // eslint-disable-next-line
            removeUserRole(userRoleAssignment.id).finally(() => {
              setDropDownEnabled(false);
              setRoleRemovalRequested(false);
              return null;
            });
          }}
          onReject={() => {
            setRoleRemovalRequested(false);
          }}
        />
      )
    );
  };

  const renderRoleChangeApprovalModal = () => {
    return (
      roleChangeRequestedTo && (
        <ApproveModal
          message={
            <span className="whitespace-pre-line">
              Are you sure you want to change {userRoleAssignment.user.name}
              <br />
              role to {roleChangeRequestedTo.name}?
            </span>
          }
          approveButton={{
            text: 'Change Role',
            className: 'bg-system-danger text-white',
          }}
          rejectButton={{
            text: 'Cancel',
          }}
          onApprove={() => {
            // eslint-disable-next-line
            changeUserRole(userRoleAssignment.id, roleChangeRequestedTo.id).finally(() => {
              setDropDownEnabled(false);
              setRoleChangeRequestedTo(null);
              return null;
            });
          }}
          onReject={() => {
            setRoleChangeRequestedTo(null);
          }}
        />
      )
    );
  };

  return (
    <div className="flex gap-1 my-2 relative">
      {renderRoleChangeApprovalModal()}
      {renderRoleRemovalApprovalModal()}

      <div className="flex gap-2 w-full">
        <img
          className="rounded-full inline-block h-9 w-9"
          referrerPolicy="no-referrer"
          src={userRoleAssignment.user.picture_url}
          alt=""
        />
        <div className="flex flex-col">
          <span className="text-sm text-label-primary">{userRoleAssignment.user.name}</span>
          <span className="text-xs text-label-tertiary">{userRoleAssignment.user.email}</span>
        </div>
      </div>
      <ActionButton
        buttonType="tertiary"
        onClick={() => setDropDownEnabled(true)}
        disabled={isReadOnly}
        className={classNames({ 'cursor-not-allowed': isReadOnly })}
      >
        {userRoleAssignment.role.name}
        <ArrowDownIcon size={14} />
      </ActionButton>

      <div
        ref={dropdownRef}
        className={classNames('absolute right-0 top-8 w-[150px] z-10', {
          hidden: !dropDownEnabled,
        })}
      >
        <ul className="shadow bg-white">
          {roles.map((role) => (
            <li key={role.id}>
              <ActionButton
                className="w-full rounded-none"
                fillState="subtle"
                buttonType="tertiary"
                onClick={(e) => {
                  e.stopPropagation();
                  if (role.id !== userRoleAssignment.role.id) {
                    setRoleChangeRequestedTo(role);
                  } else {
                    setDropDownEnabled(false);
                  }
                }}
                contentAlignment="left"
              >
                {role.name}
              </ActionButton>
            </li>
          ))}

          <li className="border border-system-separator">
            <ActionButton
              buttonType="danger"
              fillState="subtle"
              className="w-full rounded-none"
              onClick={(e) => {
                e.stopPropagation();
                setRoleRemovalRequested(true);
              }}
              contentAlignment="left"
            >
              Remove
            </ActionButton>
          </li>
        </ul>
      </div>
    </div>
  );
};

interface UsersRoleManagerProps {
  isReadOnly: boolean;
}

const UsersRoleManager: React.FC<UsersRoleManagerProps> = ({ isReadOnly }) => {
  const { organizationBeingConfigured } = useOrganizationStore();
  if (organizationBeingConfigured?.organization_user_roles_assignment) {
    const userRoles: OrganizationUserRoleAssignment[] =
      organizationBeingConfigured?.organization_user_roles_assignment;
    return (
      <div>
        {userRoles.map((userRoleAssignment, index) => (
          <UserRoleManager
            key={index}
            userRoleAssignment={userRoleAssignment}
            isReadOnly={isReadOnly}
          />
        ))}
      </div>
    );
  }
  return null;
};

const LAZY_BUILT_FILE_UPLOADER_APP_URL = process.env
  .REACT_APP_LAZY_BUILT_FILE_UPLOADER_APP_URL as string;

// eslint-disable-next-line max-lines-per-function
export const OrganizationManagementModal = () => {
  const {
    organizationBeingConfigured,
    loadOrganization,
    updateOrganization,
    toggleOrganizationManagementModalState,
    isLoading,
    userOrganizationRoleAssignments,
    setIsLoading,
  } = useOrganizationStore();
  const [name, setName] = useState(organizationBeingConfigured?.name);
  const [nameEditMode, setNameEditMode] = useState(false);
  const isUserOrgAdmin = Boolean(
    userOrganizationRoleAssignments.find(
      (roleAssignment) =>
        roleAssignment.role.name === RoleNames.ADMIN &&
        roleAssignment.org.id === organizationBeingConfigured?.id
    )
  );

  const onUpdateName = async () => {
    if (name) {
      await updateOrganization({ name });
      setNameEditMode(false);
    }
  };

  // eslint-disable-next-line
  const onUpdateImage = async (image: string) => {
    await updateOrganization({ image });
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        const response = await fetch(LAZY_BUILT_FILE_UPLOADER_APP_URL, {
          method: 'POST',
          body: formData,
        });

        const data = (await response.json()) as { file_link: string };
        await onUpdateImage(data.file_link);
      } catch (error) {
        toast.error('Error uploading organization image.');
      }
    }
  };

  useEffect(() => {
    // eslint-disable-next-line
    loadOrganization(organizationBeingConfigured?.id).catch(() => {});
  }, []);

  const getLabelForMembersCount = () => {
    const totalMembersCount =
      organizationBeingConfigured?.organization_user_roles_assignment?.length || 0;
    const countNoun = totalMembersCount > 1 ? 'members' : 'member';
    return `${totalMembersCount} ${countNoun}`;
  };

  return (
    <BasicModal onHide={toggleOrganizationManagementModalState}>
      {isLoading && <Loader />}
      <div className="flex flex-col md:w-[500px] h-[60vh]">
        <div className="flex p-4">
          <h2 className="flex-1 text-base font-semibold">Manage Team</h2>
          <button
            data-testid={TEST_ID_TEAM_POP_UP_CLOSE_BUTTON}
            onClick={toggleOrganizationManagementModalState}
          >
            <CrossIcon className="w-2" />
          </button>
        </div>
        <hr className="mb-4" />
        <div className="overflow-y-auto h-full">
          <div className="p-6">
            <span className="font-medium text-base mb-4">Team</span>

            <div className="flex items-center align-center justify-between mb-6 mt-6">
              <span className="font-medium text-sm">Name:</span>
              {nameEditMode ? (
                <input
                  type="text"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  className="py-1.5 px-3 border border-system-separator rounded-md w-full mx-2"
                  data-testid={TEST_ID_TEAM_NAME_INPUT}
                />
              ) : (
                <span
                  className="text-sm flex-1 text-center"
                  data-testid={TEST_ID_TEAM_NAME_CONTAINER}
                >
                  {organizationBeingConfigured?.name}
                </span>
              )}
              {isUserOrgAdmin && (
                <>
                  {nameEditMode ? (
                    <>
                      <ActionButton
                        size="small"
                        buttonType="primary"
                        fillState="subtle"
                        onClick={() => {
                          setNameEditMode(false);
                          setName(organizationBeingConfigured?.name);
                        }}
                        data-testid={TEST_ID_TEAM_CANCEL_CHANGED_NAME_BUTTON}
                      >
                        Cancel
                      </ActionButton>
                      <ActionButton
                        size="small"
                        buttonType="secondary"
                        fillState="subtle"
                        onClick={() => {
                          // eslint-disable-next-line no-void
                          void onUpdateName();
                        }}
                        data-testid={TEST_ID_TEAM_SAVE_CHANGED_NAME_BUTTON}
                      >
                        Save
                      </ActionButton>
                    </>
                  ) : (
                    <ActionButton
                      size="small"
                      buttonType="primary"
                      fillState="subtle"
                      onClick={() => setNameEditMode(true)}
                      data-testid={TEST_ID_TEAM_CHANGE_NAME_BUTTON}
                    >
                      Change name
                    </ActionButton>
                  )}
                </>
              )}
            </div>

            <div className="flex items-center align-center justify-between mb-4 mt-4">
              <span className="font-medium text-sm">Image:</span>
              <span className="flex flex-1 justify-center">
                {organizationBeingConfigured?.image ? (
                  <img src={organizationBeingConfigured.image} className="w-8 h-8" />
                ) : (
                  <HotelLineIcon size={42} className="bg-system-accent rounded p-1 text-white" />
                )}
              </span>

              {isUserOrgAdmin && (
                <>
                  <label
                    htmlFor="TeamProfileImageInput"
                    className={
                      'cursor-pointer text-system-blue-6 bg-white justify-center items-center ' +
                      'hover:bg-system-hover-blue text-xs p-1 rounded'
                    }
                  >
                    Change Image
                  </label>
                  <input
                    id="TeamProfileImageInput"
                    type="file"
                    className="hidden"
                    onChange={(event) => {
                      // eslint-disable-next-line no-void
                      void handleFileChange(event);
                    }}
                  />
                </>
              )}
            </div>
          </div>

          <hr />
          <div className="p-6">
            <span className="font-medium mb-2">Invite new members</span>
            <OrganizationUserInvitesSender />

            <span
              className="text-label-secondary text-sm mt-6 mb-2"
              data-testid={TEST_ID_TEAM_MEMBERS_COUNT_CONTAINER}
            >
              {getLabelForMembersCount()}
            </span>
            <UsersInviteManager />
            <UsersRoleManager isReadOnly={!isUserOrgAdmin} />
          </div>
        </div>
      </div>
    </BasicModal>
  );
};
