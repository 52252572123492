import { USER_LOGGED_IN_KEY_LOCAL_STORAGE, useAuthStore } from './store/auth';
import { useEffect } from 'react';
import routes from './routes';
import { useRoutes } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useUserAnalyticsStore } from './store/userAnalytics';
import { attemptSetWebFlowCookie } from './utils/webflowCookie';
import { INITIAL_PROMPT_KEY } from './constants';

// eslint-disable-next-line max-lines-per-function
function App() {
  const [searchParams, _setSearchParams] = useSearchParams();
  const { firebaseUser, getUserDetails, isUserSignedinEventEmitted, syncSupportUsersIdList } =
    useAuthStore();
  const { addUtmParam } = useUserAnalyticsStore();

  const utmParams = [...searchParams.keys()]
    .filter((key) => key.startsWith('utm_'))
    .map((key) => ({ key, values: searchParams.getAll(key) }));

  useEffect(() => {
    utmParams.forEach((utm) => addUtmParam(utm.key, utm.values));
  }, [window.location.href]);

  useEffect(() => {
    useAuthStore
      .getState()
      .refreshUserOnce()
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch((_e) => {
        // We don't really care about errors at this step -- we just want to keep the store
        // updated with the latest user state information
      });

    const promptQueryParam = searchParams.get('prompt');
    if (promptQueryParam) {
      localStorage.setItem(INITIAL_PROMPT_KEY, promptQueryParam);
    }
  }, []);

  useEffect(() => {
    if (
      firebaseUser &&
      localStorage.getItem(USER_LOGGED_IN_KEY_LOCAL_STORAGE) &&
      !window.location.href.includes('/logout')
    ) {
      attemptSetWebFlowCookie(0, 1);
    }
  }, [window.location.href]);

  useEffect(() => {
    if (
      firebaseUser &&
      (isUserSignedinEventEmitted || localStorage.getItem(USER_LOGGED_IN_KEY_LOCAL_STORAGE))
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getUserDetails()
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return attemptSetWebFlowCookie(0, 1);
        })
        // error here is not critical so can be ignored
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch((_e) => {});

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      syncSupportUsersIdList().catch((_e) => {});
    }
  }, [firebaseUser?.uid, isUserSignedinEventEmitted]);

  return useRoutes(routes);
}

export default App;
