/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ListArtifacts = {
    /**
     * Lists artifacts generated by the app
     */
    command: ListArtifacts.command;
};

export namespace ListArtifacts {

    /**
     * Lists artifacts generated by the app
     */
    export enum command {
        LIST_ARTIFACTS = 'list_artifacts',
    }


}

