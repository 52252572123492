import React, { useState } from 'react';
import { useOrganizationStore } from '../store/organization';
import classNames from 'classnames';
import ActionButton from './base/ActionButton';
import { Organization } from '../api/generated';
import BasicModal from './BasicModal';
import OrganizationUserInvitesSender from './OrganizationUserInvitesSender';
import { Loader } from './base/Loader';
import { ReactComponent as CrossIcon } from '../assets/cross-icon.svg';
import {
  TEST_ID_TEAM_POP_UP_CLOSE_BUTTON,
  TEST_ID_NEW_TEAM_NAME_INPUT,
  TEST_ID_CREATE_NEW_TEAM_BUTTON,
  TEST_ID_CREATE_NEW_TEAM_SKIP_BUTTON,
} from '../constants';

interface OrganizationCreationModalProps {
  onOrganizationCreation: (organization: Organization) => void;
}

// eslint-disable-next-line max-lines-per-function
const OrganizationCreationModal: React.FC<OrganizationCreationModalProps> = ({
  onOrganizationCreation,
}) => {
  const [organizationName, setOrganizationName] = useState('');
  const { createOrganization } = useOrganizationStore();
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrganizationName(e.target.value);
  };

  const handleCreateOrganization = async () => {
    const organization = await createOrganization(organizationName);
    if (organization) {
      onOrganizationCreation(organization);
    }
  };

  return (
    <div>
      <div className="flex flex-col items-center justify-center mb-4 mt-4 font-semibold text-xl">
        <span>Choose a name</span>
        <span>for your team</span>
      </div>

      <input
        type="text"
        required
        value={organizationName}
        onChange={handleInputChange}
        placeholder="Enter team name"
        className="w-full py-1.5 px-3 border border-system-separator rounded-md"
        data-testid={TEST_ID_NEW_TEAM_NAME_INPUT}
      />
      <div className="flex m-4 justify-center">
        <ActionButton
          onClick={() => {
            // eslint-disable-next-line no-void
            void handleCreateOrganization();
          }}
          disabled={organizationName.trim().length === 0}
          data-testid={TEST_ID_CREATE_NEW_TEAM_BUTTON}
        >
          Create Team
        </ActionButton>
      </div>
    </div>
  );
};

interface OrganizationInvitesModalProps {
  organization: Organization;
}

// eslint-disable-next-line max-lines-per-function
const OrganizationInvitesModal: React.FC<OrganizationInvitesModalProps> = ({ organization }) => {
  const {
    toggleCreateNewOrganizationModalState,
    toggleOrganizationManagementModalState,
    setOrganizationBeingConfigured,
  } = useOrganizationStore();

  const onOrganizationSetupCompletion = (organization: Organization) => {
    setOrganizationBeingConfigured(organization);
    toggleCreateNewOrganizationModalState();
    toggleOrganizationManagementModalState();
  };

  return (
    <div>
      <div className="flex flex-col items-center justify-center">
        <span className="font-semibold text-xl mb-2 mt-4">Add team members</span>

        <span className="text-xs text-label-secondary mb-4">
          You can update user permissions after setting up.
        </span>
      </div>

      <OrganizationUserInvitesSender
        onInvitesSent={() => {
          onOrganizationSetupCompletion(organization);
        }}
      />

      <div className="flex m-4 justify-center">
        <ActionButton
          size="small"
          buttonType="primary"
          fillState="subtle"
          onClick={() => {
            window.location.href = '/';
          }}
          data-testid={TEST_ID_CREATE_NEW_TEAM_SKIP_BUTTON}
        >
          Skip for now
        </ActionButton>
      </div>
    </div>
  );
};

// eslint-disable-next-line max-lines-per-function
export const NewOrganizationSetupModal = () => {
  const [organization, setOrganization] = useState<Organization | null>(null);

  const { toggleCreateNewOrganizationModalState, isLoading, setActiveOrganization } =
    useOrganizationStore();

  const onOrganizationCreation = (org: Organization) => {
    setOrganization(org);
    setActiveOrganization(org);
  };

  return (
    <BasicModal onHide={toggleCreateNewOrganizationModalState}>
      {isLoading && <Loader />}
      <div className="flex flex-col md:w-[500px] h-[60vh]">
        <div className="flex p-4">
          <h2 className="flex-1 text-base font-semibold">Create Team</h2>
          <button
            data-testid={TEST_ID_TEAM_POP_UP_CLOSE_BUTTON}
            onClick={toggleCreateNewOrganizationModalState}
          >
            <CrossIcon className="w-2" />
          </button>
        </div>

        <hr className="mb-4" />

        <div className="p-6">
          <div className="flex text-xs  gap-3 items-center justify-center">
            <div
              className={classNames(
                'flex items-center gap-1',
                { 'text-label-primary': !organization },
                { 'text-label-tertiary': organization }
              )}
            >
              <div
                className={classNames(
                  'w-5 h-5 rounded-full border flex items-center justify-center',
                  { 'border-label-primary': !organization },
                  { 'border-label-tertiary': organization }
                )}
              >
                1
              </div>
              <div className="">Name Team</div>
            </div>

            <div
              className={classNames(
                'flex items-center gap-1',
                { 'text-label-primary': organization },
                { 'text-label-tertiary': !organization }
              )}
            >
              <div
                className={classNames(
                  'w-5 h-5 rounded-full border flex items-center justify-center',
                  { 'border-label-primary': organization },
                  { 'border-label-tertiary': !organization }
                )}
              >
                2
              </div>
              <div className="">Add Team Members</div>
            </div>
          </div>

          {!organization && (
            <OrganizationCreationModal onOrganizationCreation={onOrganizationCreation} />
          )}
          {organization && <OrganizationInvitesModal organization={organization} />}
        </div>
      </div>
    </BasicModal>
  );
};
