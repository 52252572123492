const MOBILE_VIEW_WIDTH = 940;

let cachedVisibleHeight = window.innerHeight;
let cachedVisibleWidth = window.innerWidth;
let cachedIsMobileDevice = window.top
  ? window.top.innerWidth <= MOBILE_VIEW_WIDTH
  : window.innerWidth <= MOBILE_VIEW_WIDTH;

export const updateCachedValues = () => {
  cachedVisibleHeight = window.innerHeight;
  cachedVisibleWidth = window.innerWidth;
  cachedIsMobileDevice = window.top
    ? window.top.innerWidth <= MOBILE_VIEW_WIDTH
    : window.innerWidth <= MOBILE_VIEW_WIDTH;
};

export const getVH = (): number => {
  return cachedVisibleHeight;
};

export const getVW = (): number => {
  return cachedVisibleWidth;
};

export const isMobileDevice = (): boolean => {
  return cachedIsMobileDevice;
};

window.addEventListener('resize', updateCachedValues);

updateCachedValues();
