import React from 'react';

export interface LinkCardMessageProps {
  body: string;
  buttonHref: string;
  buttonText: string;
}

export const LinkCardMessage = ({ body, buttonHref, buttonText }: LinkCardMessageProps) => {
  return (
    <div>
      <div className="border rounded-lg w-full max-w-[400px] m-3">
        <div className="flex justify-between p-4">
          <p className="font-semibold text-sm">{body}</p>
        </div>

        <div className="flex justify-start mt-2">
          <a
            href={buttonHref}
            target="_self"
            rel="noopener noreferrer"
            className="flex items-center justify-center font-semibold text-sm \
rounded-bl-lg rounded-br-lg bg-[#0600FE] text-white w-full py-2"
          >
            {buttonText}
          </a>
        </div>
      </div>
    </div>
  );
};

export default LinkCardMessage;
