import React, { useState } from 'react';
import RepeatIcon from 'remixicon-react/Repeat2FillIcon';
import { useAppStore } from '../../store/app';
import * as dataTestidConstants from '../../constants';
import classNames from 'classnames';

export const AutoUpdateTestApp = () => {
  const [isOn, setIsOn] = useState<boolean>(useAppStore.getState().autoUpdateTestApp);
  const toggleSwitch = () => {
    setIsOn(!isOn);
    useAppStore.setState({ autoUpdateTestApp: !isOn });
  };
  return (
    <div className="flex flex-col mb-3">
      <div className="text-sm mb-1 text-label-secondary font-medium">App update settings</div>
      <div className="flex flex-row gap-2">
        <RepeatIcon size={18} />
        <div className="flex">Auto update test app</div>
        <div
          data-testid={dataTestidConstants.TEST_APP_TOGGLE_AUTO_UPDATE_TEST_APP_BUTTON}
          className={classNames('w-8 h-4 flex items-center rounded-full p-1 cursor-pointer ml-4', {
            'bg-green-500': isOn,
            'bg-gray-400': !isOn,
          })}
          onClick={toggleSwitch}
        >
          <div
            className={`bg-white w-3 h-3 rounded-full shadow-md 
              transform transition-transform ${isOn ? 'translate-x-3' : ''}`}
          ></div>
        </div>
      </div>
    </div>
  );
  return null;
};
