import React, { forwardRef } from 'react';
import Button from './base/Button';
import RunAppIcon from 'remixicon-react/PlayFillIcon';
import * as dataTestidConstants from '../constants';
import { getDateFormatted } from './chat/MessageTimestamp';
import { isMobileDevice } from '../utils/deviceDimensions';
import { ActionForParentWindow } from '../types';

interface UpdateAppHeaderProps {
  versionCreationDate: string;
  onUpdate: () => void;
}

export const UpdateAppHeader = forwardRef<HTMLDivElement, UpdateAppHeaderProps>(
  ({ onUpdate, versionCreationDate }, ref) => {
    const isInsideIframe = window.self !== window.top;
    const bannerMessage = isMobileDevice() ? 'Version from ' : 'This version is from ';

    const informParentWindowThatUserUpdatedApp = () => {
      const action: ActionForParentWindow = {
        resetOutdatedTestVersionRunningStatus: true,
      };
      window.parent.postMessage(action, '*');
    };

    const onClickRunLatest = () => {
      if (isInsideIframe) {
        informParentWindowThatUserUpdatedApp();
      }
      onUpdate();
    };

    return (
      <div
        ref={ref}
        className="w-full py-2 flex justify-center bg-system-emerald text-xs items-center gap-3"
      >
        <span className="text-black">
          {bannerMessage}
          {getDateFormatted(new Date(versionCreationDate))}
        </span>
        <Button
          className="rounded text-white bg-system-success !h-6 px-1.5"
          onClick={onClickRunLatest}
          dataTestid={dataTestidConstants.TEST_APP_RESTART_TO_UPDATE_APP_BUTTON as string}
        >
          <RunAppIcon size={16} />
          {isInsideIframe ? 'Test' : 'Run'} latest instead
        </Button>
      </div>
    );
  }
);
