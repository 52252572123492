import { type RouteObject } from 'react-router';
import Layout from './components/Layout';
import React from 'react';
import LoginPage from './pages/login/LoginPage';
import BuilderPage from './pages/BuilderPage';
import StartWithTemplateInterstitial from './pages/StartWithTemplateInterstitial';
import AcceptOrganizationInviteInterstitial from './pages/AcceptOrganizationInviteInterstitial';
import AppRun from './pages/AppRun';
import AuthGuard, { AuthParameters } from './auth/AuthGuard';
import { USER_LOGGED_IN_KEY_LOCAL_STORAGE } from './store/auth';
import { useUserAnalyticsStore } from './store/userAnalytics';
import LogoutPage from './pages/login/Logout';
import AppNotFound from './pages/AppNotFoundPage';
import { UserService } from './api/generated';

let setWebFlowCookieBeforeRedirectLock = true;
const redirectSearchParams = (redirectLocation: Location) => {
  const utmParams = useUserAnalyticsStore.getState().utmParams;
  // eslint-disable-next-line compat/compat
  const urlSearchParams = new URLSearchParams();

  utmParams.forEach((values, key) => {
    values.forEach((value) => {
      urlSearchParams.append(key, value);
    });
  });

  if (redirectLocation.pathname !== '/') {
    urlSearchParams.append('redirect', redirectLocation.pathname + redirectLocation.search);
  }

  if (urlSearchParams.size) {
    return `?${urlSearchParams.toString()}`;
  }

  return '';
};

const alwaysAllowRoute = (_auth: AuthParameters, _location: Location) => null;

const lockedCookieRedirect = (redirectTo: string) => {
  if (setWebFlowCookieBeforeRedirectLock) {
    setWebFlowCookieBeforeRedirectLock = false;
    // eslint-disable-next-line max-len
    // eslint-disable-next-line promise/catch-or-return, @typescript-eslint/no-floating-promises
    UserService.userSetWebflowAuthCookie().then(() => {
      setWebFlowCookieBeforeRedirectLock = true;
      return (window.location.href = redirectTo);
    });
  }
};

const guardPrivateRoute = (auth: AuthParameters, location: Location) =>
  auth.userIsAuthenticated &&
  auth.userPermissions &&
  (auth.isUserSignedinEventEmitted || localStorage.getItem(USER_LOGGED_IN_KEY_LOCAL_STORAGE))
    ? null
    : { to: { pathname: '/login', search: redirectSearchParams(location) } };

const guardUnauthenticated = (auth: AuthParameters, location: Location) => {
  if (!auth.userIsAuthenticated) {
    return null;
  }

  const searchParams = new URLSearchParams(location.search);
  const redirectTo = searchParams.get('redirect');
  if (redirectTo) {
    const isExternal = /^https?:\/\//.test(redirectTo.toString());
    if (isExternal) {
      lockedCookieRedirect(redirectTo);
    } else {
      window.location.href = redirectTo;
    }
    return null;
  } else {
    return { to: { pathname: '/' }, options: { replace: true } };
  }
};

const routes: RouteObject[] = [
  {
    path: '*',
    element: <Layout />,
    children: [
      {
        index: true,
        element: (
          <AuthGuard guardFunction={guardPrivateRoute}>
            <BuilderPage />
          </AuthGuard>
        ),
      },
      {
        path: 'apps/:appId/build',
        element: (
          <AuthGuard guardFunction={guardPrivateRoute}>
            <BuilderPage />
          </AuthGuard>
        ),
      },
      {
        path: 'templates/copy/:templateId',
        element: (
          <AuthGuard guardFunction={guardPrivateRoute}>
            <StartWithTemplateInterstitial />
          </AuthGuard>
        ),
      },
      {
        path: 'run/:instanceId',
        element: (
          <AuthGuard guardFunction={guardPrivateRoute}>
            <AppRun />
          </AuthGuard>
        ),
      },
      {
        path: 'login',
        element: (
          <AuthGuard guardFunction={guardUnauthenticated}>
            <LoginPage />
          </AuthGuard>
        ),
      },
      {
        path: 'logout',
        element: (
          <AuthGuard guardFunction={alwaysAllowRoute}>
            <LogoutPage />
          </AuthGuard>
        ),
      },
      {
        path: '404',
        element: (
          <AuthGuard guardFunction={alwaysAllowRoute}>
            <AppNotFound />
          </AuthGuard>
        ),
      },
      {
        path: ':appId',
        element: (
          <AuthGuard guardFunction={guardPrivateRoute}>
            <AppRun />
          </AuthGuard>
        ),
      },
      {
        path: 'test_instance/:appId',
        element: (
          <AuthGuard guardFunction={guardPrivateRoute}>
            <AppRun testing={true} />
          </AuthGuard>
        ),
      },
      {
        path: 'accept-invite/:orgId/:inviteId',
        element: (
          <AuthGuard guardFunction={guardPrivateRoute}>
            <AcceptOrganizationInviteInterstitial />
          </AuthGuard>
        ),
      },
    ],
  },
];

export default routes;
