import {
  AppLogic,
  ChatSessionBuilder,
  ContentBuilderMessage,
  File,
  FileFormat,
} from '../api/generated/index';
import { EMPTY_FILE } from './CodeListing';

export const DEFAULT_APP_FILES: File[] = [
  {
    ...EMPTY_FILE,
    name: 'main.py',
    format: FileFormat.PYTHON,
    path: './',
  },
  {
    ...EMPTY_FILE,
    name: 'requirements.txt',
    format: FileFormat.TXT,
    path: './',
  },
];

export const extractFilesFromContentBuilderMessage = (
  message: ContentBuilderMessage | null
): File[] => {
  let result: File[] = [];
  if (message) {
    if (message.files && message.files.length > 0) {
      result = message.files;
    } else if (message.code && message.code.code) {
      result.push({
        name: 'main.py',
        path: './',
        format: FileFormat.PYTHON,
        content: message.code.code,
      });

      result.push({
        name: 'requirements.txt',
        path: './',
        format: FileFormat.TXT,
        content: message.requirements?.entries.join('\n') ?? '',
      });
    }
  }

  if (result.length === 0) {
    result = DEFAULT_APP_FILES;
  }

  return result;
};

export function extractLastMessageWithCode(
  session: ChatSessionBuilder
): ContentBuilderMessage | null {
  const codeSessionMessages = session.content_builder.filter(
    (c) => c.content.code !== null || (c.content.files?.length ?? 0) > 0
  );

  if (codeSessionMessages.length) {
    return codeSessionMessages[codeSessionMessages.length - 1].content ?? null;
  }

  return null;
}

export function extractAppLogic(session: ChatSessionBuilder): AppLogic | null {
  const latestcontentBuilderMessage = session.content_builder[session.content_builder.length - 1];
  if (latestcontentBuilderMessage) {
    return latestcontentBuilderMessage.content?.app_logic || null;
  }

  return null;
}
