import classNames from 'classnames';
import React from 'react';
interface GlobalModalBackdropPros {
  disableClickToCancelModal?: boolean;
}
const GlobalModalBackdrop = (props: GlobalModalBackdropPros) => {
  return (
    <div
      className={classNames('bg-lazy-light-grey fixed top-0 left-0 right-0 bottom-0 z-[60]', {
        'cursor-not-allowed': props.disableClickToCancelModal,
      })}
      onClick={(event) => {
        event.preventDefault();
      }}
    ></div>
  );
};

export default GlobalModalBackdrop;
