import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import { AppLogic } from '../api/generated';
import AppPublishingStatusDisplay from './AppPublishingStatusDisplay';
import { ApproveModal } from './base/ApproveModal';
import CopyToClipboardButton from './base/CopyToClipboardButton';

interface AppLogicDisplayProps {
  appLogic: AppLogic | null;
  isTemplate: boolean;
  organizationName?: string;
  appIconURL: string | null;
  isAppExist: boolean;
  lastPublishedAt: Date | null;
  publishedUrl: string | null;
  updateAppLogic: (newAppName: string, newAppDescription: string) => Promise<void>;
  onHide: () => void;
}

// eslint-disable-next-line max-lines-per-function, complexity
const AppLogicDisplay = forwardRef((props: AppLogicDisplayProps, ref) => {
  const [editedAppName, setEditedAppName] = useState(props.appLogic?.app_name || '');
  const [editedAppDescription, setEditedAppDescription] = useState(props.appLogic?.summary || '');
  const [approvalModalOpen, setApprovalModalOpen] = useState(false);

  useEffect(() => {
    setEditedAppName(props.appLogic?.app_name || '');
    setEditedAppDescription(props.appLogic?.summary || '');
  }, [props.appLogic?.app_name, props.appLogic?.summary]);

  const handleAppNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedAppName(event.target.value);
  };

  const handleAppDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditedAppDescription(event.target.value);
  };

  const hasUnsavedChanged = () =>
    editedAppDescription !== (props.appLogic?.summary || '') ||
    editedAppName !== (props.appLogic?.app_name || '');

  const onSave = () => {
    props
      .updateAppLogic(editedAppName, editedAppDescription)
      .then(() => {
        toast.success(`${props.isTemplate ? 'Template' : 'App'} info updated successfully`);
        return null;
      })
      .catch(() => {
        toast.error(
          `There was an error updating the ${
            props.isTemplate ? 'template' : 'app'
          } info. Please try again later.`
        );
      });
  };

  useImperativeHandle(ref, () => ({
    onSave,
    setApprovalModalOpen,
    hasUnsavedChanged,
  }));

  const renderApprovalModal = () => {
    return (
      <ApproveModal
        message={"Are you sure you don't want to save the changes?"}
        onApprove={() => {
          onSave();
          setApprovalModalOpen(false);
          props.onHide();
        }}
        onReject={() => {
          setApprovalModalOpen(false);
          setEditedAppName(props.appLogic?.app_name || '');
          setEditedAppDescription(props.appLogic?.summary || '');
          props.onHide();
        }}
      />
    );
  };

  return (
    <div
      className={classNames('flex flex-col h-full pt-6 pb-2', {
        'text-gray-400 select-none': props.isAppExist === false,
      })}
    >
      {approvalModalOpen && renderApprovalModal()}
      <div className="flex flex-col gap-[6px] py-3 px-6">
        <label className="flex text-xs font-medium">Name</label>
        <input
          className={classNames(
            'font-normal text-sm rounded border',
            'px-2 py-[6px] overflow-hidden'
          )}
          type="text"
          placeholder="App Name"
          value={editedAppName}
          disabled={false}
          onChange={handleAppNameChange}
        />
      </div>
      <div className="flex flex-col gap-[6px] py-3 px-6">
        <label className="flex text-xs font-medium">Description</label>
        <textarea
          className={classNames(
            'font-light min-h-[100px] text-label-primary rounded-md break-words border p-2'
          )}
          placeholder="App description"
          value={editedAppDescription}
          onChange={handleAppDescriptionChange}
          disabled={false}
        />
      </div>
      <div className="flex flex-col gap-[6px] py-3 px-6">
        <label className="flex text-xs font-medium">Builder Link</label>
        <div className="flex">
          <a
            href={window.location.href}
            target="_blank"
            className="text-blue-600 hover:underline truncate max-w-[calc(100%-2rem)]"
          >
            {window.location.href}
          </a>
          <CopyToClipboardButton url={window.location.href} />
        </div>
      </div>
      {props.appLogic?.app_name && (
        <div className="my-[6px] mx-6">
          <hr />
        </div>
      )}
      {props.appLogic?.app_name && (
        <AppPublishingStatusDisplay
          publishDate={props.lastPublishedAt}
          publishUrl={props.publishedUrl}
          isTemplate={props.isTemplate}
        />
      )}
    </div>
  );
});

export default AppLogicDisplay;
