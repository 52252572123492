import React, { useEffect, useState } from 'react';
import { TEST_ID_CHAT_ACTIONS_FOR_USER, TabBarItemsIndex } from '../../../constants';
import { useAppStore } from '../../../store/app';
import MissingFilesAction from '../ChatActionsForUser/MissingFilesAction';
import MissingSecretsAction from '../ChatActionsForUser/MissingSecretsAction';
import { File } from '../../../api/generated/models/File';
import { useChatStore } from '../../../store/chat';
import { useAuthStore } from '../../../store/auth';
import { FileUploadFile } from '@lazy-frontend/components/VSCodeEditor/models';

interface ChatActionsForUserProps {
  selectedTabIndex: React.Dispatch<React.SetStateAction<TabBarItemsIndex>>;
  isTemplate?: boolean;
  updateFiles?: (updatedFiles: File[]) => Promise<void>;
}

// eslint-disable-next-line max-lines-per-function
const ChatActionsForUser = (props: ChatActionsForUserProps) => {
  const [hasEmptySecrets, setHasEmptySecrets] = useState<boolean>(false);
  const [hasEmptyFiles, setHasEmptyFiles] = useState<boolean>(false);
  const [missingFiles, setMissingFiles] = useState<FileUploadFile[]>([]);
  const [showUserActions, setShowUserActions] = useState<boolean>(false);
  const currentUserPrompt = useChatStore((state) => state.currentUserPrompt);

  useEffect(() => {
    setHasEmptySecrets(useAppStore.getState().hasEmptySecrets);
  }, [useAppStore.getState().hasEmptySecrets]);

  useEffect(() => {
    setHasEmptyFiles(Boolean(useAppStore.getState().emptyAppFiles?.length));
  }, [useAppStore.getState().emptyAppFiles]);

  useEffect(() => {
    setMissingFiles(useAppStore.getState().emptyAppFiles);
  }, [useAppStore.getState().emptyAppFiles]);

  useEffect(() => {
    setShowUserActions(
      (hasEmptyFiles || (!props.isTemplate && hasEmptySecrets)) &&
        !useChatStore.getState().userInputLoading &&
        !currentUserPrompt &&
        useAuthStore.getState().userIsAuthenticated
    );
  }, [
    hasEmptyFiles,
    hasEmptySecrets,
    useChatStore.getState().userInputLoading,
    currentUserPrompt,
    useAuthStore.getState().userIsAuthenticated,
  ]);

  if (!showUserActions) {
    return null;
  }
  return (
    <div
      className="flex flex-col items-start gap-2 pt-0 pb-6 px-12"
      data-testid={TEST_ID_CHAT_ACTIONS_FOR_USER}
    >
      <span className="font-bold text-violet-500">Actions for you:</span>
      {hasEmptySecrets && !props.isTemplate && (
        <MissingSecretsAction selectedTabIndex={props.selectedTabIndex} />
      )}
      {hasEmptyFiles && props.updateFiles && (
        <MissingFilesAction missingFiles={missingFiles} updateFiles={props.updateFiles} />
      )}
    </div>
  );
};

export default ChatActionsForUser;
