export const sleep = (ms: number): Promise<void> => {
  // eslint-disable-next-line compat/compat
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const waitUntilFlagIsTrue = (flag: boolean[]): Promise<void> => {
  // eslint-disable-next-line compat/compat
  return new Promise((resolve) => {
    const checkFlag = () => {
      if (flag[0]) {
        resolve();
        return;
      }

      setTimeout(checkFlag, 100); // Check flag every 100 milliseconds
    };

    checkFlag(); // Start checking the flag
  });
};
