import React, { useEffect } from 'react';
import {
  BuilderSignInMessageContent,
  Message,
  MessageSourceType,
  MessageType,
  TextMessageContent,
  TextMessageContentFormat,
} from '../../types';
import LazyAvatar from '../../assets/sloth-avatar-blue.svg';
import { useChatStore } from '../../store/chat';
import AppEditor from '../../components/AppEditor';
import { useAuthStore } from '../../store/auth';
import { useSearchParams } from 'react-router-dom';

const SIGN_IN_FLOW_MESSAGES = [
  {
    source: {
      type: MessageSourceType.System,
      name: '',
      avatarUrl: LazyAvatar as string,
    },
    content: {
      format: TextMessageContentFormat.Plain,
      text: 'Welcome to the Lazy Builder! Please select your preferred method to continue',
      type: MessageType.Text,
    } as TextMessageContent,
  },
  {
    source: {
      type: MessageSourceType.System,
      name: '',
      avatarUrl: LazyAvatar as string,
    },
    content: {
      type: MessageType.BuilderSignIn,
    } as BuilderSignInMessageContent,
  },
] as Message[];

const LoginPage = () => {
  const userIsAuthenticated = useAuthStore((state) => state.userIsAuthenticated);

  const [searchParams, _setSearchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirect');

  useEffect(() => {
    useChatStore.setState(() => ({ userInputBlocked: true, messages: SIGN_IN_FLOW_MESSAGES }));
  }, [userIsAuthenticated]);

  useEffect(() => {
    if (redirectUrl) {
      useAuthStore.getState().saveRedirectUrl(redirectUrl);
    }
  }, [redirectUrl]);

  return <AppEditor appId={null} />;
};

export default LoginPage;
