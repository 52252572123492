import {
  AppFileService,
  CancelablePromise,
  FileInfo,
  FileUploadDetails,
} from '../../api/generated';
enum CodingFilesExtensions {
  CSource = '.c',
  CPPSource = '.cpp',
  CHeader = '.h',
  CSharpSource = '.cs',
  JavaSource = '.java',
  PythonFile = '.py',
  RubyFile = '.rb',
  GoSource = '.go',
  RustSource = '.rs',
  SwiftSource = '.swift',
  KotlinSource = '.kt',
  KotlinScript = '.kts',
  HTMLFile = '.html',
  HTMFile = '.htm',
  CSSFile = '.css',
  JavaScriptFile = '.js',
  JSXFile = '.jsx',
  TypeScriptFile = '.ts',
  TSXFile = '.tsx',
  PHPFile = '.php',
  VueFile = '.vue',
  SCSSFile = '.scss',
  SASSFile = '.sass',
  LESSFile = '.less',
  EJSFile = '.ejs',
  HBSFile = '.hbs',
  TwigFile = '.twig',
  JSPFile = '.jsp',
  ASPFile = '.asp',
  ASPXFile = '.aspx',
  ShellScript = '.sh',
  BatchFile = '.bat',
  PowerShellScript = '.ps1',
  PerlScript = '.pl',
  LuaScript = '.lua',
  JSONFile = '.json',
  XMLFile = '.xml',
  YAMLFile = '.yaml',
  YMLFile = '.yml',
  TOMLFile = '.toml',
  INIFile = '.ini',
  CFGFile = '.cfg',
  ENVFile = '.env',
  CONFFile = '.conf',
  GitIgnore = '.gitignore',
  GitAttributes = '.gitattributes',
  Makefile = 'Makefile',
  CMakeLists = 'CMakeLists.txt',
  GradleBuild = '.gradle',
  MavenBuild = '.maven',
  NPMIgnore = '.npmignore',
  TravisCIYML = '.travis.yml',
  Dockerfile = 'Dockerfile',
  ERBFile = '.erb',
  MustacheFile = '.mustache',
  LiquidFile = '.liquid',
  PugFile = '.pug',
  MarkdownFile = '.md',
  RSTFile = '.rst',
  LogFile = '.log',
  LockFile = '.lock',
  Text = '.txt',
  SQL = '.sql',
  DB = '.db',
  DBF = '.dbf',
  MDB = '.mdb',
  ACCDB = '.accdb',
  GITIGNORE = '.gitignore',
  GITKEEP = '.gitkeep',
  ESLINTIGNORE = '.eslintignore',
}

export function isFile(name: string): boolean {
  if (name.match(/(.py|.html|.js|.sh|.txt|.css|.json|.yaml|.md|.jpg)/g)) {
    return true;
  }
  return false;
}

const CODING_FILE_REGEX = new RegExp(
  `(${Object.values(CodingFilesExtensions)
    .map((ext) => ext.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'))
    .join('|')})$`,
  'i'
);

export function hasNonBinaryContent(fileName: string): boolean {
  // we currently don't check the actual content,
  // we just assume that all files without code file extension are binary
  return CODING_FILE_REGEX.test(fileName);
}

export async function computeHash(base64String: string): Promise<string> {
  if (!base64String) {
    return '';
  }

  const content = Uint8Array.from(atob(base64String), (char) => char.charCodeAt(0));
  const hashBuffer = await crypto.subtle.digest('SHA-256', content);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

export function sendFilesToBeUploaded(
  filesToBeUploaded: FileInfo[]
): CancelablePromise<FileUploadDetails>[] {
  const filesToBeUploadedPromises = filesToBeUploaded.map((file) => {
    return AppFileService.appFileHostLazyAppFiles({
      name: file.name,
      path: file.path,
      b64_content: file.b64_content,
      content_hash: file.content_hash,
    });
  });
  return filesToBeUploadedPromises;
}
