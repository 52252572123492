/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Stop = {
    /**
     * Stop the app if currently running
     */
    command: Stop.command;
};

export namespace Stop {

    /**
     * Stop the app if currently running
     */
    export enum command {
        STOP = 'stop',
    }


}

