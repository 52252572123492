import { TabBarItemsIndex } from '../../../constants';
import React from 'react';
import ArrowRightIcon from 'remixicon-react/ArrowRightLineIcon';

interface MissingSecretsActionProps {
  selectedTabIndex: React.Dispatch<React.SetStateAction<TabBarItemsIndex>>;
}
const MissingSecretsAction = (props: MissingSecretsActionProps) => {
  const navigateToEnvSecretsTab = () => {
    props.selectedTabIndex(TabBarItemsIndex.APP_ENV_SECRETS);
  };
  return (
    <div
      className="flex items-center self-stretch py-2 px-3 text-violet-500 hover:bg-violet-100
        rounded-lg border border-system-separator bg-violet-50 cursor-pointer"
    >
      <div className="flex-1 text-sm font-medium leading-5" onClick={navigateToEnvSecretsTab}>
        Add Environment Secrets
      </div>
      <ArrowRightIcon size={20} />
    </div>
  );
};

export default MissingSecretsAction;
