/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Run = {
    /**
     * Runs the app
     */
    command: Run.command;
};

export namespace Run {

    /**
     * Runs the app
     */
    export enum command {
        RUN = 'run',
    }


}

