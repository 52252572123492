import React from 'react';
import { AppInstanceState } from '../../../api/generated';
import { INSTANCE_STATE_DESCRIPTION } from '../../../i18n';
import classNames from 'classnames';

export interface InstanceStatusChangeMessageProps {
  state: AppInstanceState;
}

export const InstanceStatusChangeMessage = ({ state }: InstanceStatusChangeMessageProps) => {
  const description = INSTANCE_STATE_DESCRIPTION.get(state);
  if (!description) {
    return <></>;
  }

  const error = state === AppInstanceState.ENDED_WITH_ERROR;

  return (
    <div
      className={classNames('flex items-center gap-1 grow my-2', {
        'text-system-red-4': error,
      })}
    >
      <p className={classNames('text-sm flex-grow-0')}>{description}</p>
      <div
        className={classNames('flex-grow h-[1px]', {
          'bg-gray-300': !error,
          'bg-system-red-4': error,
        })}
      ></div>
    </div>
  );
};

export default InstanceStatusChangeMessage;
