import { LazyApp, LazyAppFolder } from '../../api/generated';

export const sortByName = (array: LazyAppFolder[]): LazyAppFolder[] =>
  [...array].sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));

export const getUuidFromStringIfPresent = (input: string) => {
  const uuidRegex = /(?:[a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12})/i;
  const matches = input.match(uuidRegex);

  return matches && matches.length > 0 ? matches[0] : null;
};

export const applySearchFilterToAppsList = (apps: LazyApp[], searchFilter: string) => {
  return apps.filter(
    (app) =>
      app.name?.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase()) ||
      app.id === getUuidFromStringIfPresent(searchFilter)
  );
};
