/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Organization } from './Organization';
import type { Role } from './Role';

export type OrganizationUserInvite = {
    id: string;
    user_email: string;
    role: Role;
    /**
     * Status of the invite
     */
    status?: OrganizationUserInvite.status;
    org: Organization;
};

export namespace OrganizationUserInvite {

    /**
     * Status of the invite
     */
    export enum status {
        SENT = 'Sent',
        RESENT = 'Resent',
        ACCEPTED = 'Accepted',
    }


}

