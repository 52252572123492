import {
  OrganizationService,
  Organization,
  OrganizationUpdate,
  OrganizationUserInvite,
  OrganizationUserInviteUpdate,
  OrganizationUserRoleAssignment,
  UserService,
  Role,
  RoleService,
} from './generated';
import { setupHeaders } from './index';

setupHeaders && setupHeaders();

export async function createOrganization(orgName: string): Promise<Organization> {
  return OrganizationService.organizationCreateOrganization({ name: orgName });
}

export async function updateOrganization(
  orgId: string,
  update: OrganizationUpdate
): Promise<Organization> {
  return OrganizationService.organizationUpdateOrganization(orgId, update);
}

export async function getOrganization(orgId: string): Promise<Organization> {
  return OrganizationService.organizationGetOrganization(orgId);
}

export async function sendInvite(
  orgId: string,
  userEmail: string,
  roleId: string
): Promise<OrganizationUserInvite> {
  return OrganizationService.organizationSendInvite(orgId, {
    user_email: userEmail,
    role_id: roleId,
  });
}

export async function deleteInvite(orgId: string, inviteId: string): Promise<void> {
  return OrganizationService.organizationDeleteInvite(orgId, inviteId);
}

export async function resendInvite(
  orgId: string,
  inviteId: string
): Promise<OrganizationUserInvite> {
  return OrganizationService.organizationUpdateInvite(orgId, inviteId, {
    status: OrganizationUserInviteUpdate.status.RESENT,
  });
}

export async function acceptInvite(
  orgId: string,
  inviteId: string
): Promise<OrganizationUserInvite> {
  return OrganizationService.organizationUpdateInvite(orgId, inviteId, {
    status: OrganizationUserInviteUpdate.status.ACCEPTED,
  });
}

export async function getAllRoles(): Promise<Role[]> {
  return RoleService.roleGetAllRoles();
}

export async function getUserRoleAssignments(): Promise<OrganizationUserRoleAssignment[]> {
  return UserService.userGetUserRoleAssignments();
}

export async function deleteUserRole(orgId: string, userRoleAssignmentId: string): Promise<void> {
  return OrganizationService.organizationDeleteUserRoleAssignment(orgId, userRoleAssignmentId);
}

export async function changeUserRole(
  orgId: string,
  userRoleAssignmentId: string,
  newRoleId: string
): Promise<OrganizationUserRoleAssignment> {
  return OrganizationService.organizationUpdateUserRoleAssignment(orgId, userRoleAssignmentId, {
    role_id: newRoleId,
  });
}
