import { useOrganizationStore } from '../store/organization';
import Banner, { BannerItemProps } from './Banner';
import React, { useEffect, useState } from 'react';
import teamIcon from 'remixicon-react/TeamFillIcon';
import discordIcon from '../icons/DiscordIcon';

const bannerItems: BannerItemProps[] = [
  {
    icon: teamIcon,
    text: 'Add teammates',
    style: 'bg-navy-blue',
    buttonText: 'Start team',
    buttonHandler: useOrganizationStore.getState().toggleCreateNewOrganizationModalState,
  },
  {
    icon: teamIcon,
    text: 'Build together!',
    style: 'bg-indigo-700',
    buttonText: 'Start team',
    buttonHandler: useOrganizationStore.getState().toggleCreateNewOrganizationModalState,
  },
  {
    icon: discordIcon,
    text: 'Join community!',
    style: 'bg-violet-700',
    buttonText: 'Join Discord',
    buttonHandler: () => window.open('https://discord.com/invite/WKNavbVvku', '_blank'),
  },
  {
    icon: discordIcon,
    text: 'Get inspiration',
    style: 'bg-fuchsia-700',
    buttonText: 'Join Discord',
    buttonHandler: () => window.open('https://discord.com/invite/WKNavbVvku', '_blank'),
  },
];

const ChatBannerPicker = () => {
  const [currentBanner, setCurrentBanner] = useState<number>(0);

  const getRandomBanner = () => {
    setCurrentBanner(Math.floor(Math.random() * bannerItems.length));
  };

  useEffect(() => {
    getRandomBanner();
  }, []);

  return (
    <Banner
      text={bannerItems[currentBanner].text}
      buttonText={bannerItems[currentBanner].buttonText}
      buttonHandler={bannerItems[currentBanner].buttonHandler}
      style={bannerItems[currentBanner].style}
      icon={bannerItems[currentBanner].icon}
    />
  );
};

export default ChatBannerPicker;
