/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Code = {
    programming_language: Code.programming_language;
    /**
     * Code that implements solution for builder's ideated app. Must be valid python code.
     */
    code: string;
};

export namespace Code {

    export enum programming_language {
        PYTHON = 'python',
    }


}

