import classNames from 'classnames';
import { isMobileDevice } from '../utils/deviceDimensions';
import React, { useEffect, useState } from 'react';
import Loader4FillIcon from 'remixicon-react/Loader4FillIcon';
import ErrorWarningLineIcon from 'remixicon-react/ErrorWarningLineIcon';
import CheckboxCircleLineIcon from 'remixicon-react/CheckboxCircleLineIcon';
import GlobalModalBackdrop from '../components/GlobalModalBackdrop';
import Button from './base/Button';

interface RevertAppModalProps {
  onHide: () => void;
  onRevert: () => Promise<void>;
}

const RevertAppModalInProgress = () => {
  return (
    <>
      <div className="mb-3">
        <Loader4FillIcon className="animate-spin text-lazy-label-blue" size={'3rem'} />
      </div>

      <div className="flex flex-col items-center justify-center">
        <div className="font-medium">Reverting</div>
      </div>
    </>
  );
};

const RevertAppModalError = (props: { error: string; onClick: () => void }) => {
  return (
    <>
      <div className="mb-3">
        <ErrorWarningLineIcon className="text-lazy-label-red" size={'3rem'} />
      </div>

      <div className="flex flex-col items-center justify-center">
        <div className="font-medium">{props.error}</div>
        <div className="flex items-center justify-center mt-2">
          <button onClick={props.onClick} className="text-lazy-action">
            Try again
          </button>
        </div>
      </div>
    </>
  );
};

const RevertAppModalSuccess = () => {
  return (
    <>
      <div className="mb-3">
        <CheckboxCircleLineIcon className="text-lazy-label-success" size={'3rem'} />
      </div>

      <div className="flex flex-col items-center justify-center max-w-full">
        <div className="font-medium">App reverted successfully</div>
        <br />
        <Button
          className="px-8 bg-green-500 text-white"
          onClick={() => {
            window.location.reload();
          }}
        >
          Ok
        </Button>
      </div>
    </>
  );
};

const RevertAppModalForm = ({ onClick, onHide }: { onClick: () => void; onHide: () => void }) => {
  return (
    <div className="flex flex-col text-center">
      <div className="flex flex-col gap-4 p-4">
        <span className="text-lg">Are you sure you want to revert to this version?</span>
        <span>
          You will lose all later messages and versions. Latest published version will stay the same
          until republished.
        </span>
      </div>
      <hr />
      <div className="flex flex-col gap-2 p-4">
        <Button onClick={onClick} className="px-8 bg-green-500 text-white">
          Yes, revert to this version
        </Button>
        <Button className="bg-background-secondary" onClick={onHide}>
          Don't revert
        </Button>
      </div>
    </div>
  );
};

// eslint-disable-next-line max-lines-per-function
const RevertAppModal = ({ onHide, onRevert }: RevertAppModalProps) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isReverted, setIsReverted] = useState(false);

  useEffect(() => {
    if (!isVisible) {
      onHide();
    }
  }, [isVisible]);

  const handleRevert = () => {
    setError('');
    setIsLoading(true);

    onRevert()
      .then(() => setIsReverted(true))
      .finally(() => setIsLoading(false))
      .catch(() => setError('There was an error reverting this app'));
  };

  const renderForm = () => {
    let innerComponent: React.ReactNode;
    if (isLoading) {
      innerComponent = <RevertAppModalInProgress />;
    } else if (error) {
      innerComponent = <RevertAppModalError error="error" onClick={handleRevert} />;
    } else if (isReverted) {
      innerComponent = <RevertAppModalSuccess />;
    } else {
      innerComponent = (
        <RevertAppModalForm onClick={handleRevert} onHide={() => setIsVisible(false)} />
      );
    }

    return (
      <div className="flex flex-col items-center justify-center max-w-full m-auto h-full">
        {innerComponent}
      </div>
    );
  };

  return (
    <>
      <GlobalModalBackdrop disableClickToCancelModal={true} />
      <div
        className={classNames(
          'rounded-lg bg-white shadow-around h-[265px]',
          'fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[100] max-w-[90vw]',
          {
            'w-[400px]': !isMobileDevice(),
            'w-[375px]': isMobileDevice(),
          }
        )}
      >
        {renderForm()}
      </div>
    </>
  );
};

export default RevertAppModal;
