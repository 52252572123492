import React, { useState, useEffect } from 'react';
import {
  AuthenticationRequirementToMessageContent,
  TextMessageContentFormat,
} from '../../../types';
import TextMessage from './TextMessage';
import { storeApiAuthenticationKey } from '../../../api/BuilderApi';
import Loader4LineIcon from 'remixicon-react/Loader4LineIcon';
import { useChatStore } from '../../../store/chat';

interface ApiKeyAuthenticationRequirementMessageProps {
  authenticationInfo: AuthenticationRequirementToMessageContent;
}

// eslint-disable-next-line max-lines-per-function
export const ApiKeyAuthenticationRequirementMessage = ({
  authenticationInfo,
}: ApiKeyAuthenticationRequirementMessageProps) => {
  const { provider, redactedApiKey } = authenticationInfo;
  const [isSaving, setIsSaving] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const instanceId = useChatStore.getState().instance?.id;

  const saveApiKey = async () => {
    setIsSaving(true);
    if (instanceId) {
      await storeApiAuthenticationKey(instanceId, { provider, api_key: apiKey });
    }
  };

  useEffect(() => setIsSaving(false), [redactedApiKey]);

  const handleApiKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApiKey(event.target.value);
  };

  // TODO: This message should be coming from backend via a translator
  const message = `Before you can continue, you need to provide your API key for ${provider}`;
  return (
    <div>
      <TextMessage text={message} format={TextMessageContentFormat.Plain} />
      <div className="border rounded-lg w-full max-w-[400px] m-3">
        <div className="p-3">
          <div className="flex justify-between">
            <p className="font-semibold text-sm pt-2">{provider}</p>
          </div>

          <p className="text-sm pt-2 text-black/[.54]">API Key: {redactedApiKey}</p>
        </div>

        {!redactedApiKey && (
          <>
            <div className="pl-3 pr-3">
              <input
                className="flex w-full flex-row border border-gray p-1 rounded-lg shadow"
                value={apiKey}
                onChange={handleApiKeyChange}
              />
            </div>
            <div className="flex justify-start mt-4">
              <div
                className={`flex items-center justify-center \
                  font-semibold text-sm rounded-bl-lg rounded-br-lg ${
                    isSaving ? 'bg-gray-400' : 'bg-lazy-action'
                  } text-white w-full py-2 mt-4 cursor-pointer`}
                onClick={!isSaving ? saveApiKey : undefined}
              >
                {isSaving ? <Loader4LineIcon className="animate-spin mr-3" /> : null}
                {isSaving ? 'Saving...' : 'Save'}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ApiKeyAuthenticationRequirementMessage;
