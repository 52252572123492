import { Message } from '../types';
import {
  ApiError,
  AppInstanceState,
  AppService,
  InstanceService,
  LazyAppVersion,
} from './generated';
import { setupHeaders } from './index';
import {
  SOURCE_LAZY_TTY_CAPABILITIES,
  catchUpWithPreviousMessages,
  catchUpWithPreviousMessagesSavedOnLogs,
  convertAndCallHook,
  processMessageForActions,
} from './ClientApiHelper';
import { TTYMessage, getAuthCookie, getTTYHistory } from './TtyHelper';
import { waitUntilFlagIsTrue } from './utils';
import { FrontendEvents, sendEventToBackend } from './StatsApi';

setupHeaders && setupHeaders();

export type TTYCapabilities = {
  supports_app_hot_reload: boolean;
  supports_min_timestamp_param: boolean;
};

export type SubscribeToAppInstanceTtyParams = {
  instanceId: string;
  messageHook: (msg: Message[], statusMessage: string | null) => void;
  messageHookForMessagesFromHistory: (msg: Message[], statusMessage: string | null) => void;
  idsOfPreviouslyProcessedMessages: React.MutableRefObject<Set<string>>;
  lastMessageFromHistoryFile: React.MutableRefObject<string | null>;
  connectHook: (ws: WebSocket | null) => void;
  shouldAttemptToConnect: () => boolean;
  setOpenAppUrlFromMessages: (url: string | null) => void;
  setSqliteWebUrlFromMessages: (url: string | null) => void;
  messagesBeforeTimestamp?: Date;
  onReachingMaxConnectionAttempts?: () => void;
  updateAppState?: (newAppState: AppInstanceState, timestamp: Date | null) => void;
  lastMessageTimestamp: React.MutableRefObject<Date>;
  onDisconnected: () => void;
  ttyCapabilities: React.MutableRefObject<TTYCapabilities>;
};

export const WEBSOCKET_RECONNECT_TIMEOUT_MS = 1000;
export const MAX_ATTEMPTS_TO_RECONNECT_WITH_WEBSOCKET = 20;
export const CONNECTION_ATTEMPTS_COUNTER = {};
export const NUM_MAX_MESSAGES_TO_FETCH_AT_ONCE = 1000;

export function getAppInstance(instanceId: string) {
  return InstanceService.instanceGetInstance(instanceId);
}

export function resetSocketConnectionStateForInstance(instanceId: string) {
  CONNECTION_ATTEMPTS_COUNTER[instanceId] = 0;
}

// eslint-disable-next-line max-lines-per-function, max-statements
export async function subscribeToAppInstanceTty(params: SubscribeToAppInstanceTtyParams) {
  const rescheduleForRetry = () => {
    setTimeout(
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      () => subscribeToAppInstanceTty(params),
      WEBSOCKET_RECONNECT_TIMEOUT_MS
    );
  };

  if (!params.shouldAttemptToConnect()) {
    rescheduleForRetry();
    return;
  }

  const protocol = window.location.protocol.replace('http', 'ws');

  // Request a cookie with the token that will be used to authenticate the websocket connection.
  try {
    await getAuthCookie();
  } catch {
    rescheduleForRetry();
    return;
  }

  // A simple ID just to correlate logged events for this TTY.
  const ttyId = Math.floor(Math.random() * 1000000);
  sendEventToBackend({
    eventType: FrontendEvents.TTY_WEBSOCKET_ATTEMPT,
    instanceId: params.instanceId,
    properties: {
      eventTime: Date.now(),
      ttyId,
    },
  });

  const wsAddress = `${protocol}//${window.location.host}/tty/${params.instanceId}/api/v1/tty`;
  const socket = new WebSocket(wsAddress);
  const readyToConsumeFromWebsocket = [false];
  const reportedTtyMessage = [false];

  const processTTYCapabilities = (ttyMessage: TTYMessage) => {
    params.ttyCapabilities.current = JSON.parse(ttyMessage.message) as TTYCapabilities;
  };

  const fetchNewTTYMessages = async (
    instanceId: string,
    minTimestamp: Date
  ): Promise<TTYMessage[]> => {
    const messages: TTYMessage[] = (
      await getTTYHistory(instanceId, NUM_MAX_MESSAGES_TO_FETCH_AT_ONCE, undefined, minTimestamp)
    ).messages;
    return messages;
  };

  // eslint-disable-next-line max-lines-per-function
  const processIncomingMessage = (ttyMessages: TTYMessage[]) => {
    ttyMessages.forEach((ttyMessage) => {
      if (ttyMessage.source === SOURCE_LAZY_TTY_CAPABILITIES) {
        processTTYCapabilities(ttyMessage);
        // Setting the lastMessageTimestamp to the lazy capabilities means the tty
        // will initially only show messages since the client connected. The user
        // can press load more messages to load the past if needed.
        params.lastMessageTimestamp.current = new Date(ttyMessage.timestamp);
        return;
      }
      if (
        params.lastMessageTimestamp.current <= new Date(ttyMessage.timestamp) &&
        !params.idsOfPreviouslyProcessedMessages.current.has(ttyMessage.id)
      ) {
        params.lastMessageTimestamp.current = new Date(ttyMessage.timestamp);
        params.idsOfPreviouslyProcessedMessages.current.add(ttyMessage.id);
        processMessageForActions(
          ttyMessage,
          params.setOpenAppUrlFromMessages,
          params.setSqliteWebUrlFromMessages,
          params.updateAppState
        );
        // eslint-disable-next-line no-void
        void convertAndCallHook(params.messageHook, [ttyMessage]);
      }

      if (!reportedTtyMessage[0]) {
        reportedTtyMessage[0] = true;
        sendEventToBackend({
          eventType: FrontendEvents.TTY_WEBSOCKET_FIRST_MESSAGE,
          instanceId: params.instanceId,
          properties: {
            eventTime: Date.now(),
            messageId: ttyMessage.id,
            messageTimestamp: ttyMessage.timestamp,
            ttyId,
          },
        });
      }
    });
  };

  // eslint-disable-next-line max-lines-per-function
  socket.addEventListener('message', (msg: { data: string }) => {
    (async () => {
      await waitUntilFlagIsTrue(readyToConsumeFromWebsocket);

      let ttyMessages: TTYMessage[] = [];
      if (params.ttyCapabilities.current.supports_min_timestamp_param) {
        // We treat the message received on the socket as a ping indication to read the
        // new messages. The reason we don't prefer to send the messages directly on tty is that
        // doing so poses buffering and memory problems in the client specially when there might be
        // multiple tabs listening. With this approach we can have a bigger pool for all tabs and
        // scale better with less risk of a client missing messages.

        // The timestamp from js is not as precise as the one from python so it could round and miss
        // the first message so go back one second before in time.
        const afterTimestampToFetch = params.lastMessageTimestamp.current;
        afterTimestampToFetch.setSeconds(afterTimestampToFetch.getSeconds() - 1);
        const newMessages = await fetchNewTTYMessages(params.instanceId, afterTimestampToFetch);
        ttyMessages = ttyMessages.concat(newMessages);
        processIncomingMessage(ttyMessages);
      } else {
        if (msg.data.length > 0) {
          // Backwards compatible with containers that send messages directly on websocket.
          processIncomingMessage([JSON.parse(msg.data) as unknown as TTYMessage]);
        }
      }
    })();
  });
  socket.addEventListener('open', () => {
    (async () => {
      sendEventToBackend({
        eventType: FrontendEvents.TTY_WEBSOCKET_OPENED,
        instanceId: params.instanceId,
        properties: {
          eventTime: Date.now(),
          ttyId,
        },
      });
      // Now that it is connected to the websocket we need to catch-up with previous messages.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      await catchUpWithPreviousMessages(
        params.instanceId,
        params.messageHookForMessagesFromHistory,
        params.idsOfPreviouslyProcessedMessages,
        params.setOpenAppUrlFromMessages,
        params.setSqliteWebUrlFromMessages,
        true
      );
      params.connectHook(socket);
      readyToConsumeFromWebsocket[0] = true;
      CONNECTION_ATTEMPTS_COUNTER[params.instanceId] = 0;
    })();
  });
  // eslint-disable-next-line max-lines-per-function
  socket.addEventListener('close', (_event) => {
    params.onDisconnected();
    sendEventToBackend({
      eventType: FrontendEvents.TTY_WEBSOCKET_CLOSED,
      instanceId: params.instanceId,
      properties: {
        eventTime: Date.now(),
        ttyId,
      },
    });
    params.connectHook(null);
    if (!params.shouldAttemptToConnect()) {
      rescheduleForRetry();
      return;
    }
    // eslint-disable-next-line compat/compat
    new Promise((resolve, reject) => {
      // skip loading messages when code is CLOSE_ABNORMAL as attempts are being made to connect
      if (_event.code !== 1006) {
        catchUpWithPreviousMessagesSavedOnLogs(
          params.instanceId,
          params.messageHookForMessagesFromHistory,
          params.idsOfPreviouslyProcessedMessages,
          params.lastMessageFromHistoryFile
        )
          .then(() => resolve(null))
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.info('Unable to fetch logs upon shut down.');
            // eslint-disable-next-line no-console
            console.info(err);
            resolve(null);
          });
      } else {
        CONNECTION_ATTEMPTS_COUNTER[params.instanceId] =
          (CONNECTION_ATTEMPTS_COUNTER[params.instanceId] as number) || 0;
        CONNECTION_ATTEMPTS_COUNTER[params.instanceId] += 1;
        // eslint-disable-next-line no-console
        console.info(
          `Making attempt number "${
            CONNECTION_ATTEMPTS_COUNTER[params.instanceId] as number
          }" to connect with server: ${params.instanceId}`
        );
        if (
          CONNECTION_ATTEMPTS_COUNTER[params.instanceId] < MAX_ATTEMPTS_TO_RECONNECT_WITH_WEBSOCKET
        ) {
          resolve(null);
        } else {
          reject(new Error('Too many failed attempts to connect to socket'));
        }
      }
    })
      .then(() => {
        rescheduleForRetry();
        return null;
      })
      .catch((_) => {
        sendEventToBackend({
          eventType: FrontendEvents.TTY_WEBSOCKET_ATTEMPTS_FAILED,
          instanceId: params.instanceId,
          properties: {
            eventTime: Date.now(),
            ttyId,
          },
        });

        if (params.shouldAttemptToConnect()) {
          params.onReachingMaxConnectionAttempts?.();
        }

        CONNECTION_ATTEMPTS_COUNTER[params.instanceId] = 0;
      });
  });
  socket.addEventListener('error', (_event) => {
    if (!params.shouldAttemptToConnect()) {
      rescheduleForRetry();
      return;
    }
    socket.close();
  });
}

export async function getCurrentAppVersion(
  appId: string,
  testing: boolean
): Promise<LazyAppVersion | null> {
  try {
    return await AppService.appGetCurrentAppVersion(appId, testing);
  } catch (error) {
    // The entrypoint returns 404 if there's no current published version for the app.
    // Return null in this case instead of throwing an error.
    const apiError = error as ApiError;
    if (apiError?.status === 404) {
      return null;
    }
    throw error;
  }
}

export function stopInstance(instanceId: string) {
  return InstanceService.instanceStopInstance(instanceId);
}

export function startInstance(
  instanceId: string,
  builderSessionStateId?: string,
  ramMb?: number,
  autoUpdateTestApp?: boolean
) {
  return InstanceService.instanceStartInstance(
    instanceId,
    builderSessionStateId,
    ramMb,
    autoUpdateTestApp
  );
}

export function createOrGetInstanceForApp(appId: string, testing: boolean) {
  return InstanceService.instanceGetOrCreateAppInstance(appId, testing);
}

export function getInstanceTTLInfo(appInstanceId: string) {
  return InstanceService.instanceGetInstanceTtlInfo(appInstanceId);
}
