import React, { useState, useEffect } from 'react';
import {
  AuthenticationRequirementToMessageContent,
  TextMessageContentFormat,
} from '../../../types';
import TextMessage from './TextMessage';
import { storeCustomAuthentication } from '../../../api/BuilderApi';
import Loader4LineIcon from 'remixicon-react/Loader4LineIcon';
import { useChatStore } from '../../../store/chat';
import useAuthenticationCheck from './AuthenticationCheckHook';

interface CustomAuthenticationRequirementMessageProps {
  authenticationInfo: AuthenticationRequirementToMessageContent;
}

// eslint-disable-next-line max-lines-per-function
export const CustomAuthenticationRequirementMessage = ({
  authenticationInfo,
}: CustomAuthenticationRequirementMessageProps) => {
  const { provider, fields, oauthSignedInUser } = authenticationInfo;
  const [isSaving, setIsSaving] = useState(false);
  const [fieldValues, setFieldValues] = useState<{ [key: string]: string }>({});
  const instanceId = useChatStore.getState().instance?.id;

  const saveApiKeys = async () => {
    setIsSaving(true);
    if (instanceId) {
      await storeCustomAuthentication(instanceId, {
        provider,
        fields: JSON.stringify(fieldValues),
      });
    }
  };

  useEffect(() => setIsSaving(false), [oauthSignedInUser]);

  const handleFieldChange = (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValues((prevFieldValues) => ({
      ...prevFieldValues,
      [fieldName]: event.target.value,
    }));
  };

  // TODO: This message should be coming from backend via a translator
  // eslint-disable-next-line max-len
  const message = `Before you can continue, you need to provide following fields for your ${provider}.`;

  // Check if all required fields have non-empty values
  const isSaveEnabled =
    fieldValues && fields.every((field) => fieldValues[field] && fieldValues[field]?.trim() !== '');

  const isAuthenticated = instanceId
    ? useAuthenticationCheck(instanceId, authenticationInfo.provider)
    : false;

  return (
    <div>
      <TextMessage text={message} format={TextMessageContentFormat.Plain} />
      <div className="border rounded-lg w-full max-w-[400px] m-3">
        <div className="p-3">
          <div className="flex justify-between">
            <p className="font-semibold text-sm pt-2">{provider}</p>
          </div>
        </div>

        {isAuthenticated ? (
          <p className="text-xs pl-4 pb-3 text-black/[.54]">Access Received</p>
        ) : (
          <>
            {fields.map((field) => (
              <div className="pl-3 pr-3 mb-3" key={field}>
                <label className="block text-sm font-medium text-gray-700 mb-1">{field}:</label>
                <input
                  className="flex w-full flex-row border border-gray p-1 rounded-lg shadow"
                  value={fieldValues[field] || ''}
                  onChange={handleFieldChange(field)}
                  placeholder={`Enter ${field}...`}
                />
              </div>
            ))}
            <div className="flex justify-start mt-4">
              <div
                className={`flex items-center justify-center \
            font-semibold text-sm rounded-bl-lg rounded-br-lg ${
              isSaving || !isSaveEnabled ? 'bg-gray-400' : 'bg-[#2383E2]'
            } text-white w-full py-2 mt-4 cursor-pointer`}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={!isSaving && isSaveEnabled ? saveApiKeys : undefined}
              >
                {isSaving ? <Loader4LineIcon className="animate-spin mr-3" /> : null}
                {isSaving ? 'Saving...' : 'Save'}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CustomAuthenticationRequirementMessage;
