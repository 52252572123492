import React, { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router';
import AppEditor from '../components/AppEditor';
import { createApp } from '../api/BuilderApi';
import { useChatStore } from '../store/chat';
import { useOrganizationStore } from '../store/organization';
import { CURRENT_ACTIVE_APP_ID } from '../constants';
import { useSearchParams } from 'react-router-dom';

const BuilderPage = () => {
  const pathParams = useParams();
  const [searchParams, _setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { activeOrganization } = useOrganizationStore();
  const { setError } = useChatStore();

  useEffect(() => {
    const currentActiveAppId = localStorage.getItem(CURRENT_ACTIVE_APP_ID);
    if (pathParams.appId === undefined) {
      if (currentActiveAppId && !searchParams.get('prompt') && !searchParams.get('new_app')) {
        navigate(`/apps/${currentActiveAppId}/build`);
      } else {
        createApp({
          name: '',
          description: '',
          org_id: activeOrganization?.id,
        })
          .then((app) => navigate(`/apps/${app.id}/build`))
          .catch(() => setError('Failed creating app, please refresh and try again'));
      }
    }
  }, []);

  return <AppEditor appId={pathParams.appId as string} />;
};

export default BuilderPage;
