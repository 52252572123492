import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import WindowLineIcon from 'remixicon-react/WindowLineIcon';
import ArrowLeftLineIcon from 'remixicon-react/ArrowLeftLineIcon';
import ArrowRightLineIcon from 'remixicon-react/ArrowRightLineIcon';
import LinkIcon from 'remixicon-react/LinkIcon';
import Button from './Button';
import * as dataTestidConstants from '../../constants';
import { FrontendEvents } from '../../api/StatsApi';
import { copyToClipboard } from '../../utils/clipboard';
import LoopLeftIcon from '../../assets/loop-left-gray.svg';
import { Loader } from './Loader';
import { toast } from 'react-toastify';
import LaptopIcon from 'remixicon-react/MacbookLineIcon';
import PhoneIcon from 'remixicon-react/SmartphoneLineIcon';

const DISABLE_NAVIGATION_BUTTONS = true;

export interface BrowserFrameProps {
  url: string;
  displayIframe: boolean;
  emitEvent: (eventType: FrontendEvents) => void;
}

// eslint-disable-next-line max-lines-per-function
export const BrowserFrame = ({ url, displayIframe, emitEvent }: BrowserFrameProps) => {
  const [isResponsiveIFrame, setIsResponsiveIFrame] = useState<boolean>(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const handleReload = () => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      iframe.src = 'about:blank'; // Set src to a blank page
      iframe.src = url; // Set src back to the original URL
    }
  };

  const handleNext = () => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      iframe.contentWindow?.history.forward(); // Navigate forward in iframe history
    }
  };

  const handleBack = () => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      iframe.contentWindow?.history.back(); // Navigate back in iframe history
    }
  };

  const renderIframePlaceholder = () => <Loader fullScreen={false} />;

  const renderIframe = () => (
    <iframe
      ref={iframeRef}
      name="lazy-app-run-iframe"
      src={url}
      className={classNames('rounded-b-lg h-full', {
        'w-[390px] max-h-[844px] ml-auto mr-auto': isResponsiveIFrame,
        'w-full': !isResponsiveIFrame,
      })}
      data-hj-allow-iframe
    ></iframe>
  );
  return (
    <div className={'flex flex-col h-full w-full'}>
      <div
        className={classNames(
          'bg-white flex items-center p-2 gap-1',
          'box-border rounded-t-lg border-b border-gray-300'
        )}
      >
        {!DISABLE_NAVIGATION_BUTTONS && (
          <div className="flex gap-1 px-1">
            <div className="rounded-full border border-system-separator p-1" onClick={handleBack}>
              <ArrowLeftLineIcon size={18} color="label-primary" />
            </div>
            <div className="rounded-full border border-system-separator p-1" onClick={handleNext}>
              <ArrowRightLineIcon size={18} color="label-primary" />
            </div>
          </div>
        )}
        <div
          className={classNames(
            'bg-white h-7 rounded-full flex items-center text-sm text-gray-600 border-gray-300',
            'flex-grow px-4 whitespace-nowrap overflow-hidden overflow-ellipsis',
            'border border-solid'
          )}
          aria-hidden
        >
          <div className="flex flex-1 items-center overflow-hidden">
            <span className="overflow-hidden whitespace-nowrap overflow-ellipsis">{url}</span>
          </div>
          <div className="cursor-pointer" onClick={handleReload}>
            <img
              src={LoopLeftIcon as string}
              alt="Loop Left Icon"
              className="h-4 w-4 pointer-events-none text-label-secondary"
            />
          </div>
        </div>
        <div className="flex gap-3 min-w-[235px]">
          <Button
            className={classNames('bg-white text-system-accent')}
            onClick={() => {
              copyToClipboard(url);
              toast.success('Link copied successfully.');
            }}
            iconProps={{ icon: LinkIcon, iconSize: 20 }}
          >
            Copy link
          </Button>
          <Button
            className={classNames('bg-white text-system-accent')}
            onClick={() => {
              emitEvent(FrontendEvents.USER_CLICKED_OPEN_APP_BUTTON);
              window.open(url, '_blank');
            }}
            iconProps={{ icon: WindowLineIcon, iconSize: 20 }}
            dataTestid={dataTestidConstants.TEST_APP_OPEN_APP_BUTTON as string}
          >
            Open in tab
          </Button>
        </div>
        <div className="flex flex-row h-[34px]">
          <button
            className={classNames('py-1 pl-3 pr-4 text-sm font-medium', 'rounded-l-lg', {
              'bg-system-accent text-white': !isResponsiveIFrame,
              'bg-neutral-200 text-gray-700': isResponsiveIFrame,
            })}
            onClick={() => {
              setIsResponsiveIFrame(false);
            }}
          >
            <LaptopIcon size={16} />
          </button>
          <button
            className={classNames(
              'py-1 pl-3 pr-4 text-sm font-medium',
              'rounded-r-lg', // Right button rounded only on the right
              {
                'bg-system-accent text-white': isResponsiveIFrame,
                'bg-neutral-200 text-gray-700': !isResponsiveIFrame,
              }
            )}
            onClick={() => {
              setIsResponsiveIFrame(true);
            }}
          >
            <PhoneIcon size={16} />
          </button>
        </div>
      </div>
      <div className={classNames('flex flex-1 h-full w-full bg-white rounded-b-lg relative')}>
        {displayIframe ? renderIframe() : renderIframePlaceholder()}
      </div>
    </div>
  );
};
