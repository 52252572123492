import { relativeTimeFromDates } from '../utils/time';
import React from 'react';
import ActionButton from './base/ActionButton';
import RocketIcon from 'remixicon-react/Rocket2LineIcon';

interface AppPublishingStatusDisplayProps {
  publishDate: Date | null;
  publishUrl: string | null;
  isTemplate?: boolean;
}

// eslint-disable-next-line max-lines-per-function
const AppPublishingStatusDisplay = ({
  publishDate,
  publishUrl,
  isTemplate,
}: AppPublishingStatusDisplayProps) => {
  const typeLabel = isTemplate ? 'Community' : 'Production';
  if (!publishDate || !publishUrl) {
    return (
      <span className="font-normal text-sm text-label-secondary text-400 px-6 py-3">
        {isTemplate ? 'No version published yet' : 'No production version published'}
      </span>
    );
  }
  return (
    <div className="flex flex-col font-normal text-sm overflow-hidden px-6 py-3 gap-2">
      <div className="flex shrink-0 text-subtitle-custom-gray text-400 mr-3">
        {typeLabel} version updated {relativeTimeFromDates(publishDate)}
      </div>
      <ActionButton
        buttonType="primary"
        onClick={() => window.open(publishUrl, '_blank')}
        fillState="light"
      >
        <RocketIcon size={20} />
        Open production version
      </ActionButton>
    </div>
  );
};

export default AppPublishingStatusDisplay;
