import { copyToClipboard } from '../../utils/clipboard';
import React, { useState } from 'react';
import FileCopyLineIcon from 'remixicon-react/FileCopyLineIcon';
import classNames from 'classnames';

const DELAY_RESET_TO_DEFAULT_STATE_MS = 4000;

const CopyToClipboardButton = ({ url }: { url: string }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    copyToClipboard(url);
    setCopied(true);
    setTimeout(() => setCopied(false), DELAY_RESET_TO_DEFAULT_STATE_MS);
  };

  return (
    <div
      className="cursor-pointer text-label-primary hover:text-black relative inline-block"
      onClick={() => handleCopyClick()}
    >
      <FileCopyLineIcon className="inline" size={20} />
      <div
        className={classNames(
          'absolute top-[100%] left-[50%] px-4',
          'text-label-primary bg-label-secondary rounded-lg',
          { hidden: !copied }
        )}
        style={{ transform: 'translateX(-50%)' }}
      >
        Copied!
      </div>
    </div>
  );
};

export default CopyToClipboardButton;
