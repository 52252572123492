import React, { ChangeEvent, FC, ReactNode, memo } from 'react';
import classNames from 'classnames';

interface InputProps {
  disabled?: boolean;
  value?: string;
  label: string | ReactNode;
  type?: string;
  placeholder?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e) => void;
  onBlur?: (e) => void;
  visible?: boolean;
  isEditMode: boolean;
  containerClassName?: string;
  maxLength?: number;
  dataTestid?: string;
}

export const Input: FC<InputProps> = memo(
  // eslint-disable-next-line max-lines-per-function
  ({
    value = '',
    onChange,
    onFocus,
    onBlur,
    label,
    type,
    placeholder,
    isEditMode,
    disabled,
    containerClassName,
    maxLength,
    dataTestid,
  }) => {
    return (
      <div
        className={classNames(
          'grid lg:gap-3 lg:grid-cols-[100px_1fr] items-center',
          containerClassName,
          {
            hidden: !value && !isEditMode,
          }
        )}
      >
        <span className={classNames('text-xs text-label-secondary font-medium')}>{label}</span>
        <input
          maxLength={maxLength}
          className={classNames('p-2 border ', {
            'border-none bg-transparent': !isEditMode,
            // eslint-disable-next-line max-len
            'border-system-separator rounded-md placeholder:text-label-tertiary disabled:text-label-tertiary':
              isEditMode,
          })}
          type={type}
          placeholder={placeholder}
          disabled={disabled || !isEditMode}
          value={value || ''}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          data-testid={dataTestid}
        />
      </div>
    );
  }
);
