import { USER_LOGGED_IN_KEY_LOCAL_STORAGE } from '../store/auth';
import { UserService } from '../api/generated/index';
import { handleRedirectQueryParam, handleRedirectToPricingPage } from './redirectHandler';

export const attemptSetWebFlowCookie = (
  retryAttempt = 0,
  maxRetries = 1,
  handleRedirectUrl = true
) => {
  if (window.location.href.includes('/logout')) {
    return;
  }

  return UserService.userSetWebflowAuthCookie()
    .then(() => {
      const queryString = window.location.search;
      // eslint-disable-next-line compat/compat
      const urlParams = new URLSearchParams(queryString);
      const redirectUrlQueryParam = urlParams.get('redirect');
      return redirectUrlQueryParam && handleRedirectUrl
        ? handleRedirectQueryParam(redirectUrlQueryParam)
        : handleRedirectToPricingPage();
    })
    .catch(async (_e) => {
      if (retryAttempt < maxRetries && localStorage.getItem(USER_LOGGED_IN_KEY_LOCAL_STORAGE)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return attemptSetWebFlowCookie(retryAttempt + 1, maxRetries, handleRedirectUrl);
      } else if (!handleRedirectQueryParam) {
        throw new Error('Failed to set WebFlow cookie.');
      } else {
        await handleRedirectToPricingPage();
      }
    });
};
