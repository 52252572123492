import React from 'react';
import { parse } from 'tldts';
interface DomainConfigTableProps {
  showVerificationFailedErrorMessage: boolean;
  domainName: string;
}

// eslint-disable-next-line max-lines-per-function
export const DomainConfigTable = ({
  showVerificationFailedErrorMessage,
  domainName,
}: DomainConfigTableProps) => {
  const { subdomain } = parse(domainName);
  return (
    <div className="flex flex-col items-start w-full pl-10 pt-4">
      {showVerificationFailedErrorMessage ? (
        <div className="self-stretch text-xs mb-3 text-system-danger">
          There was some kind of connection error.
          <br /> Recheck your settings in the hosting control panel.
        </div>
      ) : (
        <div className="self-stretch text-xs mb-3">
          Your DNS records must be set up with the following values
        </div>
      )}
      <div className="w-full border border-gray-300 rounded overflow-hidden">
        <table className="w-full">
          <thead className="bg-gray-100">
            <tr className="text-sm font-medium border-gray-300 text-left">
              <th className="p-2 w-24 border-r">Name</th>
              <th className="p-2 w-24 border-r">Type</th>
              <th className="p-2">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-sm">
              <td className="p-2 w-24 border-r">{subdomain || 'www'}</td>
              <td className="p-2 w-24 border-r">CNAME</td>
              <td className="p-2">{process.env.REACT_APP_CUSTOM_DOMAIN_CNAME}</td>
            </tr>

            {(!subdomain || subdomain === 'www') && (
              <tr className="text-sm">
                <td className="p-2 w-24 border-r border-t">@</td>
                <td className="p-2 w-24 border-r border-t">A</td>
                <td className="p-2 border-t">{process.env.REACT_APP_CUSTOM_DOMAIN_IP}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
