import React, { useState } from 'react';
import { useOrganizationStore } from '../store/organization';
import AddFillIcon from 'remixicon-react/AddFillIcon';
import DeleteBinLineIcon from 'remixicon-react/DeleteBinLineIcon';
import ActionButton from './base/ActionButton';
import { Role } from '../api/generated';
import emailValidator from 'email-validator';
import {
  TEST_ID_TEAM_ADD_ANOTHER_USER_INVITE_BUTTON,
  TEST_ID_TEAM_REMOVE_INVITE_INPUT_BUTTON,
  TEST_ID_TEAM_SEND_INVITES_BUTTON,
  TEST_ID_TEAM_USER_INVITE_INPUT,
} from '../constants';

interface OrganizationUserInvitesSenderProps {
  onInvitesSent?: () => void;
}

// eslint-disable-next-line max-lines-per-function
export default ({ onInvitesSent }: OrganizationUserInvitesSenderProps) => {
  const [emails, setEmails] = useState<string[]>(['']);

  const {
    organizationBeingConfigured,
    loadOrganization,
    builderUserRole,
    inviteUserToOrganization,
  } = useOrganizationStore();

  const handleAddEmailField = () => {
    setEmails([...emails, '']);
  };

  const removeEmailField = (index: number) => {
    const emailsCopy = [...emails];
    emailsCopy.splice(index, 1);
    setEmails(emailsCopy);
  };

  const handleEmailChange = (index: number, value: string) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);
  };

  const onSendInvites = async () => {
    // eslint-disable-next-line compat/compat
    await Promise.all(
      emails
        .filter((email) => email.trim().length !== 0)
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        .map((email) => inviteUserToOrganization(email, (builderUserRole as Role).id))
    );
    if (onInvitesSent) {
      onInvitesSent();
    } else {
      await loadOrganization(organizationBeingConfigured?.id);
      setEmails(['']);
    }
  };

  const checkIfThereIsAnInvalidEmail = () => {
    return (
      emails.filter(
        (email) =>
          (email.trim().length !== 0 && !emailValidator.validate(email.trim())) ||
          email.trim().length === 0
      ).length > 0
    );
  };

  return (
    <div>
      {emails.map((email, index) => (
        <div key={index} className="flex mb-2 justify-center items-start">
          <div className="flex flex-1 flex-col">
            <input
              type="email"
              value={email}
              onChange={(e) => handleEmailChange(index, e.target.value)}
              placeholder="Email"
              className="py-1.5 px-3 border border-system-separator rounded-md w-full"
              data-testid={TEST_ID_TEAM_USER_INVITE_INPUT}
            />
            {email && !emailValidator.validate(email) && (
              <span className="text-xs text-red-500">enter valid email</span>
            )}
          </div>
          {emails.length > 1 && (
            <button
              className="p-1"
              onClick={() => removeEmailField(index)}
              data-testid={TEST_ID_TEAM_REMOVE_INVITE_INPUT_BUTTON}
            >
              <DeleteBinLineIcon className="h-5 text-system-danger" />
            </button>
          )}
        </div>
      ))}
      <div className="flex flex-start mb-2">
        <ActionButton
          buttonType="quaternary"
          fillState="subtle"
          size="small"
          onClick={handleAddEmailField}
          data-testid={TEST_ID_TEAM_ADD_ANOTHER_USER_INVITE_BUTTON}
        >
          <AddFillIcon size={14} className={'inline mr-2 align-text-bottom'} />
          Add more
        </ActionButton>
      </div>

      {onInvitesSent ? (
        <div className="flex m-4 justify-center">
          <ActionButton
            buttonType="tertiary"
            onClick={() => {
              // eslint-disable-next-line no-void
              void onSendInvites();
            }}
            disabled={checkIfThereIsAnInvalidEmail()}
            data-testid={TEST_ID_TEAM_SEND_INVITES_BUTTON}
          >
            Invite & Finish
          </ActionButton>
        </div>
      ) : (
        <ActionButton
          className="w-full"
          buttonType="tertiary"
          onClick={() =>
            // eslint-disable-next-line no-void
            void onSendInvites()
          }
          disabled={checkIfThereIsAnInvalidEmail()}
          data-testid={TEST_ID_TEAM_SEND_INVITES_BUTTON}
        >
          Invite
        </ActionButton>
      )}
    </div>
  );
};
