/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JwtToken } from '../models/JwtToken';
import type { OrganizationUserRoleAssignment } from '../models/OrganizationUserRoleAssignment';
import type { UserLoginRequest } from '../models/UserLoginRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Checks if a user identified by the email is allowed to be a builder in the beta test
     * During the beta test only allowed users should be able to access the system as a builder. This entry
     * point returns 200 if the user is allowed to be a builder on beta test or 404 it is not.
     * @param email
     * @returns any Successful Response
     * @throws ApiError
     */
    public static userCheckBetaAllowedList(
        email: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/beta_allowed_list',
            query: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Gets user information with permissions
     * Returns the contents of the user's JwtToken, with the added permissions field.
     * @returns JwtToken Successful Response
     * @throws ApiError
     */
    public static userShowUserInfo(): CancelablePromise<JwtToken> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/whoami',
        });
    }

    /**
     * Gets list of support users id
     * Returns the list of support user ids. Support users are users with datamilk email.
     * @returns string Successful Response
     * @throws ApiError
     */
    public static userGetSupportUsersId(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/support_users',
        });
    }

    /**
     * Gets the encoded lazy context
     * Returns the encoded lazy context.
     * @returns string Successful Response
     * @throws ApiError
     */
    public static userGetEncodedLazyContext(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/context',
        });
    }

    /**
     * Returns a response with authentication cookie set
     * Returns a server side cookie for authentication.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static userSetAuthCookie(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/cookie',
        });
    }

    /**
     * Returns a response with user's email cookie set
     * Returns a server side cookie for user's email.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static userSetWebflowAuthCookie(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/set_webflow_cookie',
        });
    }

    /**
     * Deletes webflow cookie on response
     * Deletes cookie that was ser for webflow and this request is to be called on logout
     * @returns any Successful Response
     * @throws ApiError
     */
    public static userClearWebflowCookie(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/clear_cookie',
        });
    }

    /**
     * Gets user's detail for webflow
     * Decrypts the token in the request for the webflow to acquire user details from the token
     * @returns any Successful Response
     * @throws ApiError
     */
    public static userGetWebflowUserDetails(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/get_webflow_user_details',
        });
    }

    /**
     * Informs to lazy backend that a user has just logged in in the UI
     * The UI is informing that a user has just logged in. If this is the first time
     * for this user we consider she has just registered
     * @param requestBody
     * @returns JwtToken Successful Response
     * @throws ApiError
     */
    public static userLogin(
        requestBody: UserLoginRequest,
    ): CancelablePromise<JwtToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get all role assignments for the current user
     * Returns list of all the organization user role assignments for the current user
     * @returns OrganizationUserRoleAssignment Successful Response
     * @throws ApiError
     */
    public static userGetUserRoleAssignments(): CancelablePromise<Array<OrganizationUserRoleAssignment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/role_assignments',
        });
    }

}
