import React, { MouseEventHandler } from 'react';
import { LazyReactIconComponentType } from '../../icons/typings';
import { RemixiconReactIconComponentType } from 'remixicon-react';
import classNames from 'classnames';

export interface MenuLabelIcon {
  icon: LazyReactIconComponentType | RemixiconReactIconComponentType;
  iconSize?: number;
}

export interface MenuLabelItemProps {
  className: string;
  label?: string;
  labelElement?: React.ReactElement;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
  iconProps?: MenuLabelIcon;
}

const MenuLabelItem = ({
  className,
  label,
  labelElement,
  iconProps: IconProps,
}: MenuLabelItemProps) => {
  return (
    <div
      className={classNames('flex flex-row gap-1 px-1.5 items-center cursor-pointer', className)}
    >
      <div className="flex-col grow items-center">
        {IconProps?.icon && <IconProps.icon size={IconProps.iconSize} />}
      </div>
      <label className="cursor-pointer text-left">{labelElement || label}</label>
    </div>
  );
};

export default MenuLabelItem;
