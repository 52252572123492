import React from 'react';
import { ViewVersionFunction, useChatStore } from '../../store/chat';
import classNames from 'classnames';
import { isMobileDevice } from '../../utils/deviceDimensions';
import CodeLineIcon from 'remixicon-react/CodeLineIcon';

interface ViewAppVersionProps {
  viewAppVersionFunction?: ViewVersionFunction;
  buttonText?: string;
  builderSessionStateId?: string;
}
// eslint-disable-next-line max-lines-per-function
const ViewVersionButton = ({
  viewAppVersionFunction,
  buttonText = isMobileDevice() ? '' : 'View code',
  builderSessionStateId,
}: ViewAppVersionProps) => {
  const handleViewAppFunction = () => {
    if (viewAppVersionFunction && builderSessionStateId) {
      viewAppVersionFunction(builderSessionStateId);
    }
  };

  if (!builderSessionStateId) {
    return null;
  }

  return (
    <div className="flex">
      <button
        onClick={handleViewAppFunction}
        disabled={useChatStore.getState().userInputLoading}
        className={classNames(
          'text-system-accent md:bg-transparent',
          'hover:bg-system-accent/10 rounded-lg text-lazy-btn-text p-1',
          {
            'cursor-not-allowed': useChatStore.getState().userInputLoading,
          }
        )}
      >
        <CodeLineIcon
          className="align-text-bottom
        inline-block mr-1"
          size={16}
        />
        <span className="hidden xs:inline-block">{buttonText}</span>
      </button>
    </div>
  );
};

export default ViewVersionButton;
