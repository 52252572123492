import React from 'react';
import StarterPrompt from './StarterPrompt';
import SearchIcon from 'remixicon-react/Search2LineIcon';
import { StarterPrompt as StarterPromptType } from '../../../api/generated';
import { TEST_ID_STARTER_PROMPTS } from '../../../constants';

interface StarterPromptsProps {
  starterPrompts?: StarterPromptType[];
  submitStarterPrompt: (starterPrompt: string) => void;
  showExploreTemplates?: boolean;
}

const StarterPrompts = (props: StarterPromptsProps) => {
  if (!props.starterPrompts) {
    return null;
  }

  return (
    <div
      className="flex flex-col items-start gap-2 py-6 px-4"
      data-testid={TEST_ID_STARTER_PROMPTS}
    >
      {props.showExploreTemplates && (
        <a
          className="flex gap-3 w-full items-center py-2 px-3 rounded-md
         text-system-accent hover:bg-blue-100"
          target="_blank"
          href="https://www.getlazy.ai/explore"
        >
          <SearchIcon size={20} />
          <div className="text-sm font-medium leading-5">Explore templates</div>
        </a>
      )}
      {props.starterPrompts.map((starterPrompt, index) => (
        <StarterPrompt
          key={index}
          starterPrompt={starterPrompt}
          submitStarterPrompt={props.submitStarterPrompt}
        />
      ))}
    </div>
  );
};

export default StarterPrompts;
