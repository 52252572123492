import React, { MouseEventHandler } from 'react';
import Loader4LineIcon from 'remixicon-react/Loader4LineIcon';
import classNames from 'classnames';
import { LazyReactIconComponentType } from '../../icons/typings';
import { RemixiconReactIconComponentType } from 'remixicon-react';

export interface ButtonIcon {
  icon: LazyReactIconComponentType | RemixiconReactIconComponentType;
  iconSize?: number;
}

export interface ButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  iconProps?: ButtonIcon;
  dataTestid?: string;
}

const Button = ({
  onClick,
  className,
  loading,
  disabled,
  children,
  dataTestid,
  iconProps: IconProps,
}: ButtonProps) => {
  const clickHandler = disabled ? undefined : onClick;

  const styles = {
    'cursor-not-allowed text-lazy-light-grey': disabled,
  };

  return (
    <button
      data-testid={dataTestid}
      onClick={clickHandler}
      className={classNames(
        'flex justify-center items-center gap-1 rounded-md px-2 py-1.5 font-medium h-8',
        styles,
        className
      )}
    >
      {IconProps?.icon && !loading && <IconProps.icon size={IconProps.iconSize} />}
      {loading && <Loader4LineIcon className="animate-spin" size="1.2rem" />}
      {children}
    </button>
  );
};

export default Button;
