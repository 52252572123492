/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Role } from '../models/Role';
import type { RoleCreate } from '../models/RoleCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RoleService {

    /**
     * Get all roles
     * Returns list of all the Roles
     * @returns Role Successful Response
     * @throws ApiError
     */
    public static roleGetAllRoles(): CancelablePromise<Array<Role>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/role',
        });
    }

    /**
     * Create Role
     * Creates a new Role
     * @param requestBody
     * @returns Role Successful Response
     * @throws ApiError
     */
    public static roleCreateRole(
        requestBody: RoleCreate,
    ): CancelablePromise<Role> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/role',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get role
     * Returns details of the Role
     * @param roleId
     * @returns Role Successful Response
     * @throws ApiError
     */
    public static roleGetRole(
        roleId: string,
    ): CancelablePromise<Role> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/role/{role_id}',
            path: {
                'role_id': roleId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
