import { create } from 'zustand';
import { UtmParams } from '../api/generated';

interface UserAnalyticsStoreState {
  utmParams: Map<string, string[]>;
  addUtmParam: (key: string, values: string[]) => void;
  addUtmsToUrl: (targetUrl: string) => string;
  asUtmParamsObject: () => UtmParams;
}

const stringArrayOrUndefined = (
  key: string,
  utmParamsMap: Map<string, string[]>
): string[] | undefined => {
  const value = utmParamsMap.get(key);
  if (Array.isArray(value) && value.every((item) => typeof item === 'string')) {
    return value;
  }
  return undefined;
};

const cleanUtmParams = (utmParams: UtmParams): void => {
  (Object.keys(utmParams) as Array<keyof UtmParams>).forEach((key) => {
    if (!utmParams[key] || utmParams[key]?.length === 0) {
      delete utmParams[key];
    }
  });
};

export const useUserAnalyticsStore = create<UserAnalyticsStoreState>((set, get) => ({
  utmParams: new Map<string, string[]>(),
  addUtmParam: (key: string, values: string[]) => {
    const currentValues = get().utmParams;
    const newMap = new Map(currentValues);
    newMap.set(key, values);

    set(() => ({ utmParams: newMap }));
  },
  addUtmsToUrl: (targetUrl: string) => {
    // eslint-disable-next-line compat/compat
    const url = new URL(targetUrl);
    for (const [key, values] of get().utmParams.entries()) {
      for (const value of values) {
        url.searchParams.set(key, value);
      }
    }

    return url.toString();
  },
  asUtmParamsObject: (): UtmParams => {
    const utmParamsMap = get().utmParams;
    const utmParams: UtmParams = {
      source: stringArrayOrUndefined('utm_source', utmParamsMap),
      medium: stringArrayOrUndefined('utm_medium', utmParamsMap),
      campaign: stringArrayOrUndefined('utm_campaign', utmParamsMap),
      term: stringArrayOrUndefined('utm_term', utmParamsMap),
      content: stringArrayOrUndefined('utm_content', utmParamsMap),
    };
    cleanUtmParams(utmParams);
    return utmParams;
  },
}));
