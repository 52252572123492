import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';

export const useGoToApp = () => {
  const navigate = useNavigate();

  const goToApp = useCallback(
    (id: string) => {
      navigate(`/apps/${id}/build`);
    },
    [navigate]
  );

  return goToApp;
};

export const useAppIdFromUrl = (): string | undefined => {
  const params = useParams();
  return params?.appId;
};
