import React, { useState } from 'react';
import ArrowRightIcon from 'remixicon-react/ArrowRightLineIcon';
import FileUploadModal from './FileUploadModal';
import { File } from '../../../api/generated/models/File';
import { FileUploadFile } from '@lazy-frontend/components/VSCodeEditor/models';

interface MissingFilesActionProps {
  missingFiles: FileUploadFile[];
  updateFiles: (updateFiles: File[]) => Promise<void>;
}

const MissingFilesAction = (props: MissingFilesActionProps) => {
  const [isAddMissingFilesVisible, setIsAddMissingFilesVisible] = useState<boolean>(false);
  return (
    <>
      {isAddMissingFilesVisible && (
        <FileUploadModal
          missingFiles={props.missingFiles}
          onHide={() => {
            setIsAddMissingFilesVisible(false);
          }}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          updateFiles={props.updateFiles}
        ></FileUploadModal>
      )}
      <div
        className="flex items-center self-stretch py-2 px-3 text-violet-500 hover:bg-violet-100
        rounded-lg border border-system-separator bg-violet-50 cursor-pointer"
        onClick={() => setIsAddMissingFilesVisible(true)}
      >
        <div className="flex-1 text-sm font-medium leading-5">Add missing files</div>
        <ArrowRightIcon size={20} />
      </div>
    </>
  );
};

export default MissingFilesAction;
