import HistoryFillIcon from 'remixicon-react/HistoryFillIcon';
import React, { useState } from 'react';
import RevertAppModal from '../RevertAppModal';
import { RevertFunction, useChatStore } from '../../store/chat';
import classNames from 'classnames';
import { isMobileDevice } from '../../utils/deviceDimensions';

interface RevertMessageButtonProps {
  revertFunction: RevertFunction;
  builderSessionStateId?: string;
  buttonText?: string;
  className?: string;
  iconSize?: number;
}
// eslint-disable-next-line max-lines-per-function
const RevertMessageButton = ({
  revertFunction,
  builderSessionStateId,
  buttonText = isMobileDevice() ? '' : 'Recover',
  // eslint-disable-next-line max-len
  className = 'text-system-accent md:bg-transparent hover:bg-system-accent/10 rounded-lg text-lazy-btn-text p-1',
  iconSize = 16,
}: RevertMessageButtonProps) => {
  const [revertAppModalOpen, setRevertAppModalOpen] = useState(false);
  const handleRevert = async () => {
    if (revertFunction && builderSessionStateId) {
      await revertFunction(builderSessionStateId);
    }
  };

  if (!builderSessionStateId) {
    return null;
  }

  return (
    <div>
      {revertAppModalOpen ? (
        <RevertAppModal onHide={() => setRevertAppModalOpen(false)} onRevert={handleRevert} />
      ) : null}
      <button
        onClick={() => setRevertAppModalOpen(true)}
        disabled={useChatStore.getState().userInputLoading}
        className={classNames(className, {
          'cursor-not-allowed': useChatStore.getState().userInputLoading,
        })}
      >
        <HistoryFillIcon
          className="align-text-bottom
        inline-block mr-1"
          size={iconSize}
        />
        <span className="hidden xs:inline-block">{buttonText}</span>
      </button>
    </div>
  );
};

export default RevertMessageButton;
