import React, { Ref, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ReactComponent as CrossIcon } from '../assets/cross-icon.svg';
import RocketIcon from 'remixicon-react/Rocket2LineIcon';
import GlobalModalBackdrop from '../components/GlobalModalBackdrop';
import { UpgradeToProButton } from '../components/UpgradeToProButton';
import useModalVisibility from '../hooks/modalVisibility';
import { relativeTimeFromDates } from '../utils/time';
import ActionButton from './base/ActionButton';
import { LazyApp, LazyAppCustomDomain } from '../api/generated';
import { getCustomDomainsForApp } from '../api/BuilderApi';
import { FrontendEvents } from '../api/StatsApi';
import { useAuthStore } from '../store/auth';
import { useEventEmitter } from './eventEmitterHook';
import * as dataTestidConstants from '../constants';
import classNames from 'classnames';
import { isMobileDevice } from '../utils/deviceDimensions';

interface PublishAppModalProps {
  app: LazyApp;
  onHide: () => void;
  onPublishApp: (published: boolean) => Promise<void>;
  publishedUrl: string | null;
  lastPublishedAt: Date | null;
  isLastAppVersionPublished: boolean;
  isPublishSelectedVersion?: boolean;
}

// eslint-disable-next-line max-lines-per-function, max-statements
const PublishAppModal = ({
  app,
  onHide,
  onPublishApp,
  publishedUrl,
  lastPublishedAt,
  isLastAppVersionPublished,
  isPublishSelectedVersion,
}: PublishAppModalProps) => {
  const { itemRef, isVisible } = useModalVisibility(true);
  const { userPermissions } = useAuthStore();
  useEffect(() => {
    if (!isVisible) {
      onHide();
    }
  }, [isVisible]);
  const { emitEvent } = useEventEmitter();

  const [loading, setLoading] = useState(false);
  const [userPublishedApp, setUserPublishedApp] = useState(false);
  const [configuredCustomDomains, setConfiguredCustomDomains] = useState<LazyAppCustomDomain[]>([]);

  useEffect(() => {
    if (process.env.REACT_APP_FLAG_ENABLE_CUSTOM_DOMAIN === 'true') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises, promise/catch-or-return
      getCustomDomainsForApp(app.id).then((customDomains) => {
        setConfiguredCustomDomains(customDomains);
        return null;
      });
    }
  }, []);

  useEffect(() => {
    if (publishedUrl && userPublishedApp) {
      window.open(publishedUrl, '_blank');
    }
  }, [publishedUrl]);

  const emitPublishEvent = () => {
    emitEvent(
      lastPublishedAt
        ? FrontendEvents.USER_PUBLISHED_APP_UPDATE
        : FrontendEvents.USER_PUBLISHED_APP,
      app.id
    );
  };

  const handlePublish = () => {
    setLoading(true);

    onPublishApp(true)
      .then(() => {
        emitPublishEvent();
        setLoading(false);
        setUserPublishedApp(true);
        toast.success('App published successfully.');

        return '';
      })
      .finally(() => {
        if (publishedUrl) {
          window.open(publishedUrl, '_blank');
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error('There was an error publishing the app. Please try again later.');
        return false;
      });
  };

  const renderPublishButton = () =>
    userPermissions?.isUserAllowedToPublish ? (
      <ActionButton
        className="bg-system-success"
        onClick={handlePublish}
        isLoading={loading}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data-testid={dataTestidConstants.PUBLISH_APP_BUTTON}
      >
        {publishedUrl
          ? isPublishSelectedVersion
            ? 'Publish selected version'
            : 'Publish latest version'
          : 'Publish now'}
      </ActionButton>
    ) : (
      <UpgradeToProButton />
    );

  const renderManageYourAppButton = (publishedUrl: string) => (
    <ActionButton
      buttonType="primary"
      onClick={() => window.open(publishedUrl, '_blank')}
      fillState="light"
      data-testid={dataTestidConstants.APP_PRODUCTION_VERSION_LINK}
    >
      <RocketIcon size={20} />
      Open production version
    </ActionButton>
  );

  const renderConfigureCustomDomainButton = (publishedUrl: string) => (
    <ActionButton
      buttonType="primary"
      onClick={() => window.open(`${publishedUrl}?configure_custom_domains=true`, '_blank')}
      fillState="light"
    >
      Configure custom domain
    </ActionButton>
  );

  const renderBottomActionButtons = () => (
    <div className="flex p-4 justify-end gap-2 items-end self-end h-full md:h-auto">
      {renderPublishButton()}
    </div>
  );

  return (
    <div
      className="flex items-center justify-center h-full w-full absolute z-[55]"
      data-testid={dataTestidConstants.PUBLISH_PRODUCTION_APP_MODAL}
    >
      <GlobalModalBackdrop />
      <div
        ref={itemRef as Ref<HTMLDivElement>}
        className="rounded-md bg-white shadow-2xl z-[100] h-full w-screen md:max-w-xl md:h-auto"
      >
        <div
          className={classNames('flex flex-col overflow-scroll', {
            'h-full': isMobileDevice(),
            'h-96': !isMobileDevice(),
          })}
        >
          <div className="flex flex-row justify-between p-4">
            <div className="font-semibold text-base">Publish Production Version</div>
            <button
              onClick={onHide}
              data-testid={dataTestidConstants.CLOSE_PUBLISH_PRODUCTION_APP_MODAL}
            >
              <CrossIcon className="w-5" />
            </button>
          </div>
          <hr />
          {publishedUrl ? (
            <div className="h-full">
              <div>
                <div className="flex flex-row items-center justify-center gap-2 w-full p-4">
                  <div className="flex flex-1 flex-col pr-2">
                    <div className="text-400 mb-1">Manage your Lazy app:</div>
                    <div className="text-subtitle-custom-gray text-xs text-400 mb-1">
                      Production version updated {relativeTimeFromDates(lastPublishedAt)}
                    </div>
                  </div>
                  {renderManageYourAppButton(publishedUrl)}
                </div>
                <hr />
              </div>
              {configuredCustomDomains.map((customDomain) => (
                <div key={customDomain.id}>
                  <div className="flex flex-row items-center justify-center gap-2 w-full p-4">
                    <div className="flex flex-1 flex-col pr-2">
                      <div className="text-400 mb-1">Your web app:</div>
                      <a
                        href={customDomain.name}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classNames(
                          'font-medium text-xs text-system-blue-6',
                          'xs:max-w-[200px] md:max-w-[280px] 2xl:max-w-[380px] truncate'
                        )}
                      >
                        {customDomain.name}
                      </a>
                    </div>
                    {renderConfigureCustomDomainButton(publishedUrl)}
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-1 flex-col items-center justify-center gap-6">
              <div className="text-subtitle-custom-gray text-400 px-6">
                No production version published
              </div>
              {renderPublishButton()}
            </div>
          )}
          {publishedUrl && !isLastAppVersionPublished && renderBottomActionButtons()}
        </div>
      </div>
    </div>
  );
};

export default PublishAppModal;
