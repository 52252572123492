import { useEffect, useRef, useState } from 'react';
import { isAuthenticatedForProvider } from '../../../api/TtyHelper';
import { sleep } from '../../../api/utils';

const useAuthenticationCheck = (instanceId: string, provider: string, sleepDuration = 1000) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      if (instanceId) {
        while (isMounted.current) {
          // Check if the component is still mounted
          const authStatus = await isAuthenticatedForProvider(instanceId, provider);

          if (authStatus) {
            if (isMounted.current) {
              setIsAuthenticated(authStatus);
            } // Update state only if component is mounted
            break;
          } else {
            await sleep(sleepDuration);
          }
        }
      }
    };

    (async () => {
      await checkAuthentication();
    })();

    // Cleanup function to set isMounted to false when component unmounts
    return () => {
      isMounted.current = false;
    };
  }, [instanceId, provider, isAuthenticatedForProvider, sleepDuration]);

  return isAuthenticated;
};

export default useAuthenticationCheck;
