/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BuilderMessageOrigin {
    CHAT_INPUT = 'CHAT_INPUT',
    LLM_REPLY = 'LLM_REPLY',
    STARTER_PROMPT = 'STARTER_PROMPT',
    APP_SKELETON_SELECTION = 'APP_SKELETON_SELECTION',
    SUGGEST_SMALL_FEATURES = 'SUGGEST_SMALL_FEATURES',
    UPDATED_FILES = 'UPDATED_FILES',
    UPDATED_APP_LOGIC = 'UPDATED_APP_LOGIC',
    UPDATED_SECRETS = 'UPDATED_SECRETS',
    TRY_TO_FIX_CODE_ISSUES = 'TRY_TO_FIX_CODE_ISSUES',
    PROMPT_RECIPE = 'PROMPT_RECIPE',
}
