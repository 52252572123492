import { AppInstanceState } from './api/generated';
export const LOADING_MESSAGES = [
  'Sloth and steady wins the race',
  'Slowly crafting your app',
  'Lazy loading, literally',
  'Taking it easy',
  'Sloth and steady',
  'Not trying too hard',
  'Patience, sloths are building',
  'Just a few branches away',
  "Coding apps at a sloth's pace",
  'Meticulously designing your experience',
  'Steady as a sloth',
  'Making haste slowly',
  "No rush, we're sloths",
  'Slothing through the code',
  'Wandering through the digital forest',
  'Climbing code branches',
  'Taking it one leaf at a time',
  'Savvy sloths at work',
  'Building your app, leaf by leaf',
  'Being slow, but sure',
  'Taking the slow lane',
  'A moment of sloth, please',
  'Sloths are in control',
  'Leisurely working on your request',
  'Taking our sweet time',
  'One slothful step at a time',
  'Just hanging in there',
  'Going slow to go fast',
  'In slow we trust',
  'Sloth loading in progress',
  'Taking a slow and steady approach',
  "It's a sloth's world",
  'Deliberate slothfulness in action',
  'Perfection takes time',
  'Keeping it slow and smooth',
  'Enjoying the scenic route',
  'Coding at sloth speed',
  'The art of slow loading',
  'Efficiency in laziness',
  'Slow and sound',
  'Just swinging along',
  'Sloths in the matrix',
  'Going at a comfy pace',
  'Loading, the sloth way',
  'Casual coding in process',
  'Building apps at leisure',
  'Carefully crafting',
  "Strollin' through the code",
  'Slow but reliable',
  'Just hanging around',
  'Sloth pace, best pace',
  "Chillin' and codin'",
  'Just a slow shuffle away',
  "Haste makes waste, we're sloths",
  'On sloth time',
  'Patience is a virtue',
  "Rolling along at a sloth's pace",
];

export const SHUFFLED_LOADING_MESSAGES = LOADING_MESSAGES.map((message) => ({
  sort: Math.random(),
  message,
}))
  .sort((a, b) => a.sort - b.sort)
  .map(({ message }) => message);

export const INSTANCE_STATE_DESCRIPTION: Map<AppInstanceState, string> = new Map<
  AppInstanceState,
  string
>();
INSTANCE_STATE_DESCRIPTION.set(AppInstanceState.RUNNING, 'App running');
INSTANCE_STATE_DESCRIPTION.set(AppInstanceState.STOPPED, 'App stopped');
INSTANCE_STATE_DESCRIPTION.set(AppInstanceState.ENDED, 'App finished running');
INSTANCE_STATE_DESCRIPTION.set(AppInstanceState.ENDED_WITH_ERROR, 'App ran with errors');
