/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CronExpressions } from './CronExpressions';

export type Schedule = {
    /**
     * Schedule the app to run at defined schedules
     */
    command: Schedule.command;
    /**
     * Mandatory arg with list of CRONTAB like spec
     */
    args: CronExpressions;
};

export namespace Schedule {

    /**
     * Schedule the app to run at defined schedules
     */
    export enum command {
        SCHEDULE = 'schedule',
    }


}

