export const importPublicKey = (pemContents: string): Promise<CryptoKey> => {
  // Decode the base64 string
  const binaryDerString = atob(pemContents);

  // Convert the binary string to an ArrayBuffer
  const binaryDer = new Uint8Array(binaryDerString.length);
  for (let i = 0; i < binaryDerString.length; i++) {
    binaryDer[i] = binaryDerString.charCodeAt(i);
  }
  return window.crypto.subtle.importKey(
    'spki',
    binaryDer,
    {
      name: 'RSA-PSS',
      hash: 'SHA-256',
    },
    true,
    ['verify']
  );
};

export const verifySignature = async (
  publicKey: CryptoKey,
  signature: string,
  data: string
): Promise<boolean> => {
  const enc = new TextEncoder();
  const encodedData = enc.encode(data);
  const encodedSignature = new Uint8Array(Array.from(atob(signature), (c) => c.charCodeAt(0)));

  return await crypto.subtle.verify(
    {
      name: 'RSA-PSS',
      saltLength: 222, // 256 - 32 - 2
    },
    publicKey,
    encodedSignature,
    encodedData
  );
};

const verifySignatureForLazyInternalMessageImpl = async (
  message: string,
  signature: string
): Promise<boolean> => {
  if (!process.env.REACT_APP_PUBLIC_KEY_LAZY_INTERNAL_MESSAGES) {
    return false;
  }
  const publicKey = await importPublicKey(process.env.REACT_APP_PUBLIC_KEY_LAZY_INTERNAL_MESSAGES);
  const result = await verifySignature(publicKey, signature, message);
  return result;
};

export const verifySignatureForLazyInternalMessage = async (
  message: string,
  signature: string
): Promise<boolean> => {
  try {
    return await verifySignatureForLazyInternalMessageImpl(message, signature);
  } catch {}
  return false;
};
