import React, { useEffect } from 'react';
import { useAuthStore } from '../../store/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoadingPage from '../LoadingPage';

const LogoutPage = () => {
  const navigate = useNavigate();
  const [searchParams, _setSearchParams] = useSearchParams();

  const handleRedirect = () => {
    const redirectUrlQueryParam = searchParams.get('redirect');
    if (redirectUrlQueryParam) {
      return (window.location.href = redirectUrlQueryParam);
    } else {
      return navigate('/login');
    }
  };

  const logout = () => {
    if (useAuthStore.getState().userIsAuthenticated) {
      useAuthStore
        .getState()
        .signOut()
        .then(() => handleRedirect())
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch((_e) => {});
    } else {
      handleRedirect();
    }
  };

  useEffect(() => {
    logout();
  }, []);
  return <LoadingPage />;
};

export default LogoutPage;
