import {
  AppFolderService,
  LazyAppFolder,
  LazyAppFolderCreate,
  LazyAppFolderUpdate,
} from './generated';
import { setupHeaders } from './index';

setupHeaders && setupHeaders();

export async function createFolder(folderName: string, orgId?: string): Promise<LazyAppFolder> {
  return AppFolderService.appFolderCreateFolder({
    name: folderName,
    org_id: orgId,
  } as LazyAppFolderCreate);
}

export async function updateFolder(folderId: string, folderName: string): Promise<LazyAppFolder> {
  return AppFolderService.appFolderUpdateFolder(folderId, {
    name: folderName,
  } as LazyAppFolderUpdate);
}

export async function getAllFolders(orgId?: string): Promise<LazyAppFolder[]> {
  return AppFolderService.appFolderGetAllFolders(orgId);
}
