import React from 'react';
import { useOrganizationStore } from '../store/organization';
import { NewOrganizationSetupModal } from './NewOrganizationSetupModal';
import { OrganizationManagementModal } from './OrganizationManagementModal';

export const OrganizationModal = () => {
  const { isOrganizationManagementModalOpen, isCreateNewOrganizationModalOpen } =
    useOrganizationStore();

  return (
    <>
      {isCreateNewOrganizationModalOpen && <NewOrganizationSetupModal />}
      {isOrganizationManagementModalOpen && <OrganizationManagementModal />}
    </>
  );
};
