import React, { useEffect, useState } from 'react';

import { ApproveModal } from '../components/base/ApproveModal';
import { BUILDER_VIEW_MOVE_APP_TO_TEAM_CONFIRMATION_BUTTON } from '../constants';
import { useChatStore } from '../store/chat';
import { useOrganizationStore } from '../store/organization';
import { useAppActions } from './appActionsHook';

interface MoveAppToOrgModalProps {
  onSuccess: () => Promise<void>;
}

// eslint-disable-next-line max-lines-per-function
export const MoveAppToOrgModal = ({ onSuccess }: MoveAppToOrgModalProps) => {
  const [orgIdToMoveAppTo, setOrgIdToMoveAppTo] = useState<string | null>(null);
  const { userOrganizationRoleAssignments } = useOrganizationStore();
  const { showMoveAppToOrgModalForApp: app } = useChatStore();
  const { moveAppToOrganization } = useAppActions();

  useEffect(() => {
    if (userOrganizationRoleAssignments.length > 0) {
      setOrgIdToMoveAppTo(userOrganizationRoleAssignments[0].org.id);
    }
  }, [userOrganizationRoleAssignments]);

  const onClose = () => useChatStore.setState({ showMoveAppToOrgModalForApp: null });

  if (!app) {
    return null;
  }

  return (
    <ApproveModal
      message={
        <>
          <span className="whitespace-pre-line">
            To which team do you
            <br />
            want to move the {app?.is_template ? 'template' : 'application'}?
          </span>
          <div className="whitespace-nowrap pt-2 flex w-full gap-2">
            <select
              className="w-full border border-system-separator p-2 rounded"
              onChange={(e) => {
                setOrgIdToMoveAppTo(e.target.value);
              }}
            >
              {userOrganizationRoleAssignments.map((roleAssignment) => (
                <option key={roleAssignment.id} value={roleAssignment.org.id}>
                  {roleAssignment.org.name}
                </option>
              ))}
            </select>
          </div>
        </>
      }
      approveButton={{
        text: 'Move',
        className: 'bg-system-accent text-white',
        dataTestId: BUILDER_VIEW_MOVE_APP_TO_TEAM_CONFIRMATION_BUTTON,
      }}
      rejectButton={{
        text: 'Cancel',
      }}
      onApprove={() => {
        if (orgIdToMoveAppTo) {
          // eslint-disable-next-line promise/catch-or-return
          moveAppToOrganization(orgIdToMoveAppTo, app.id, onSuccess).finally(onClose);
        } else {
          onClose();
        }
      }}
      onReject={() => {
        onClose();
      }}
    />
  );
};
