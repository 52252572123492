import React, { useEffect, useState } from 'react';
import { createApp } from '../api/BuilderApi';
import { useNavigate, useParams } from 'react-router';
import LoadingPage from './LoadingPage';
import { toast } from 'react-toastify';
import { useOrganizationStore } from '../store/organization';

const ERROR_UNKNOWN =
  'There was an error creating app from template. Refresh this page to try again.';

const StartWithTemplateInterstitial = () => {
  const navigate = useNavigate();
  const params = useParams();
  const templateId = params.templateId as string;
  const [error, setError] = useState('');
  const { activeOrganization } = useOrganizationStore();

  const createAppFromTemplate = async () => {
    const app = await createApp({ parent_id: templateId, org_id: activeOrganization?.id });
    navigate(`/apps/${app.id}/build`);
    toast.success('App created successfully from template. Navigated to the app.');
  };

  useEffect(() => {
    createAppFromTemplate().catch((e) => {
      setError(ERROR_UNKNOWN);
      // eslint-disable-next-line no-console
      console.error(e);
    });
  }, []);

  return <LoadingPage error={error} />;
};

export default StartWithTemplateInterstitial;
