import React from 'react';

import { APPS_TEMPLATES_FILTERS, ARCHIVED_FILTERS, PUBLISHED_STATUS_FILTERS } from './constants';

interface AppsAdvanceFiltersProps {
  archivedFilter: string;
  publishedStatusFilter: string;
  appsAndTemplatesFilter: string;
  onArchivedFilterChange: (filterValue: string) => void;
  onPublishedStatusFilterChange: (filterValue: string) => void;
  onAppsAndTemplatesFilterChange: (filterValue: string) => void;
}

// eslint-disable-next-line max-lines-per-function
const AppsAdvanceFilters: React.FC<AppsAdvanceFiltersProps> = ({
  archivedFilter,
  publishedStatusFilter,
  appsAndTemplatesFilter,
  onArchivedFilterChange,
  onPublishedStatusFilterChange,
  onAppsAndTemplatesFilterChange,
}) => {
  return (
    <div className="flex gap-1 px-3 flex-wrap">
      <select
        className="p-2 rounded-full bg-colors-neutral-100 text-sm"
        onChange={(e) => onArchivedFilterChange(e.target.value)}
        value={archivedFilter}
      >
        <option value={ARCHIVED_FILTERS.NON_ARCHIVED}>Hide archived</option>
        <option value={ARCHIVED_FILTERS.ARCHIVED}>Archived only</option>
        <option value={ARCHIVED_FILTERS.ALL}>All</option>
      </select>

      <select
        className="p-2 rounded-full bg-colors-neutral-100"
        onChange={(e) => onAppsAndTemplatesFilterChange(e.target.value)}
        value={appsAndTemplatesFilter}
      >
        <option value={APPS_TEMPLATES_FILTERS.ALL}>Apps + Templates</option>
        <option value={APPS_TEMPLATES_FILTERS.APPS}>Apps</option>
        <option value={APPS_TEMPLATES_FILTERS.TEMPLATES}>Templates</option>
      </select>

      <select
        className="p-2 rounded-full bg-colors-neutral-100"
        onChange={(e) => onPublishedStatusFilterChange(e.target.value)}
        value={publishedStatusFilter}
      >
        <option value={PUBLISHED_STATUS_FILTERS.ALL}>All Statuses</option>
        <option value={PUBLISHED_STATUS_FILTERS.PUBLISHED}>Published</option>
        <option value={PUBLISHED_STATUS_FILTERS.UNPUBLISHED}>Unpublished</option>
      </select>
    </div>
  );
};

export default AppsAdvanceFilters;
