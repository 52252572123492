import React, { memo } from 'react';
import Button from './base/Button';
import classNames from 'classnames';

export interface ReconnectModalProps {
  onReconnect: () => void;
  onCancel: () => void;
}

// eslint-disable-next-line max-lines-per-function
export const ReconnectModal: React.FC<ReconnectModalProps> = memo(({ onReconnect, onCancel }) => {
  return (
    <>
      <div className="bg-lazy-light-grey absolute top-0 left-0 right-0 bottom-0 z-10"></div>
      <div
        className={classNames(
          'rounded-lg bg-lazy-light-grey fixed flex-1 top-0',
          'left-0 flex items-center justify-center h-screen w-screen z-[30]'
        )}
      >
        <div
          className={classNames(
            'flex flex-col items-center justify-center',
            ' p-6 gap-2 z-40 bg-white rounded max-w-120'
          )}
        >
          <div className="flex flex-row justify-between p-4 items-center">
            <div className="font-semibold text-base">Connection error</div>
          </div>
          <hr />
          <div className="w-full text-center text-sm font-medium">
            It looks like we disconnected due to network issues or something else.
            <br />
            Saving is disabled.
            <br />
            <br />
            Try to reconnect?
          </div>
          <div className="w-full flex flex-row gap-2 justify-center items-center">
            <Button className="bg-system-success text-white" onClick={onReconnect}>
              Yes
            </Button>
            <Button
              className="bg-white text-label-secondary border border-colors-neutral-300"
              onClick={onCancel}
            >
              No
            </Button>
          </div>
        </div>
      </div>
    </>
  );
});
