/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetAppManifest = {
    /**
     * Returns the app manifest
     */
    command: GetAppManifest.command;
};

export namespace GetAppManifest {

    /**
     * Returns the app manifest
     */
    export enum command {
        GET_APP_MANIFEST = 'get_app_manifest',
    }


}

