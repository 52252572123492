import { UserLoginRequest, UserService, UtmParams, JwtToken } from './generated';
import { useUserAnalyticsStore } from '../store/userAnalytics';

export const userSignedIn = async (): Promise<JwtToken> => {
  const utmParams: UtmParams = useUserAnalyticsStore.getState().asUtmParamsObject();
  const requestBody: UserLoginRequest = {
    utm_params: utmParams,
  };
  return await UserService.userLogin(requestBody);
};

export const getUserDetails = async (): Promise<JwtToken> => {
  return await UserService.userShowUserInfo();
};

export const getSupportUsersId = async (): Promise<string[]> => {
  return await UserService.userGetSupportUsersId();
};
