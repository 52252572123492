/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import React from 'react';
import { LazyReactIconComponentType } from './typings';

const DiscordIcon: LazyReactIconComponentType = ({ color = '#FFF', size = 20, ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.0862 4.45024C14.9453 3.92846 13.7337
         3.54819 12.4691 3.33594C12.3099 3.6101 12.133 
         3.98153 12.0092 4.27337C10.6641 4.0788 9.32867 
         4.0788 8.00212 4.27337C7.87831 3.98153 7.69258 
         3.6101 7.54223 3.33594C6.26876 3.54819 5.05717 
         3.92846 3.92429 4.45024C1.63377 7.82853 1.01472 
         11.1272 1.32425 14.3817C2.84536 15.4872 4.31343 
         16.1593 5.75584 16.6015C6.10959 16.1239 6.42797 
         15.6109 6.70212 15.0715C6.18034 14.8769 5.68509 
         14.6382 5.20753 14.3552C5.33135 14.2668 5.45517 
         14.1695 5.57013 14.0722C8.45317 15.3899 11.5759 
         15.3899 14.4235 14.0722C14.5473 14.1695 14.6623 
         14.2668 14.7862 14.3552C14.3086 14.6382 13.8133 
         14.8769 13.2916 15.0715C13.5657 15.6109 13.8841 
         16.1239 14.2378 16.6015C15.6793 16.1593 17.1562 
         15.4872 18.6694 14.3817C19.0497 10.6144 18.0672 
         7.34216 16.0862 4.45024ZM7.10008 12.3742C6.23339 
         12.3742 5.52589 11.5871 5.52589 10.6232C5.52589 
         9.65919 6.2157 8.8721 7.10008 8.8721C7.97559 8.8721 
         8.69192 9.65919 8.67425 10.6232C8.67425 11.5871 
         7.97559 12.3742 7.10008 12.3742ZM12.9113 12.3742C12.0446 
         12.3742 11.3362 11.5871 11.3362 10.6232C11.3362 9.65919 
         12.0269 8.8721 12.9113 8.8721C13.7868 8.8721 14.5032 
         9.65919 14.4854 10.6232C14.4854 11.5871 13.7957 
         12.3742 12.9113 12.3742Z"
        fill="white"
      />
    </svg>
  );
};

export default DiscordIcon;
