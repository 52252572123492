import classNames from 'classnames';
import React from 'react';
import ActionButton from './base/ActionButton';
import { RemixiconReactIconComponentType } from 'remixicon-react';

export interface BannerItemProps {
  icon?: RemixiconReactIconComponentType;
  text: string;
  style?: string;
  buttonText: string;
  buttonHandler?: () => void;
}

const Banner = (props: BannerItemProps) => {
  return (
    <div className={classNames('flex items-center py-3 px-4 w-full', props.style)}>
      <div className="flex flex-row">
        {props.icon && <props.icon size={20} color="#FFF" />}
        &nbsp;<div className="flex flex-col flex-1 text-white font-semibold">{props.text}</div>
      </div>
      <div className="flex justify-end flex-1">
        <ActionButton
          buttonType="primary"
          fillState="subtle"
          size="medium"
          contentAlignment="center"
          onClick={props.buttonHandler}
        >
          {props.buttonText}
        </ActionButton>
      </div>
    </div>
  );
};

export default Banner;
