import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { createApp, updateApp, generateTemplate } from '../api/BuilderApi';
import { LazyAppUpdate } from '../api/generated/index';
import { useChatStore } from '../store/chat';
import { useOrganizationStore } from '../store/organization';

// eslint-disable-next-line max-lines-per-function
export const useAppActions = () => {
  const { setError, setUserInputLoading } = useChatStore();
  const navigate = useNavigate();

  const archiveApp = async (appId: string, onSuccess: () => Promise<void>): Promise<boolean> => {
    try {
      setUserInputLoading(true);

      const lazyUpdate: LazyAppUpdate = {
        archive: true,
      };

      await updateApp(appId, lazyUpdate);

      toast.success('App archived successfully.');
      await onSuccess();

      return true;
    } catch (e) {
      toast.error('Failed to archive the app, please refresh and try again');
      return false;
    } finally {
      setUserInputLoading(false);
    }
  };

  const unarchiveApp = async (appId: string, onSuccess: () => Promise<void>): Promise<boolean> => {
    try {
      setUserInputLoading(true);

      const lazyUpdate: LazyAppUpdate = {
        archive: false,
      };

      await updateApp(appId, lazyUpdate);

      toast.success('App unarchived successfully.');
      await onSuccess();
      return true;
    } catch (e) {
      toast.error('Failed to unarchive the app, please refresh and try again');
      return false;
    } finally {
      setUserInputLoading(false);
    }
  };

  const copyApp = async (appId: string, isTemplate: boolean) => {
    const { activeOrganization } = useOrganizationStore.getState();
    setUserInputLoading(true);
    try {
      const copiedAppOrTemplate = await createApp({
        parent_id: appId,
        is_template: isTemplate,
        org_id: activeOrganization?.id,
      });
      navigate(`/apps/${copiedAppOrTemplate.id}/build`);
      toast.success(
        `${isTemplate ? 'Template' : 'App'} copied successfully.` +
          ` Navigated to copied ${isTemplate ? 'template' : 'app'}.`
      );
    } catch (e) {
      setError('Failed to make the copy, please refresh and try again');
    } finally {
      setUserInputLoading(false);
    }
  };

  const moveAppToOrganization = async (
    orgIdToMoveAppTo: string,
    appId: string,
    onSuccess: () => Promise<void>
  ) => {
    setUserInputLoading(true);
    try {
      const lazyUpdate: LazyAppUpdate = {
        org_id: orgIdToMoveAppTo,
      };
      await updateApp(appId, lazyUpdate);
      await onSuccess();

      toast.success('Successfully moved to the team.');
    } catch (e) {
      setError('Failed to move to the team, please refresh and try again');
    } finally {
      setUserInputLoading(false);
    }
  };

  const createAppFromTemplate = async (templateId: string) => {
    const { activeOrganization } = useOrganizationStore.getState();

    setUserInputLoading(true);
    try {
      const templateTestApp = await createApp({
        parent_id: templateId,
        is_template_test_app: true,
        org_id: activeOrganization?.id,
      });
      navigate(`/apps/${templateTestApp.id}/build`);
      toast.success('App created from the template. Navigated to the app.');
    } catch (e) {
      setError('Failed to create app from template, please refresh and try again');
    } finally {
      setUserInputLoading(false);
    }
  };

  const makeCommunityTemplateFromApp = async (appId: string) => {
    setUserInputLoading(true);
    try {
      const templateApp = await generateTemplate(appId);
      navigate(`/apps/${templateApp.id}/build`);
      toast.success('Template generated successfully. Navigated to template.');
    } catch (e) {
      setError('Failed to generate template, please refresh and try again');
    } finally {
      setUserInputLoading(false);
    }
  };

  return {
    archiveApp,
    unarchiveApp,
    copyApp,
    moveAppToOrganization,
    createAppFromTemplate,
    makeCommunityTemplateFromApp,
  };
};
