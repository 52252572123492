import React from 'react';
import { LoaderWithSlothLotties } from './LoaderWithSlothLotties';
import { TEST_APP_LOADER } from '../../constants';
import classNames from 'classnames';

interface LoaderProps {
  fullScreen?: boolean;
  dataTestId?: string;
  loaderContent?: () => React.ReactElement;
}

export const Loader: React.FC<LoaderProps> = ({
  fullScreen = true,
  dataTestId = TEST_APP_LOADER,
  loaderContent,
}) => {
  return (
    <div
      data-testid={dataTestId}
      className={classNames(
        {
          fixed: fullScreen,
          absolute: !fullScreen,
        },
        'z-[1000] backdrop-blur-md flex items-center justify-center flex-1',
        'w-full h-full top-0 left-0'
      )}
    >
      {loaderContent ? loaderContent() : <LoaderWithSlothLotties />}
    </div>
  );
};
