/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LazyAppVersion } from '../models/LazyAppVersion';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VersionService {

    /**
     * Publishes the most recent version of a Lazy App
     * Creates a new snapshot of the current app that can be executed
     * @param appId
     * @param published
     * @param builderSessionStateId
     * @returns LazyAppVersion Successful Response
     * @throws ApiError
     */
    public static versionCreateVersion(
        appId: string,
        published: boolean = false,
        builderSessionStateId?: string,
    ): CancelablePromise<LazyAppVersion> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/version/{app_id}',
            path: {
                'app_id': appId,
            },
            query: {
                'published': published,
                'builder_session_state_id': builderSessionStateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Retrieves a version based on its id
     * This entry point returns information for an version based on its ID
     * @param versionId
     * @returns LazyAppVersion Successful Response
     * @throws ApiError
     */
    public static versionGetVersion(
        versionId: string,
    ): CancelablePromise<LazyAppVersion> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/version/{version_id}',
            path: {
                'version_id': versionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Retrieves all versions
     * Returns information on all versions created
     * @param appId
     * @param publishedOnly
     * @returns LazyAppVersion Successful Response
     * @throws ApiError
     */
    public static versionGetAllVersions(
        appId?: string,
        publishedOnly?: boolean,
    ): CancelablePromise<Array<LazyAppVersion>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/version/',
            query: {
                'app_id': appId,
                'published_only': publishedOnly,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
