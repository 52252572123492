/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum IssueCategoryName {
    PYTHON_UNSOLVABLE_MISSING_IMPORTS = 'python_unsolvable_missing_imports',
    PYTHON_MISSING_MAIN_FUNCTION = 'python_missing_main_function',
    PYTHON_MAIN_BLOCKS = 'python_main_blocks',
    PYTHON_FLASK_APPS = 'python_flask_apps',
    PYTHON_TRYING_TO_OVERRIDE_ABILITIES_PACKAGE = 'python_trying_to_override_abilities_package',
    JAVASCRIPT_ESLINT_ISSUES = 'javascript_eslint_issues',
    JAVASCRIPT_UNMATCHED_BRACKETS = 'javascript_unmatched_brackets',
    BLANK_HTML = 'blank_html',
    MISSING_STATIC_DIRECTORY_ISSUE = 'missing_static_directory_issue',
    PYTHON_MISSING_MAIN_PY_FILE = 'python_missing_main_py_file',
    CIRCULAR_IMPORTS = 'circular_imports',
    PYTHON_MYPY_ISSUES = 'python_mypy_issues',
    ROUTES_DEFINED_IN_THE_APP_LEADING_TO_404 = 'routes_defined_in_the_app_leading_to_404',
    MISSING_ROOT_ROUTES = 'missing_root_routes',
    ROUTES_DEFINED_IN_THE_APP_BUT_NOT_MENTIONED_IN_THE_HTML = 'routes_defined_in_the_app_but_not_mentioned_in_the_html',
    RESOURCES_NOT_MENTIONED_IN_THE_HTML = 'resources_not_mentioned_in_the_html',
    ISOLATED_HTML_FILES = 'isolated_html_files',
    HTML_TEMPLATE_NOT_FOUND = 'html_template_not_found',
}
