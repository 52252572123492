import { useTabStore } from '../../store/tab';
import {
  Message,
  MessageSource,
  MessageContent,
  MessageType,
  VSCodeConnectionStatus,
} from './models';

export const MAX_ATTEMPTS_TO_RECONNECT_WITH_WEBSOCKET = 5;
export const CONNECTION_ATTEMPTS_COUNTER = {};
const TIME_BETWEEN_WEBSOCKET_RETRIAL = 1000;

interface CloseEvent {
  code: number;
}

class ServiceWebSocket {
  tabId: string;
  readyState: number;
  onmessage: ((event: MessageEvent) => void) | null;
  onerror: ((event: ErrorEvent) => void) | null;
  onopen: (() => void) | null;
  onclose: ((event: CloseEvent) => void) | null;
  messageQueue = [];

  constructor(
    tabId: string,
    onopen: (() => void) | null,
    onmessage: ((event: MessageEvent) => void) | null,
    onerror: ((event: ErrorEvent) => void) | null,
    onclose: ((event: CloseEvent) => void) | null
  ) {
    this.onmessage = onmessage;
    this.onerror = onerror;
    this.onopen = onopen;
    this.onclose = onclose;
    this.messageQueue = [];
    this.readyState = WebSocket.CLOSED;
    this.tabId = tabId;

    this.readyState = WebSocket.OPEN;
    if (this.onopen) {
      this.onopen();
      this.poll(); // Start the polling process
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  send(data: string) {
    // Send data to the service worker via fetch
    // eslint-disable-next-line @typescript-eslint/no-floating-promises, compat/compat
    fetch('/from-frontend-message-to-vscode', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'lazy-tab-id': this.tabId,
      },
      body: data,
    });
  }

  poll() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises, compat/compat
    fetch('/from-frontend-any-messages', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'lazy-tab-id': this.tabId,
      },
    })
      .then((response) => {
        if (response.status === 409) {
          // If the service worker reported a conflict it means that it is no longer the active one
          // so the browser needs to be refreshed.
          window.location.reload();
        }
        return response.json();
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((data: any) => {
        // eslint-disable-next-line max-len
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        data.forEach((element: any) => {
          if (this.onmessage) {
            // eslint-disable-next-line max-len
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            this.onmessage({ data: element.data } as MessageEvent);
          }
        });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setTimeout(this.poll.bind(this), 10);
        return null;
      })
      .catch((error) => {
        if (this.onerror) {
          this.onerror(error as ErrorEvent);
        }
        // On errors only retry after some time to make it easier to update service worker.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setTimeout(this.poll.bind(this), 1000);
      });
  }

  close() {
    // nothing to do here.
  }
}

class WebSocketManager {
  private static instance: WebSocketManager;
  private webSocket: ServiceWebSocket | null = null;

  private messageReceivedCallback: ((message: MessageContent) => void) | null = null;
  private signalReceivedCallback: ((signal: string) => void) | null = null;
  private vscodeCommandsCallback: ((message: Message) => void) | null = null;
  private onReconnectionFailedCallback: (() => void) | null = null;

  // Method to set the callback
  public setReconnectionFailedCallback(callback: () => void): void {
    this.onReconnectionFailedCallback = callback;
  }

  // Call this method within your reconnection logic when reconnection fails
  private handleReconnectionFailure(): void {
    if (this.onReconnectionFailedCallback) {
      this.onReconnectionFailedCallback();
    }
  }

  private constructor() {
    this.connect();
    this.handleWebsocketReconnection();
  }

  public static getInstance(): WebSocketManager {
    if (!WebSocketManager.instance) {
      WebSocketManager.instance = new WebSocketManager();
    }
    return WebSocketManager.instance;
  }

  public reconnect(): void {
    this.connect(true);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setMessageReceivedCallback(callback: (message: any) => void): void {
    this.messageReceivedCallback = callback;
  }

  public setSignalReceivedCallback(callback: (signal: string) => void): void {
    this.signalReceivedCallback = callback;
  }

  public setVSCodeCommandsCallback(callback: (message: Message) => void): void {
    this.vscodeCommandsCallback = callback;
  }

  private connect(isNew?: boolean): void {
    const tabId = useTabStore.getState().id;
    if (isNew) {
      CONNECTION_ATTEMPTS_COUNTER[tabId] = 0;
    }
    if (!this.webSocket || this.webSocket.readyState === WebSocket.CLOSED) {
      useTabStore.getState().setVSCodeConnectionStatus(VSCodeConnectionStatus.CONNECTING);
      const onopen = () => {
        useTabStore.getState().setVSCodeConnectionStatus(VSCodeConnectionStatus.OPEN);
        CONNECTION_ATTEMPTS_COUNTER[tabId] = 0;
        // eslint-disable-next-line no-console
        console.info('Lazy is connected to vscode');
      };

      const onmessage = (event: MessageEvent<string>) => {
        this.handleMessage(event.data);
      };

      const onclose = (event: CloseEvent) => {
        useTabStore.getState().setVSCodeConnectionStatus(VSCodeConnectionStatus.CLOSED);
        if (event.code !== 1000) {
          // Ignore normal closures
          this.handleWebsocketReconnection();
        }
      };
      this.webSocket = new ServiceWebSocket(tabId, onopen, onmessage, null, onclose);
    }
  }

  send(message: Message): void {
    if (!this.webSocket || this.webSocket.readyState !== WebSocket.OPEN) {
      setTimeout(() => {
        this.handleWebsocketReconnection();
        this.send(message);
      }, 100); // It can't try immediately because otherwise it will enter recursion and blow stack.
    }
    if (this.webSocket && this.webSocket.readyState === WebSocket.OPEN) {
      const messageToSend = JSON.stringify(message);
      this.webSocket.send(messageToSend);
    }
  }

  // eslint-disable-next-line max-lines-per-function
  private handleMessage(data: string): void {
    if (!this.webSocket || this.webSocket.readyState !== WebSocket.OPEN) {
      this.handleWebsocketReconnection();
      this.handleMessage(data);
    }
    const message = JSON.parse(data) as Message;
    if (
      message.source === MessageSource.VSCODE &&
      message.type === MessageType.APP_INFO_FILES &&
      this.messageReceivedCallback
    ) {
      this.messageReceivedCallback(message.content);
    } else if (
      message.source === MessageSource.VSCODE &&
      message.type === MessageType.SIGNAL &&
      this.signalReceivedCallback
    ) {
      this.signalReceivedCallback(message.content as string);
    } else if (
      message.source === MessageSource.VSCODE &&
      message.type === MessageType.COMMAND &&
      this.vscodeCommandsCallback
    ) {
      this.vscodeCommandsCallback(message);
    }
  }

  private handleWebsocketReconnectionImpl(): void {
    const tabId = useTabStore.getState().id;
    const reconnect = () => {
      if (CONNECTION_ATTEMPTS_COUNTER[tabId] <= MAX_ATTEMPTS_TO_RECONNECT_WITH_WEBSOCKET) {
        setTimeout(() => {
          if (
            this.webSocket?.readyState === WebSocket.OPEN ||
            this.webSocket?.readyState === WebSocket.CONNECTING
          ) {
            return;
          }
          this.connect();
          // eslint-disable-next-line no-console
          console.info(`Attempt ${Number(CONNECTION_ATTEMPTS_COUNTER[tabId]) + 1} to reconnect`);
        }, TIME_BETWEEN_WEBSOCKET_RETRIAL);
        CONNECTION_ATTEMPTS_COUNTER[tabId]++;
      } else {
        useTabStore.getState().setVSCodeConnectionStatus(VSCodeConnectionStatus.TIMEOUT);
        this.handleReconnectionFailure();
      }
    };

    CONNECTION_ATTEMPTS_COUNTER[tabId] = Number(CONNECTION_ATTEMPTS_COUNTER[tabId]) || 0;
    reconnect();
  }

  private handleWebsocketReconnection(): void {
    // Avoid blowing stack by avoiding recursions.
    setTimeout(() => this.handleWebsocketReconnectionImpl(), 100);
  }

  disconnect(): void {
    if (this.webSocket) {
      this.webSocket.close();
    }
  }
}

export default WebSocketManager;
