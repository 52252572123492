/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Organization } from '../models/Organization';
import type { OrganizationCreate } from '../models/OrganizationCreate';
import type { OrganizationUpdate } from '../models/OrganizationUpdate';
import type { OrganizationUserInvite } from '../models/OrganizationUserInvite';
import type { OrganizationUserInviteCreate } from '../models/OrganizationUserInviteCreate';
import type { OrganizationUserInviteUpdate } from '../models/OrganizationUserInviteUpdate';
import type { OrganizationUserRoleAssignment } from '../models/OrganizationUserRoleAssignment';
import type { OrganizationUserRoleAssignmentUpdate } from '../models/OrganizationUserRoleAssignmentUpdate';
import type { OrganizationWithInvitesAndRoles } from '../models/OrganizationWithInvitesAndRoles';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganizationService {

    /**
     * Get orgnaization
     * Returns details of the Organization
     * @param orgId
     * @returns OrganizationWithInvitesAndRoles Successful Response
     * @throws ApiError
     */
    public static organizationGetOrganization(
        orgId: string,
    ): CancelablePromise<OrganizationWithInvitesAndRoles> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/organization/{org_id}',
            path: {
                'org_id': orgId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Organization
     * Updates an existing Organization
     * @param orgId
     * @param requestBody
     * @returns Organization Successful Response
     * @throws ApiError
     */
    public static organizationUpdateOrganization(
        orgId: string,
        requestBody: OrganizationUpdate,
    ): CancelablePromise<Organization> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/organization/{org_id}',
            path: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create new Organization
     * Creates a new Organization
     * @param requestBody
     * @returns Organization Successful Response
     * @throws ApiError
     */
    public static organizationCreateOrganization(
        requestBody: OrganizationCreate,
    ): CancelablePromise<Organization> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/organization',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Sends organization invite
     * Sends organization invite to user
     * @param orgId
     * @param requestBody
     * @returns OrganizationUserInvite Successful Response
     * @throws ApiError
     */
    public static organizationSendInvite(
        orgId: string,
        requestBody: OrganizationUserInviteCreate,
    ): CancelablePromise<OrganizationUserInvite> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/organization/{org_id}/invite',
            path: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Deletes organization invite
     * Deletes organization invite to user
     * @param orgId
     * @param inviteId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static organizationDeleteInvite(
        orgId: string,
        inviteId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/organization/{org_id}/invite/{invite_id}',
            path: {
                'org_id': orgId,
                'invite_id': inviteId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Updates organization invite
     * Updates organization invite status
     * @param orgId
     * @param inviteId
     * @param requestBody
     * @returns OrganizationUserInvite Successful Response
     * @throws ApiError
     */
    public static organizationUpdateInvite(
        orgId: string,
        inviteId: string,
        requestBody: OrganizationUserInviteUpdate,
    ): CancelablePromise<OrganizationUserInvite> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/organization/{org_id}/invite/{invite_id}',
            path: {
                'org_id': orgId,
                'invite_id': inviteId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Deletes user role assignment
     * Deletes user role assignment in the organization
     * @param orgId
     * @param userRoleAssignmentId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static organizationDeleteUserRoleAssignment(
        orgId: string,
        userRoleAssignmentId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/organization/{org_id}/user_role_assignment/{user_role_assignment_id}',
            path: {
                'org_id': orgId,
                'user_role_assignment_id': userRoleAssignmentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update user role assignment
     * Updates user role assignment in the organization
     * @param orgId
     * @param userRoleAssignmentId
     * @param requestBody
     * @returns OrganizationUserRoleAssignment Successful Response
     * @throws ApiError
     */
    public static organizationUpdateUserRoleAssignment(
        orgId: string,
        userRoleAssignmentId: string,
        requestBody: OrganizationUserRoleAssignmentUpdate,
    ): CancelablePromise<OrganizationUserRoleAssignment> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/organization/{org_id}/user_role_assignment/{user_role_assignment_id}',
            path: {
                'org_id': orgId,
                'user_role_assignment_id': userRoleAssignmentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
