/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AppInstanceState {
    STARTING = 'starting',
    STARTED = 'started',
    INSTALLING_DEPENDENCIES = 'installing_dependencies',
    RUNNING = 'running',
    STOPPING = 'stopping',
    STOPPED = 'stopped',
    STOPPED_AUTOMATICALLY = 'stopped_automatically',
    ENDED = 'ended',
    ENDED_WITH_ERROR = 'ended_with_error',
    UNKNOWN_ERROR = 'unknown_error',
    NEVER_RUN = 'never_run',
}
