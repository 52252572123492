import React from 'react';
import { Tooltip } from 'react-tooltip';

interface MessageTimestampProps {
  date: Date;
  hasTooltip: boolean;
}
const shortDateOptions: Intl.DateTimeFormatOptions = {
  month: 'long',
  day: 'numeric',
};

const onlyTimeShown: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
};

const timeAndWeekDay: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
  weekday: 'short',
};

const timeAndDate: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  day: 'numeric',
  hour12: true,
  month: 'short',
};

const dateLocalesArgument: Intl.LocalesArgument = 'en-GB';

export function getDateFormatted(date: Date): string {
  const currentDate = new Date();
  const daysDifference = currentDate.getUTCDate() - date.getUTCDate();
  if (daysDifference === 0 && currentDate.getUTCMonth() === date.getUTCMonth()) {
    return `Today ${date.toLocaleString([], onlyTimeShown)}`;
  } else if (daysDifference === 1 && currentDate.getUTCMonth() === date.getUTCMonth()) {
    return `Yesterday ${date.toLocaleString([], onlyTimeShown)}`;
  } else if (daysDifference < 7 && currentDate.getUTCMonth() === date.getUTCMonth()) {
    return date.toLocaleString([], timeAndWeekDay);
  } else {
    return date.toLocaleString([], timeAndDate);
  }
}

const MessageTimestamp = (props: MessageTimestampProps) => {
  if (!props.date) {
    return null;
  }
  return (
    <div>
      <span className="opacity-0 w-0 h-0 flex">&&timestamp&&</span>
      <div
        data-tooltip-content={props.date?.toLocaleDateString(dateLocalesArgument, shortDateOptions)}
        data-tooltip-id="date-tooltip"
        className="flex shrink-0 grow-0 ml-auto 
      justify-end text-xs text-label-tertiary"
      >
        {props.date && getDateFormatted(props.date)}
        {props.hasTooltip && <Tooltip id="date-tooltip" place="top" />}
      </div>
      <span className="opacity-0 w-0 h-0 flex">&&timestamp&&</span>
    </div>
  );
};

export default MessageTimestamp;
