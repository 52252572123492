import classNames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
import { isMobileDevice } from '../../utils/deviceDimensions';
import * as dataTestidConstants from '../../constants';
import ArrowLeftIcon from 'remixicon-react/ArrowLeftSLineIcon';
import { RunAppButtonDropdownContent } from '../base/RunAppButtonDropdownContent';
import { useAppStore } from '../../store/app';

const REACTIVATION_DELAY = 3000; // 3 seconds

export interface RichPromptAction {
  content: React.ReactNode;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  hasReactivationDelay?: boolean;
}

interface RichPromptActionListProps {
  actions: RichPromptAction[];
  handleSaveInstanceSettings: () => void;
}

// eslint-disable-next-line max-lines-per-function
const RichPromptActionList = ({
  actions: initialActions,
  handleSaveInstanceSettings,
}: RichPromptActionListProps) => {
  if (!initialActions?.length) {
    return <></>;
  }

  const [isTestAppDropdownOpened, setIsTestAppDropdownOpened] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSaveInstanceSettingsWithClosingDropdownMenu = () => {
    handleSaveInstanceSettings();
    setIsTestAppDropdownOpened(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as HTMLElement)) {
      setIsTestAppDropdownOpened(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [actions, setActions] = useState(initialActions);

  useEffect(() => {
    setActions(initialActions);
  }, [initialActions]);

  const handleActionClick = (action: RichPromptAction, index: number) => {
    if (action.hasReactivationDelay && !action.disabled) {
      const newActions = actions.map((a, idx) => {
        if (idx === index) {
          return { ...a, disabled: true };
        }
        return a;
      });
      setActions(newActions);

      setTimeout(() => {
        const newActions = actions.map((a, idx) => {
          if (idx === index) {
            return { ...a, disabled: false };
          }
          return a;
        });
        setActions(newActions);
      }, REACTIVATION_DELAY);
    }
    action.onClick();
  };

  return (
    <div
      key="chat-actions-container-menu"
      className={classNames('flex flex-col w-full py-1.5', {
        'gap-[2px]': !isMobileDevice(),
        'gap-[4px]': isMobileDevice(),
      })}
    >
      {/* eslint-disable-next-line max-lines-per-function */}
      {actions.map((action, index) => (
        <div key={`action-item-container-${index}`} className="flex flex-row">
          <button
            key={`action-${index}`}
            disabled={action.disabled}
            className={classNames(
              'flex py-1.5 rounded-md w-full',
              {
                'text-system-success hover:bg-system-success-hover': index === 0,
                'text-system-accent hover:bg-system-accent-hover': index !== 0,
                '!text-label-tertiary !hover:bg-label-tertiary !cursor-not-allowed':
                  action.disabled,
              },
              action.className
            )}
            onClick={() => handleActionClick(action, index)}
            onMouseDown={(e) => e.preventDefault()}
          >
            {action.content}
          </button>
          {index === 0 && (
            <button
              key={`action-dropdown-btn-${index}`}
              data-testid={
                dataTestidConstants.TEST_APP_LATEST_VERSION_MEMORY_SELECTOR_DROPDOWN_BUTTON
              }
              className={classNames(
                'bg-transparent text-system-success rounded-r-md hover:bg-system-success-hover',
                { 'px-1': !isMobileDevice() },
                { 'px-2': isMobileDevice() }
              )}
              onClick={() => {
                setIsTestAppDropdownOpened(!isTestAppDropdownOpened);
              }}
            >
              <ArrowLeftIcon size="16" />
            </button>
          )}
        </div>
      ))}
      {isTestAppDropdownOpened && (
        <div
          key="test-app-drop-down-content"
          ref={dropdownRef}
          className={classNames(
            'absolute w-72 bg-white border',
            ' border-gray-300 rounded-md shadow-lg p-4 z-50',
            ' right-[30px] top-0 px-2'
          )}
        >
          <RunAppButtonDropdownContent
            appMemoryOptionsProps={{ currentRam: useAppStore.getState().currentAppRam }}
            leftAlignDropDown={false}
            isTestingApp={true}
            handleSaveInstanceSettings={handleSaveInstanceSettingsWithClosingDropdownMenu}
          />
        </div>
      )}
    </div>
  );
};

export default RichPromptActionList;
