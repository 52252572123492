import { useAuthStore } from '../store/auth';
import { updateUserPermissions } from './updateUserPermissions';

export const navigateToUrl = (url: string) => {
  window.location.href = url;
};

export const handleRedirectToPricingPage = async () => {
  const email = useAuthStore.getState().firebaseUser?.email;
  if (email) {
    await updateUserPermissions(email);
  }
};

export const handleRedirectQueryParam = (redirectUrlQueryParam: string) => {
  if (redirectUrlQueryParam) {
    navigateToUrl(redirectUrlQueryParam);
  }
};
