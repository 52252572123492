import React, { useState } from 'react';
import classNames from 'classnames';
import PublishIcon from 'remixicon-react/Rocket2LineIcon';
import InfoIcon from 'remixicon-react/InformationLineIcon';
import { TOP_BAR_APP_NAME } from '../constants';
import { isMobileDevice } from '../utils/deviceDimensions';
import { LazyApp, AppLogic } from '../api/generated/index';
import InfoModal from './InfoModal';

interface AppInfoProps {
  appName: string;
  appLastPublishedUrl: string | null;
  appLastPublishedAt: Date | null;
  app: LazyApp | null;
  appLogic: AppLogic | null;
  updateAppLogic: (newName: string, newLogic: string) => Promise<void>;
}

// eslint-disable-next-line max-lines-per-function
const AppInfo: React.FC<AppInfoProps> = ({
  appName,
  appLastPublishedUrl,
  appLastPublishedAt,
  app,
  appLogic,
  updateAppLogic,
}) => {
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const isMobile = isMobileDevice();

  return (
    <div
      className={classNames('flex w-full px-3 py-2 justify-between', {
        'border-b border-system-separator': !isMobile,
      })}
    >
      {infoModalOpen && (
        <InfoModal
          infoTitle={app?.is_template ? 'Template info' : 'App Info'}
          appLogic={appLogic}
          isTemplate={!!app?.is_template}
          orgName={app?.org?.name}
          isAppExist={!!app}
          appLastPublishedAt={appLastPublishedAt}
          appLastPublishedUrl={appLastPublishedUrl}
          onHide={() => setInfoModalOpen(false)}
          updateAppLogic={updateAppLogic}
        ></InfoModal>
      )}
      <div className="flex flex-col items-start justify-center">
        <div className="flex items-center justify-center gap-1 cursor-pointer">
          {appLastPublishedUrl && (
            <PublishIcon size={16} className="text-system-success"></PublishIcon>
          )}
          <span
            data-testid={TOP_BAR_APP_NAME}
            className={classNames(
              'text-label-primary font-medium',
              'text-ellipsis whitespace-nowrap overflow-hidden',
              { 'max-w-[160px]': isMobile, 'max-w-[240px]': !isMobile }
            )}
          >
            {appName}
          </span>
          {app?.is_template && (
            <div className="flex justify-start">
              <div
                className={
                  'flex justify-center bg-indigo-50 text-indigo-600 px-2 py-1.5' +
                  'font-normal rounded text-xs'
                }
              >
                Template
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className={classNames(
          'flex items-center justify-start text-system-accent px-2',
          'gap-1 cursor-pointer',
          { 'w-[100px]': !isMobile },
          { 'flex-1 w-[40px]': isMobile }
        )}
        onClick={() => setInfoModalOpen(true)}
      >
        <InfoIcon size={20} />
        {!isMobile && <span>App info</span>}
      </div>
    </div>
  );
};

export default AppInfo;
