import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAuthStore, UserPermissions } from '../store/auth';
import LoadingPage from '../pages/LoadingPage';
import { To } from '@remix-run/router';
import { NavigateOptions } from 'react-router/dist/lib/context';

export interface AuthParameters {
  userIsAuthenticated: boolean;
  isUserSignedinEventEmitted: boolean;
  userPermissions?: UserPermissions;
}
interface AuthGuardProps {
  children: React.ReactNode;
  guardFunction: (
    auth: AuthParameters,
    currentUrl: Location
  ) => { to: To; options?: NavigateOptions } | null;
}

// eslint-disable-next-line max-lines-per-function
const AuthGuard = ({ children, guardFunction }: AuthGuardProps) => {
  const navigate = useNavigate();
  const { userIsAuthenticated, isUserSignedinEventEmitted, userStatusKnown, userPermissions } =
    useAuthStore();

  const authParameters: AuthParameters = {
    userIsAuthenticated,
    isUserSignedinEventEmitted,
    userPermissions,
  };

  useEffect(() => {
    if (userStatusKnown && (userPermissions || !userIsAuthenticated)) {
      const navigationPath = guardFunction(authParameters, window.location);
      if (navigationPath) {
        if (typeof navigationPath.to === 'string' && navigationPath.to.startsWith('https://')) {
          window.location.assign(navigationPath.to);
        } else {
          navigate(navigationPath.to, navigationPath.options);
        }
      }
    }
  }, [userStatusKnown, userIsAuthenticated, userPermissions]);

  if (userStatusKnown && !guardFunction(authParameters, window.location)) {
    return <>{children}</>;
  } else {
    return <LoadingPage />;
  }
};

export default AuthGuard;
