import React from 'react';
import RichPromptInput from './RichPromptInput';
import RichPromptInputLoader from './RichPromptInputLoader';
import RichPromptActionList, { RichPromptAction } from './RichPromptActionList';
import classNames from 'classnames';
import { isMobileDevice } from '../../utils/deviceDimensions';
import { RevertFunction, useChatStore } from '../../store/chat';
import ActionButton from '../base/ActionButton';
import SparklingIcon from '../../assets/sparkling.svg';
import { BuilderInteractionMode } from '../../api/generated/models/BuilderInteractionMode';
import * as dataTestidConstants from '../../constants';

interface RichPromptProps {
  currentPrompt: string;
  changeCurrentPrompt: (string) => void;
  submitUserPrompt: (string) => void;
  handleUserCancel: () => Promise<void>;
  handleInputFocus: (focus: boolean) => void;
  loading?: boolean;
  blocked?: boolean;
  displayActions?: boolean;
  revertFunction?: RevertFunction;
  actions?: RichPromptAction[];
  statusMessage?: string | null;
  hideLoadingStop: boolean;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  isAppRun?: boolean;
  developerReplyCount: number;
  currentBuilderInteractionMode: BuilderInteractionMode;
  setBuilderInteractionMode: (newState: BuilderInteractionMode) => void;
  handleSaveInstanceSettings: () => void;
}

// eslint-disable-next-line max-lines-per-function
const RichPrompt = (props: RichPromptProps) => {
  const dontRenderPromptInput = props.blocked && !props.loading;

  const sendMessage = (input: string) => {
    if (input.trim() !== '') {
      props?.submitUserPrompt(input);
    }
  };

  const onStop = () => {
    if (props.loading) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      props?.handleUserCancel();
    }
  };

  // eslint-disable-next-line max-lines-per-function
  const renderPromptInput = () => {
    if (dontRenderPromptInput) {
      return <></>;
    }

    return (
      <div className="flex flex-col w-full">
        {props.loading ? (
          <div className={classNames('flex')}>
            <RichPromptInputLoader
              onStop={onStop}
              hideStop={props.hideLoadingStop}
              message={props.statusMessage}
            />
          </div>
        ) : null}

        <div
          className={classNames('flex flex-col w-full relative', {
            hidden: props.loading,
            'gap-2': props.displayActions && props.actions?.length !== 0,
          })}
          onFocus={() => props.handleInputFocus(true)}
        >
          <div className={classNames('flex', { hidden: !props.displayActions })}>
            <RichPromptActionList
              actions={props.actions ?? []}
              handleSaveInstanceSettings={props.handleSaveInstanceSettings}
            />
          </div>

          <RichPromptInput
            textareaRef={props.textareaRef}
            currentPrompt={props.currentPrompt}
            changeCurrentPrompt={props.changeCurrentPrompt}
            onSend={sendMessage}
            isAppRun={props.isAppRun}
            developerReplyCount={props.developerReplyCount}
            currentBuilderInteractionMode={props.currentBuilderInteractionMode}
          />
        </div>
      </div>
    );
  };

  const canShowAbilitiesButton =
    process.env.REACT_APP_ENABLE_ABILTIES_IFRAME === 'true' &&
    !props.loading &&
    !props.isAppRun &&
    !dontRenderPromptInput;

  const showBuilderInteractionModeToggle =
    process.env.REACT_APP_ENABLE_INTERACTION_MODE_TOGGLE === 'true' &&
    !props.loading &&
    !props.isAppRun &&
    !dontRenderPromptInput &&
    props.developerReplyCount > 0;

  return (
    <div className={classNames('flex min-h-0 grow flex-col justify-end flex-nowrap z-50')}>
      <div
        className={classNames('flex w-full px-4 pt-4 flex-col', {
          'mb-8': !isMobileDevice() && !dontRenderPromptInput,
          'pb-6': isMobileDevice() && !dontRenderPromptInput,
        })}
      >
        {renderPromptInput()}

        <div className="flex w-full justify-between mt-3">
          {canShowAbilitiesButton && (
            <ActionButton
              buttonType="primary"
              fillState="light"
              className="w-24 !rounded-xl"
              onClick={() => useChatStore.setState({ showAbilitiesIframe: true })}
            >
              <img src={SparklingIcon as string} alt="Sparkling Icon" />
              Abilities
            </ActionButton>
          )}

          {showBuilderInteractionModeToggle && (
            <div
              className="flex space-x-0"
              data-testid={dataTestidConstants.TEST_ID_BUILDER_INTERACTION_MODE_TOGGLE}
            >
              <button
                data-testid={dataTestidConstants.INTERACTION_MODE_TOGGLE_CHAT_BUTTON}
                className={classNames(
                  'py-1 pl-3 pr-4 text-sm font-medium',
                  'rounded-l-lg', // Left button rounded only on the left
                  props.currentBuilderInteractionMode === BuilderInteractionMode.CHAT
                    ? 'bg-system-accent text-white'
                    : 'bg-neutral-200 text-gray-700'
                )}
                onClick={() => props.setBuilderInteractionMode(BuilderInteractionMode.CHAT)}
              >
                <span className="ml-0 mr-2" role="img" aria-label="Chat mode">
                  💬
                </span>
                Chat
              </button>
              {process.env.REACT_APP_ENABLE_EXPERIMENTAL_INTERACTION_MODES === 'true' && (
                <button
                  className={classNames(
                    'py-1 pl-3 pr-4 text-sm font-medium',
                    props.currentBuilderInteractionMode === BuilderInteractionMode.THINK_THEN_BUILD
                      ? 'bg-system-accent text-white'
                      : 'bg-neutral-200 text-gray-700'
                  )}
                  onClick={() =>
                    props.setBuilderInteractionMode(BuilderInteractionMode.THINK_THEN_BUILD)
                  }
                >
                  <span className="mr-2" role="img" aria-label="Builder mode">
                    🤔🔧
                  </span>
                  Think then build
                </button>
              )}
              <button
                data-testid={dataTestidConstants.INTERACTION_MODE_TOGGLE_BUILD_BUTTON}
                className={classNames(
                  'py-1 pl-3 pr-4 text-sm font-medium',
                  'rounded-r-lg', // Right button rounded only on the right
                  props.currentBuilderInteractionMode === BuilderInteractionMode.BUILD
                    ? 'bg-system-accent text-white'
                    : 'bg-neutral-200 text-gray-700'
                )}
                onClick={() => props.setBuilderInteractionMode(BuilderInteractionMode.BUILD)}
              >
                <span className="mr-2" role="img" aria-label="Builder mode">
                  🔧
                </span>
                Build
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RichPrompt;
