import type { BuilderSessionState } from '../generated/models/BuilderSessionState';
import type { ContentBuilderMessage } from '../generated/models/ContentBuilderMessage';

import type { CancelablePromise } from '../generated/core/CancelablePromise';
import { OpenAPI } from '../generated/core/OpenAPI';
import { request as __request } from './request';

export class StreamingAppService {
  /**
   * Update the session
   * Adds a new message to the session and returns the updated version with LLM response.
   * @param appId
   * @param requestBody
   * @param isLlmIndependentRequest
   * @param onMessage
   * @returns BuilderSessionState Successful Response
   * @throws ApiError
   */
  public static appUpdateAppSession(
    appId: string,
    requestBody: ContentBuilderMessage,
    isLlmIndependentRequest = false,
    onMessage?: <BuilderSessionState>(data: BuilderSessionState) => void
  ): CancelablePromise<BuilderSessionState> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v1/app/{app_id}/session',
      path: {
        app_id: appId,
      },
      query: {
        is_llm_independent_request: isLlmIndependentRequest,
      },
      body: requestBody,
      mediaType: 'application/json',
      onMessage,
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
