import RunAppIcon from 'remixicon-react/PlayFillIcon';
import React, { useState } from 'react';
import { useChatStore, TestVersionFunction } from '../../store/chat';
import classNames from 'classnames';
import { isMobileDevice } from '../../utils/deviceDimensions';
import * as dataTestidConstants from '../../constants';

const REACTIVATION_DELAY = 3000; // 3 seconds
interface TestVersionButtonProps {
  builderSessionStateId?: string;
  hasReactivationDelay?: boolean;
  testVersionFunction?: TestVersionFunction;
}
// eslint-disable-next-line max-lines-per-function
const TestVersionButton = ({
  builderSessionStateId,
  hasReactivationDelay,
  testVersionFunction,
}: TestVersionButtonProps) => {
  const buttonText = isMobileDevice() ? '' : 'Test version';
  const [isButtonDisable, setIsButtonDisabled] = useState<boolean>(false);
  if (!builderSessionStateId || !testVersionFunction) {
    return null;
  }

  const handleButtonClick = () => {
    if (hasReactivationDelay) {
      if (!isButtonDisable) {
        setIsButtonDisabled(true);
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, REACTIVATION_DELAY);
        testVersionFunction(builderSessionStateId);
      }
    } else {
      testVersionFunction(builderSessionStateId);
    }
  };

  return (
    <div className="flex">
      <button
        // eslint-disable-next-line max-len
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
        onClick={handleButtonClick}
        disabled={useChatStore.getState().userInputLoading || isButtonDisable}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data-testid={dataTestidConstants.TEST_RUN_APP_OLDER_VERSION_BUTTON}
        className={classNames(
          'text-system-success md:bg-transparent',
          'hover:bg-system-accent/10 rounded-lg text-lazy-btn-text p-1',
          {
            '!text-label-tertiary !hover:bg-label-tertiary !cursor-not-allowed':
              useChatStore.getState().userInputLoading || isButtonDisable,
          }
        )}
      >
        <RunAppIcon
          className="align-text-bottom
        inline-block mr-1"
          size={16}
        />
        <span className="hidden xs:inline-block">{buttonText}</span>
      </button>
    </div>
  );
};

export default TestVersionButton;
