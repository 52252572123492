import React, { MouseEventHandler } from 'react';

import classNames from 'classnames';
import Loader4LineIcon from 'remixicon-react/Loader4LineIcon';

interface ActionButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  buttonType?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'danger';
  size?: 'small' | 'medium' | 'large';
  contentAlignment?: 'left' | 'center' | 'right';
  fillState?: 'filled' | 'light' | 'subtle';
  children?: React.ReactNode;
}

// eslint-disable-next-line max-lines-per-function,complexity
const ActionButton: React.FC<ActionButtonProps> = ({
  buttonType = 'primary',
  fillState = 'filled',
  contentAlignment = 'center',
  size = 'large',
  isLoading,
  onClick,
  disabled,
  className,
  children,
  ...rest
}) => {
  const clickHandler = disabled ? undefined : onClick;

  // Background color styles
  const backgroundColorStyles = {
    'bg-system-blue-6': buttonType === 'primary' && fillState === 'filled',
    'bg-system-green-7': buttonType === 'secondary' && fillState === 'filled',
    'bg-system-gray-6 hover:bg-system-gray-5': buttonType === 'tertiary' && fillState === 'filled',
    'bg-system-gray-7 hover:bg-system-gray-5':
      buttonType === 'quaternary' && (fillState === 'filled' || fillState === 'light'),

    'bg-system-hover-blue': buttonType === 'primary' && fillState === 'light',
    'bg-system-hover-green': buttonType === 'secondary' && fillState === 'light',
    'bg-system-hover-default hover:bg-system-gray-5':
      buttonType === 'tertiary' && fillState === 'light',

    'bg-white hover:bg-system-hover-blue': buttonType === 'primary' && fillState === 'subtle',
    'bg-white hover:bg-system-hover-green': buttonType === 'secondary' && fillState === 'subtle',
    'bg-white hover:hover:bg-system-gray-5': buttonType === 'tertiary' && fillState === 'subtle',
    'bg-white bg-system-gray-7': buttonType === 'quaternary' && fillState === 'subtle',

    'bg-red-50 hover:bg-red-100': buttonType === 'danger' && fillState !== 'subtle',
    'hover:bg-red-50': buttonType === 'danger' && fillState === 'subtle',
  };

  // Text color styles
  const textColorStyles = {
    'text-white':
      (buttonType === 'primary' || buttonType === 'secondary') && fillState === 'filled',
    'text-system-blue-6':
      buttonType === 'primary' && (fillState === 'light' || fillState === 'subtle'),
    'text-system-green-7':
      buttonType === 'secondary' && (fillState === 'light' || fillState === 'subtle'),
    'text-label-primary': buttonType === 'tertiary',
    'text-label-secondary hover:text-label-primary': buttonType === 'quaternary',
    'text-system-danger': buttonType === 'danger',
  };

  // Text size styles
  const textSizeStyles = {
    'text-xs': size === 'small',
    'text-sm': size === 'medium' || size === 'large',
  };

  // Button height styles
  const heightStyles = {
    'h-6': size === 'small',
    'h-7': size === 'medium',
    'h-8': size === 'large',
  };

  // Padding styles
  const paddingStyles = {
    'px-1.5': size === 'small',
    'px-2.5': size === 'medium',
    'px-3.5': size === 'large',
  };

  // Border radius
  const borderRadiusStyles = {
    rounded: size === 'small' || size === 'medium',
    'rounded-md': size === 'large',
  };

  // Button content alignment styles
  const contentAlignmentStyles = {
    'justify-start': contentAlignment === 'left',
    'justify-center': contentAlignment === 'center',
    'justify-end': contentAlignment === 'right',
  };

  const styles = {
    ...backgroundColorStyles,
    ...paddingStyles,
    ...textColorStyles,
    ...textSizeStyles,
    ...borderRadiusStyles,
    ...heightStyles,
    ...contentAlignmentStyles,
  };

  const buttonProps = {
    className: classNames(
      'flex flex-row items-center gap-1',
      {
        'cursor-not-allowed text-lazy-light-grey': isLoading || disabled,
      },
      styles,
      className
    ),
    disabled: isLoading || disabled,
  };

  return (
    <button {...buttonProps} {...rest} onClick={clickHandler}>
      {isLoading && <Loader4LineIcon className="animate-spin" size="1.2rem" />}
      {children}
    </button>
  );
};

export default ActionButton;
