import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { LazyApp, LazyAppFolder } from '@lazy-frontend/api/generated';

import { updateApp } from '../../../api/BuilderApi';
import { INDEX_OF_FOLDER_LESS_APPS_CONTAINER } from './constants';
import Folder from './Folder';

interface FolderListProps {
  folders: LazyAppFolder[];
  apps: LazyApp[];
  onAppMoved: () => Promise<void>;
  closeSidebar: () => void;
}

// eslint-disable-next-line max-lines-per-function
const FolderList: React.FC<FolderListProps> = ({ folders, apps, onAppMoved, closeSidebar }) => {
  const [isOverOnFolderIndex, setIsOverOnFolderIndex] = useState<number | null>(null);

  const handleFolderDrop = async (
    appId: string,
    folderId?: string,
    isNoOp = false
  ): Promise<void> => {
    setIsOverOnFolderIndex(null);
    if (!isNoOp) {
      await updateApp(appId, { app_folder_id: folderId });
      await onAppMoved();
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex flex-col w-full gap-2 px-2 flex-1 overflow-y-scroll">
        {folders.map((folder, index) => (
          <Folder
            key={index}
            folder={folder}
            onDrop={handleFolderDrop}
            isOver={isOverOnFolderIndex === index}
            setIsOver={() => setIsOverOnFolderIndex(index)}
            onAppMoved={onAppMoved}
            closeSidebar={closeSidebar}
          />
        ))}
        <Folder
          apps={apps || []}
          onDrop={handleFolderDrop}
          isOver={isOverOnFolderIndex === INDEX_OF_FOLDER_LESS_APPS_CONTAINER}
          setIsOver={() => setIsOverOnFolderIndex(INDEX_OF_FOLDER_LESS_APPS_CONTAINER)}
          onAppMoved={onAppMoved}
          closeSidebar={closeSidebar}
        />
      </div>
    </DndProvider>
  );
};

export default FolderList;
