import React from 'react';
import ErrorWarningLineIcon from 'remixicon-react/ErrorWarningLineIcon';
import { Loader } from '../components/base/Loader';

const LoadingPage = ({ error }: { error?: string }) => {
  return (
    <div
      role="alert"
      aria-busy="true"
      data-testid="loading-page"
      className="flex h-screen text-center px-8"
    >
      {error ? (
        <div className="flex flex-col items-center m-auto text-lazy">
          <ErrorWarningLineIcon size="4rem" />
          <div className="text-xl">{error}</div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default LoadingPage;
