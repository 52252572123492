import { PingClientParams } from './generated';
import { setupHeaders } from './index';

setupHeaders && setupHeaders();

export const WEBSOCKET_RECONNECT_TIMEOUT_MS = 2000;

export type SubscribeToAppInstanceUpdatesParams = {
  instanceId: string;
  messageHook: (message: PingClientParams) => void;
  connectHook: (ws: WebSocket | null) => void;
};

export function subscribeToAppInstanceUpdates(params: SubscribeToAppInstanceUpdatesParams) {
  const protocol = window.location.protocol.replace('http', 'ws');

  const socket = new WebSocket(
    `${protocol}//${window.location.host}/api/v1/ws/ping/${params.instanceId}`
  );
  socket.addEventListener('message', (msg: { data: string }) => {
    params.messageHook(JSON.parse(msg.data) as unknown as PingClientParams);
  });
  socket.addEventListener('open', () => {
    params.connectHook(socket);
  });
  socket.addEventListener('close', (_event) => {
    setTimeout(() => subscribeToAppInstanceUpdates(params), WEBSOCKET_RECONNECT_TIMEOUT_MS);
  });
  socket.addEventListener('error', (_event) => {
    // Close if it's not already closed. This will trigger the close event if it's not
    // closed already. Following the answers in https://stackoverflow.com/q/22431751
    socket.close();
  });
}
