import classNames from 'classnames';
import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import ArrowDownIcon from 'remixicon-react/ArrowDownSLineIcon';
import ArrowRightIcon from 'remixicon-react/ArrowRightSLineIcon';
import FolderIcon from 'remixicon-react/FolderLineIcon';
import { toast } from 'react-toastify';

import { LazyApp, LazyAppFolder } from '@lazy-frontend/api/generated';
import { updateFolder } from '../../../../api/AppFoldersApi';

import ThreeDotsIcon from '../../../../assets/three-dots.svg';
import { useAppIdFromUrl } from '../../hooks';
import { DRAGABLE_ITEM_TYPE } from '../constants';
import App from './App';
import { ActionsMenu } from './ActionsMenu';
interface FolderProps {
  apps?: LazyApp[];
  folder?: LazyAppFolder;
  onDrop: (appId: string, folderId?: string | null, isNoOp?: boolean) => Promise<void>;
  isOver: boolean;
  setIsOver: () => void;
  onAppMoved: () => Promise<void>;
  closeSidebar: () => void;
}

// eslint-disable-next-line max-lines-per-function
const Folder: React.FC<FolderProps> = ({
  folder,
  apps,
  onDrop,
  isOver,
  setIsOver,
  onAppMoved,
  closeSidebar,
}) => {
  const appsToRender = folder?.apps || apps || [];
  const appIdFromUrl = useAppIdFromUrl();

  const [isExpanded, setIsExpanded] = useState(
    !!appsToRender.find((app) => app.id === appIdFromUrl)
  );
  const [showActionsMenu, setShowActionsMenu] = useState(false);
  const [folderNameEditMode, setFolderNameEditMode] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const [, dropRef] = useDrop({
    accept: DRAGABLE_ITEM_TYPE,
    hover: () => {
      setIsOver && setIsOver();
    },
    drop: async (app: LazyApp) => {
      await onDrop(app.id, folder?.id || null, app.app_folder_id === (folder?.id || null));
    },
  });

  const Icon = isExpanded ? ArrowDownIcon : ArrowRightIcon;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const inputElement = e.target as HTMLInputElement;

    if (e.key === 'Enter') {
      updateFolder(folder?.id as string, inputElement.value)
        .then(async () => {
          setFolderNameEditMode(false);
          toast.success('Folder name updated successfully.');
          await onAppMoved();
          return null;
        })
        .catch(() => {
          // Handle errors
          toast.error('Please make sure its a unique folder name.');
        });
    }
  };

  return (
    <div
      ref={(node) => dropRef(node)}
      className={classNames('relative', {
        'bg-system-hover-green': isOver,
      })}
    >
      {folder &&
        (folderNameEditMode ? (
          <div className="flex w-full px-3">
            <div
              className={
                'flex flex-1 gap-2 items-center bg-white border ' +
                'border-system-separator rounded p-1.5'
              }
            >
              <ArrowRightIcon size={20} />
              <input
                type="text"
                placeholder="Folder Name"
                className="flex-1 outline-none"
                defaultValue={folder.name}
                onBlur={() => {
                  setShowActionsMenu(false);
                  setFolderNameEditMode(false);
                }}
                onKeyDown={(e) => handleKeyDown(e)}
              ></input>
            </div>
          </div>
        ) : (
          <div className="flex w-full">
            <div className="flex p-2 items-center gap-1 w-full" onClick={handleToggle}>
              <Icon size={20} className="cursor-pointer" />
              <FolderIcon size={18} className="cursor-pointer" />
              <span className="font-semibold label-primary text-sm truncate flex-1 cursor-pointer">
                {folder.name}
              </span>
              <div
                className="flex cursor-pointer ml-auto pr-[3px] items-center"
                onClick={(e) => {
                  setShowActionsMenu(true);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <img className="w-5 h-5" src={ThreeDotsIcon as string} alt="Three Dots Icon" />
              </div>
            </div>
          </div>
        ))}

      {showActionsMenu && (
        <ActionsMenu
          onEditFolderName={() => {
            setShowActionsMenu(false);
            setFolderNameEditMode(true);
          }}
          onClose={() => setShowActionsMenu(false)}
        />
      )}
      {(isExpanded || !folder) && (
        <div
          className={classNames(
            { 'pl-[24px]': folder },
            {
              'h-6 w-full': !folder && appsToRender.length === 0,
            }
          )}
        >
          {appsToRender.map((app, appIndex) => (
            <App key={appIndex} app={app} onAppMoved={onAppMoved} closeSidebar={closeSidebar} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Folder;
