import useModalVisibility from '../hooks/modalVisibility';
import React, { Ref, useEffect } from 'react';
import classNames from 'classnames';
import GlobalModalBackdrop from '../components/GlobalModalBackdrop';

export interface BasicModalProps {
  children?: React.ReactNode;
  onHide: () => void;
}

const BasicModal = ({ onHide, children }: BasicModalProps) => {
  const { itemRef, isVisible } = useModalVisibility(true);

  useEffect(() => {
    if (!isVisible) {
      onHide();
    }
  }, [isVisible]);

  const renderForm = () => {
    return (
      <div
        className="flex rounded items-center justify-center bg-white"
        ref={itemRef as Ref<HTMLDivElement>}
      >
        {children}
      </div>
    );
  };

  return (
    <>
      <GlobalModalBackdrop />
      <div
        className={classNames(
          'rounded-lg bg-transparent shadow-around fixed flex-1 top-0 left-0',
          'flex items-center justify-center h-screen w-screen z-[100]'
        )}
      >
        {renderForm()}
      </div>
    </>
  );
};

export default BasicModal;
